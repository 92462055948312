export const MIN_X_EXTENT = 5;
export const MIN_Y_EXTENT = 5;

export const MAX_X_EXTENT = 20000;
export const MAX_Y_EXTENT = 20000;

// Define available resolutions
export const AVAILABLE_RESOLUTIONS = [
  700, 800, 900, 1000, 1200, 1400, 1600, 1800, 2000, 3000, 4000, 5000, 6000,
];
