import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { SimulationTurbineList } from "../TurbineLocationUploader/helpers";

interface Props {
  rowLimit: number;
  turbineLocations: SimulationTurbineList;
}

export default function TurbineLocationsTable({
  turbineLocations,
  rowLimit,
}: Props) {
  if (!turbineLocations) return null;
  const rows = turbineLocations.slice(0, rowLimit).map((row, i) => {
    return (
      <Tr key={i}>
        <Td fontSize="xs">{row[0]}</Td>
        <Td fontSize="xs">{row[5]}</Td>

        <Td fontSize="xs" isNumeric>
          {row[1]}
        </Td>
        <Td fontSize="xs" isNumeric>
          {row[2]}
        </Td>
        <Td fontSize="xs" isNumeric>
          {row[3]}m
        </Td>
      </Tr>
    );
  });

  const placeholder =
    turbineLocations.length > rowLimit ? (
      <Tr key="placeholder">
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
      </Tr>
    ) : null;

  return (
    <TableContainer mb={4}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th w={"2rem"} verticalAlign="bottom">
              ID
            </Th>
            <Th verticalAlign="bottom">Project</Th>
            <Th verticalAlign="bottom" isNumeric>
              Lat
            </Th>
            <Th verticalAlign="bottom" isNumeric>
              Lng
            </Th>
            <Th verticalAlign="bottom" isNumeric>
              Hub
              <br /> Height
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows}
          {placeholder}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
