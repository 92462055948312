import { roundCoordinate } from "../util";

export type TurbinePosition = [number, number, number, number, string, string];
export type SimulationTurbineList = TurbinePosition[];

type Ret = {
  errors: string[];
  turbineLocations: SimulationTurbineList;
};

function csvToGeoJSON(
  turbines: SimulationTurbineList | null | undefined
): GeoJSON.FeatureCollection<GeoJSON.Point> {
  const features = (turbines || []).map((turbine) => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [turbine[2], turbine[1]],
      },
      properties: {
        id: turbine[0],
        slug: turbine[4],
        hubHeight: turbine[3],
        project: turbine[5],
      },
    } as GeoJSON.Feature<GeoJSON.Point>;
  });

  return {
    type: "FeatureCollection",
    features,
  };
}

function parseCSV(data: any[], turbineSlugs: string[]): Ret {
  let errors: string[] = [];

  const unknownTurbineSlugs = new Set<string>();

  // let maybeHeaderRow = true;
  let turbineLocations: SimulationTurbineList = [];
  console.debug(`TODO: match with turbineIds`);
  try {
    for (var i = 1; i < data.length; i++) {
      const row = data[i];
      if (row.length <= 1) continue;

      const slug = row[4].toString();
      const project = row[5]?.toString() || "";

      if (!turbineSlugs.includes(slug)) {
        unknownTurbineSlugs.add(slug);
        continue;
      }

      let parsed = [
        parseInt(row[0], 10),
        roundCoordinate(parseFloat(row[1])),
        roundCoordinate(parseFloat(row[2])),
        parseInt(row[3], 10),
        slug,
        project,
      ];

      // maybeHeaderRow = false;
      if (!parsed) continue;

      turbineLocations.push(parsed as TurbinePosition);
    }

    if (unknownTurbineSlugs.size > 0) {
      errors.push(
        `We don't recognize the following turbine models: ${Array.from(
          unknownTurbineSlugs
        ).join(", ")}`
      );
    }

    return { errors, turbineLocations };
  } catch (e) {
    console.error(e);
    return { errors: [(e as Error).message], turbineLocations: [] };
  }
}

export { csvToGeoJSON, parseCSV };
