import { DownloadIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CircularProgress,
  Flex,
  Heading,
  IconButton,
  List,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Simulation } from "../../API";
import getSimulationOutputs, {
  SimulationOutputsData,
} from "../../endpoints/getSimulationOutputs";
import { shouldShowSimulationOutputs } from "../Simulations/simulationStates";

export default function SimulationOutputs(props: {
  simulation: Partial<Simulation>;
}) {
  const [loading, setLoading] = useState(true);
  const [outputs, setOutputs] = useState<SimulationOutputsData | null>(null);
  if (!shouldShowSimulationOutputs(props.simulation)) return;

  const { slug } = props.simulation;

  useEffect(() => {
    async function loadOutputs() {
      if (!slug) return;
      const outputs = await getSimulationOutputs({ simulationSlug: slug });
      setOutputs(outputs);
      setLoading(false);
    }
    loadOutputs();
  }, [slug]);

  if (loading) {
    return (
      <Box>
        <CircularProgress isIndeterminate size="24px" color="yellow.200" />
      </Box>
    );
  }

  if (!outputs) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Error loading outputs</AlertTitle>
        <AlertDescription>
          There was an error loading the outputs for this simulation. Please try
          again.
        </AlertDescription>
      </Alert>
    );
  }

  function renderNotes(outputs: SimulationOutputsData) {
    if (!outputs.notes) {
      return false;
    }
    const notes = outputs.notes.map((note, index) => {
      return (
        <Box
          key={index}
          bg="whiteAlpha.200"
          fontSize="sm"
          display={"block"}
          borderRadius={6}
          lineHeight={1.6}
          p={4}
        >
          <Text size="sm">{note}</Text>
        </Box>
      );
    });
    return <>{notes}</>;
  }

  function renderDownloads(outputs: SimulationOutputsData) {
    if (!outputs.downloads.length) {
      return false;
    }

    return (
      <Box>
        <Heading as="h2" size="sm" mb={4}>
          Downloads
        </Heading>
        <List>
          {outputs.downloads.map((download) => (
            <li key={download.url}>
              <a href={download.url} target="_blank" rel="noreferrer">
                <Flex>
                  <Box pr={4}>
                    <IconButton
                      aria-label="Download"
                      icon={<DownloadIcon />}
                      colorScheme="teal"
                      size="sm"
                      top={1}
                    />
                  </Box>
                  <Box>
                    {download.name}

                    <Box>
                      <Text as="small">{download.path}</Text>
                    </Box>
                  </Box>
                </Flex>
              </a>
            </li>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Box>
      {renderNotes(outputs)}
      {renderDownloads(outputs)}
    </Box>
  );
}
