import { PlusSquareIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Input } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Simulation } from "../../API";
import SidebarLeft from "../SidebarLeft";
import SimulationLibraryTable from "./SimulationLibraryTable";
import filterSimulationsByKeyword from "./filterSimulationsByKeyword";

interface Props {
  simulations: Simulation[];
}

export default function SimulationLibrary(props: Props) {
  const { simulations } = props;
  const [search, setSearch] = useState("");
  const filteredSimulations = useMemo(() => {
    return filterSimulationsByKeyword(simulations, search);
  }, [search, simulations]);

  const isFiltered = simulations.length !== filteredSimulations.length;

  let results;
  if (search.length > 0 && filteredSimulations.length === 0) {
    results = (
      <Box textAlign="center" fontSize="sm" p={6}>
        No simulations matching "{search}"
      </Box>
    );
  } else {
    results = (
      <SimulationLibraryTable
        simulations={filteredSimulations}
        isFiltered={isFiltered}
      />
    );
  }

  return (
    <SidebarLeft>
      <Box
        height="100%"
        className="record-library simulation-library"
        position={"relative"}
      >
        <Box className="record-library-header" px={6} pt={6}>
          <HStack mb={6} alignItems="justify-content">
            <Box flex="1">
              <Button
                leftIcon={<PlusSquareIcon />}
                as={Link}
                to="/simulations/new"
                size="sm"
                variant="outline"
                // colorScheme="teal"
              >
                New
              </Button>
            </Box>

            <Box flex="1">
              <Input
                placeholder="Search"
                variant="filled"
                size="sm"
                type="search"
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </HStack>
        </Box>
        <Box className="record-library-results">{results}</Box>
      </Box>
    </SidebarLeft>
  );
}
