import { Box, Button, useDisclosure } from "@chakra-ui/react";
import PowerThrustModal from "../PowerThrustModal";
import { PowerCurve } from "../Turbines/generatePowerCurveJSON";

type Props = {
  powerCurve: PowerCurve | undefined;
};

export default function PowerThrustTable(props: Props) {
  const { powerCurve } = props;
  if (!powerCurve || powerCurve.length < 1) return null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button size="sm" colorScheme="teal" onClick={onOpen}>
        Power Curve Data
      </Button>
      <PowerThrustModal
        isOpen={isOpen}
        onClose={onClose}
        rowLimit={50}
        powerCurve={powerCurve}
      />
    </Box>
  );
}
