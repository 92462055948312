import { Box, Text } from "@chakra-ui/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
interface Props {
  submitterInfo: Record<string, string>;
}
export default function SubmitterInfo(props: Props) {
  const { user } = useAuthenticator();
  const submitterInfo = props.submitterInfo;
  const parts = [];

  if (!submitterInfo) return null;
  if (submitterInfo.sub == user.username) {
    // Do not render submitterInfo since the submission belongs to the current user
    return null;
  }

  // if (!submitterInfo)

  if (submitterInfo.name) {
    parts.push(
      <Box key="name">
        <Text as={"b"}>Name</Text>: {submitterInfo.name}
      </Box>
    );
  }

  if (submitterInfo.email) {
    parts.push(
      <Box key="email">
        <Text as={"b"}>Email</Text>: {submitterInfo.email}
      </Box>
    );
  }

  if (submitterInfo["custom:company"]) {
    parts.push(
      <Box key="company">
        <Text as={"b"}>Company</Text>: {submitterInfo["custom:company"]}
      </Box>
    );
  }

  if (submitterInfo.website) {
    parts.push(
      <Box key="website">
        <Text as={"b"}>Website</Text>: {submitterInfo.website}
      </Box>
    );
  }

  return (
    <Box w="full">
      <Box
        minHeight={10}
        background={"gray.300"}
        color={"blackAlpha.800"}
        borderRadius={6}
        p={4}
        fontSize="sm"
      >
        <Box fontSize={"sm"}>{parts}</Box>
      </Box>
    </Box>
  );
}
