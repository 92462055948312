export type PowerCurveDatum = [number, number, number]; // TODO: what should this be called?
export type PowerCurve = PowerCurveDatum[];

type Ret = {
  errors: string[];
  powerCurve: PowerCurve;
};

export default function generatePowerCurveJSON(data: any[]): Ret {
  let errors: string[] = [];

  let maybeHeaderRow = true;
  let powerCurve: PowerCurve = [];

  for (var i = 0; i < data.length; i++) {
    const row = data[i];
    if (row.length <= 1) continue;
    let parsed = row;
    for (var j = 0; j < 3; j++) {
      // const parsed = row[j];
      const orig = row[j];
      parsed[j] = parseFloat(row[j]);
      if (isNaN(row[j])) {
        if (maybeHeaderRow) {
          parsed = null;
          break;
        }
        errors.push(
          `Row ${i} column ${j} is not a number: ${
            row[j]
          }, was ${orig}, row: ${JSON.stringify(parsed)}`
        );

        return { errors, powerCurve: [] };
      }
    }

    maybeHeaderRow = false;
    if (!parsed) continue;

    powerCurve.push(parsed);
  }

  return { errors, powerCurve };
}
