import { Privacy, Turbine, TurbineTypeStatus } from "../API";

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

export const DEMO_TURBINES: Turbine[] = [];

const iea34: Turbine = {
  rotorDiameter: 130,
  ratedPower: 3400,
  __typename: "Turbine",
  description: "IEA 3.4 MW turbine",
  privacy: Privacy.PUBLIC,
  createdAt: oneWeekAgo.toISOString(),
  deletedAt: null,
  powerCurve:
    "[[3,0.814,51.6203274],[3.5392,0.8042,123.3681588],[4.0485,0.7982,213.241509],[4.5267,0.7945,318.837998],[4.973,0.7913,435.9564867],[5.3862,0.7861,560.8983052],[5.7654,0.776,688.906312],[6.1098,0.7664,819.1257759],[6.4186,0.7664,949.7236726],[6.6912,0.7664,1075.927005],[6.9269,0.7664,1193.682699],[7.1252,0.7664,1299.172102],[7.2857,0.7664,1388.952513],[7.6468,0.7664,1605.876078],[8.0899,0.7664,1901.498529],[8.3132,0.7664,2063.341406],[8.5735,0.7664,2263.331677],[8.8703,0.7664,2506.635948],[9.203,0.7664,2799.332771],[9.5707,0.7664,3148.458978],[9.8127,0.8068,3370],[10.408,0.5306,3370.000035],[10.8757,0.4467,3370.000717],[11.3748,0.3801,3370.000086],[11.9041,0.3254,3370.000018],[12.4625,0.2798,3370.011453],[13.0487,0.2415,3370.00003],[13.6615,0.209,3370.001728],[14.2995,0.1816,3370.008641],[14.9612,0.1583,3370.033669],[15.6452,0.1384,3370.000061],[16.35,0.1214,3370.000238],[17.074,0.1069,3370.001502],[17.8155,0.0945,3370.002288],[18.5731,0.0839,3370.002284],[19.3448,0.0748,3370.018233],[20.1292,0.0669,3370.034011],[20.9244,0.0601,3370.004589],[21.7286,0.0542,3369.999956],[22.5401,0.0491,3370.000667],[23.357,0.0446,3370.003948],[24.1776,0.0406,3370.00009],[25,0.0372,3370.104925]]",
  id: "demo_iea_130m_3400kw",
  slug: "iea_130m_3400kw",
  status: TurbineTypeStatus.PUBLISHED,
  updatedAt: oneWeekAgo.toISOString(),
};

const vestas82: Turbine = {
  rotorDiameter: 82,
  ratedPower: 1650,
  __typename: "Turbine",
  description: "Vestas 1.65 MW turbine",
  privacy: Privacy.PUBLIC,
  createdAt: oneWeekAgo.toISOString(),
  deletedAt: null,
  powerCurve:
    "[[3,0.979,0],[4,1.111,28],[5,1.014,144],[6,0.925,309],[7,0.843,511],[8,0.768,758],[9,0.701,1017],[10,0.642,1285],[11,0.578,1504],[12,0.509,1637],[13,0.438,1650],[14,0.379,1650],[15,0.334,1650],[16,0.299,1650],[17,0.272,1650],[18,0.249,1650],[19,0.232,1650],[20,0.218,1650]]",
  id: "demo_vestas_82m_1650kw",
  slug: "vestas_82m_1650kw",
  status: TurbineTypeStatus.PUBLISHED,
  updatedAt: oneWeekAgo.toISOString(),
};

const nrel126: Turbine = {
  rotorDiameter: 126,
  ratedPower: 5000,
  __typename: "Turbine",
  description: "NREL's standard 5 MW offshore turbine",
  privacy: Privacy.PUBLIC,
  createdAt: oneWeekAgo.toISOString(),
  deletedAt: null,
  powerCurve:
    "[[3,2.497990147,40.5],[4,1.766833378,177.7],[5,1.408360153,403.9],[6,1.201348494,737.6],[7,1.065133759,1187.2],[8,0.977936955,1771.1],[9,0.936281559,2518.6],[10,0.905425262,3448.41],[11,0.855544522,4562.51],[12,0.627368152,5000],[13,0.471373796,5000],[14,0.372703289,5000],[15,0.30290131,5000],[16,0.251235686,5000],[17,0.211900735,5000],[18,0.181210571,5000],[19,0.156798163,5000],[20,0.137091212,5000],[21,0.120753164,5000],[22,0.106941036,5000],[23,0.095319286,5000],[24,0.085631997,5000],[25,0.077368152,5000]]",
  id: "demo_nrel_126m_5000kw",
  slug: "nrel_126m_5000kw",
  status: TurbineTypeStatus.PUBLISHED,
  updatedAt: oneWeekAgo.toISOString(),
};

DEMO_TURBINES.push(iea34);
DEMO_TURBINES.push(vestas82);
DEMO_TURBINES.push(nrel126);
