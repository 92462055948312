import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Container } from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import awsconfig from "./aws-exports";
import AppBody from "./components/AppBody";
// import MapPlaceholder from "./components/Map/MapPlaceholder";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import ErrorMessage from "./components/ErrorMessage";
import Header from "./components/Header";
import Map from "./components/Map";
import { MapProvider } from "./contexts/mapContext";
import useSimulationStore from "./components/Simulations/simulationStore";
import useTurbineStore from "./components/Turbines/turbineStore";
import useDomainStore from "./components/Simulations/domainStore";
Amplify.configure(awsconfig);

interface User {
  attributes: {
    sub: string;
    email_verified: boolean;
    email: string;
  };
}

function App(props: any) {
  const { signOut } = props;
  const user = props.user as User;

  const [appSection, setAppSection] = useState("simulations");
  const location = useLocation();
  const mapDisabled =
    location.pathname.startsWith("/turbines") ||
    location.pathname.startsWith("/docs");

  // set the sentry user in useEffect
  useEffect(() => {
    if (user && user.attributes) {
      console.debug("Current user", user);
      Sentry.setUser({
        email: user.attributes.email,
        id: user.attributes.sub,
      });

      ReactGA.set({
        userId: user.attributes.sub,
        email: user.attributes.email,
      });
    }
  }, [user]);

  const { reset: resetSimulations } = useSimulationStore();
  const { reset: resetTurbines } = useTurbineStore();
  const { reset: resetDomains } = useDomainStore();

  const signOutAndReset = useCallback(async () => {
    resetSimulations();
    resetTurbines();
    resetDomains();
    await signOut();
  }, [resetSimulations, resetTurbines, resetDomains, signOut]);

  return (
    <MapProvider>
      <Container
        as={"main"}
        bg="transparent"
        position={"relative"}
        w="100vw"
        height="100vh"
        maxW="unset"
        // maxH="100vh"
        // maxW="100vw"
        p={0}
        m={0}
        flexDirection="column"
        display="flex"
        className="app-container"
      >
        <Box
          as="header"
          h="9vh"
          className="app-header"
          position="relative"
          zIndex="10"
          bg="blackAlpha.900"
        >
          <Header
            userLabel={user.attributes.email}
            signOut={signOutAndReset}
            appSection={appSection}
            onSetAppSection={setAppSection}
          />
        </Box>
        <Box
          // alignItems="flex-start"
          // justifyContent="flex-start"
          flex={1}
          as="article"
          position="relative"
          className="app-body"
          zIndex={2}
        >
          <Map disabled={mapDisabled} />

          <AppBody
            appSection={appSection}
            onSetAppSection={setAppSection}
            signOut={signOut}
          />
        </Box>
      </Container>
    </MapProvider>
  );
}

const formFields = {
  signUp: {
    company: {
      label: "Company",
      placeholder: "Enter your company",
      isRequired: false,
      order: 1,
    },
    name: {
      label: "Your Name",
      placeholder: "Enter your name",
      isRequired: true,
      order: 2,
    },
    email: {
      label: "Email",
      placeholder: "Enter your email",
      isRequired: true,
      order: 3,
    },

    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
      isRequired: false,
      order: 4,
    },
    confirm_password: {
      label: "Confirm Password:",
      order: 5,
    },
  },
};

const AuthWrapped = () => {
  return (
    <Authenticator formFields={formFields}>
      {(props) => <App {...props} />}
    </Authenticator>
  );
};

export default Sentry.withErrorBoundary(AuthWrapped, {
  fallback: <ErrorMessage />,
});
