import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface WindResourceData {
  id: number; // mapbox wants IDs to be integers

  /* wind speed - all farms */
  wsaf: number;
}

interface WindResourceState {
  currentWindResourceData: WindResourceData | undefined;
  setCurrentWindResourceData: (data?: WindResourceData) => void;
}

const useWindResourceStore = create<WindResourceState>()(
  devtools(
    (set, _get) => ({
      currentWindResourceData: undefined,
      setCurrentWindResourceData: (data?: WindResourceData) => {
        set({ currentWindResourceData: data });
      },
    }),
    { name: "windResourceStore" }
  )
);

export default useWindResourceStore;
