import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
  CreateTurbineInput,
  CreateTurbineMutation,
  DeleteTurbineMutation,
  ListTurbinesQuery,
  Privacy,
  Turbine,
  TurbineTypeStatus,
  UpdateTurbineInput,
  UpdateTurbineMutation,
} from "../../API";
import { DEMO_TURBINES } from "../../demo/turbines";
import generateId from "../../generateId";
import {
  createTurbine,
  deleteTurbine,
  updateTurbine,
} from "../../graphql/mutations";
import { listTurbines } from "../../graphql/queries";
const deleteCurrentTurbine = async (id: string) => {
  try {
    await API.graphql<GraphQLQuery<DeleteTurbineMutation>>(
      graphqlOperation(deleteTurbine, { input: { id: id } })
    );
  } catch (error) {
    console.error(error);
  }
};

const fetchTurbines = async (): Promise<Turbine[]> => {
  const turbines: Turbine[] = [];
  try {
    let nextToken: string | null | undefined = undefined;

    do {
      const apiData: GraphQLResult<GraphQLQuery<ListTurbinesQuery>> =
        await API.graphql<GraphQLQuery<ListTurbinesQuery>>(
          graphqlOperation(listTurbines, { nextToken, limit: 1000 })
        );

      apiData?.data?.listTurbines?.items.forEach((turbine) => {
        if (!turbine) return;
        turbines.push(turbine);
      });
      nextToken = apiData?.data?.listTurbines?.nextToken;
    } while (nextToken);

    DEMO_TURBINES.forEach((demoTurbine: Turbine) => {
      turbines.push(demoTurbine);
    });

    return turbines || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const saveTurbine = async (
  params: Partial<UpdateTurbineInput> | Partial<CreateTurbineInput>
): Promise<Turbine> => {
  params.privacy = Privacy.PRIVATE;

  if (params.id) {
    const result = await API.graphql<GraphQLQuery<UpdateTurbineMutation>>(
      graphqlOperation(updateTurbine, {
        input: params,
      })
    );

    const turbine = result?.data?.updateTurbine as Turbine;

    return turbine;
  } else {
    const id = generateId("turbine");
    const slug = params.slug || id;

    params.status = TurbineTypeStatus.PUBLISHED;

    const result = await API.graphql<GraphQLQuery<CreateTurbineMutation>>(
      graphqlOperation(createTurbine, {
        input: { ...params, id, slug },
      })
    );

    if (!result?.data?.createTurbine?.id) {
      throw new Error("No turbine id");
    }

    const turbine = result?.data?.createTurbine as Turbine;
    return turbine;
  }
};

export { deleteCurrentTurbine, fetchTurbines, saveTurbine };
