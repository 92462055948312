// @ts-expect-error
import centerOfMass from "@turf/center-of-mass";
import mapboxgl, { SourceVectorLayer, VectorSource } from "mapbox-gl";
import { WindResourceData } from "../windResourceStore";
import formatWindResourcePopup from "./formatWindResourcePopup";
import {
  DATA_LAYERS,
  SourceLayer,
  WindMapSource,
  metricsOrNone,
} from "./types";
import getCellData from "../../../endpoints/getCellData";
import { removeMapLayer, removeMapSource } from "./removeMapElements";

// ALL POLYGONS
let current_metric: metricsOrNone = "wsaf";
let current_threshold: number = 0;
let firstSymbolLayerID: string | undefined;
let hasLayer = false; // TODO: do we still need this or can we use loadedLayerIDs?
let loadedLayerIDs = new Set<string>();

export function rerenderWindResourceMap(
  map: mapboxgl.Map,
  source: WindMapSource,
  sourceLayers: SourceLayer[],
  metric: metricsOrNone,
  threshold: number,
  simulationSlug: string
) {
  current_metric = metric;
  current_threshold = threshold;
  if (hasLayer) {
    sourceLayers.forEach((sourceLayer) => {
      removeMapLayer(map, sourceLayer.sourceLayer);
    });
  }

  if (current_metric === "none") {
    return;
  }

  addFillLayer(map, source, sourceLayers, firstSymbolLayerID, simulationSlug);
  hasLayer = true;
}

export function removeWindResource(map: mapboxgl.Map | null) {
  if (!map) return;

  const sourceIds = new Set<string>();

  Array.from(document.getElementsByClassName("wind-resource-popup")).forEach(
    (popup) => {
      popup.remove();
    }
  );

  loadedLayerIDs.forEach((layerID) => {
    console.debug("removing layer", layerID);
    try {
      const layer = map.getLayer(layerID) as SourceVectorLayer;
      if (layer) {
        sourceIds.add(layer.source as string);
        removeMapLayer(map, layerID);
      }
    } catch (e) {
      console.warn("error removing layer", layerID, e);
    }

    loadedLayerIDs.delete(layerID);
  });

  sourceIds.forEach((sourceId) => {
    removeMapSource(map, sourceId);
  });

  map.off("click", () => {});

  hasLayer = false;
}

export function addWindResource(
  map: mapboxgl.Map,
  simulationSlug: string,
  source: WindMapSource,
  sourceLayers: SourceLayer[],
  metric: metricsOrNone,
  threshold: number,
  onMouseOver?: (data?: WindResourceData) => void
) {
  const interactiveLayerIDs = Object.values(sourceLayers)
    .filter((sl) => sl.interactive)
    .map((sl) => sl.sourceLayer);

  current_metric = metric;
  current_threshold = threshold;
  const layers = map.getStyle().layers;

  // Find the index of the first symbol layer in the map style.
  for (const layer of layers) {
    if (layer.type === "symbol") {
      firstSymbolLayerID = layer.id;
      break;
    }
  }

  const mapSource = map.getSource(source.id) as VectorSource;
  if (mapSource) {
    console.warn("already have mapSource", mapSource);
    // mapSource.setData(source.url);
  } else {
    map.addSource(source.id, {
      type: source.type,
      url: source.url,
    });
  }

  let cellID: string | number | undefined;

  // renderStyles(map)
  rerenderWindResourceMap(
    map,
    source,
    sourceLayers,
    current_metric,
    current_threshold,
    simulationSlug
  );

  // TODO: guard against multiple possible layers?
  const PRIMARY_INTERACTIVE_LAYER = sourceLayers.filter(
    (sl) => sl.interactive
  )[0];

  map.on("mousemove", interactiveLayerIDs, (event) => {
    map.getCanvas().style.cursor = "pointer";

    // Check whether features exist
    if (!event.features || event.features.length === 0) return;

    const feature = event.features[0];
    if (!feature) return;

    const { properties } = event.features[0];
    if (!properties) return;

    if (onMouseOver) {
      onMouseOver(properties as WindResourceData);
    }

    // configure INTERACTIVITY

    // If cellID for the hovered feature is not null,
    // use removeFeatureState to reset to the default behavior
    if (cellID) {
      map.removeFeatureState({
        source: PRIMARY_INTERACTIVE_LAYER.source,
        sourceLayer: PRIMARY_INTERACTIVE_LAYER.sourceLayer,
        id: cellID,
      });
    }

    cellID = feature.id;

    map.setFeatureState(
      {
        source: PRIMARY_INTERACTIVE_LAYER.source,
        sourceLayer: PRIMARY_INTERACTIVE_LAYER.sourceLayer,
        id: cellID,
      },
      {
        hover: true,
      }
    );
  });

  map.on("mouseleave", interactiveLayerIDs, () => {
    if (cellID) {
      map.setFeatureState(
        {
          source: PRIMARY_INTERACTIVE_LAYER.source,
          sourceLayer: PRIMARY_INTERACTIVE_LAYER.sourceLayer,
          id: cellID,
        },
        {
          hover: false,
        }
      );

      if (onMouseOver) {
        onMouseOver(undefined);
      }
    }

    cellID = undefined;
    map.getCanvas().style.cursor = "";
  });
}

function addFillLayer(
  map: mapboxgl.Map,
  source: WindMapSource,
  sourceLayers: SourceLayer[],
  firstSymbolLayerID: string | undefined,
  simulationSlug: string
) {
  if (current_metric === "none") return;

  // console.debug(filter);
  const dataLayer = DATA_LAYERS[current_metric];

  if (!dataLayer) {
    console.error("no data layer for", current_metric);
    return;
  }

  let formatting = "ws";
  if (
    current_metric === "gcfaf" ||
    current_metric === "gcfnf" ||
    current_metric === "gcfd"
  ) {
    formatting = "gcf";
  }

  sourceLayers.forEach((sourceLayer) => {
    loadedLayerIDs.add(sourceLayer.sourceLayer);
    const layer = map.getLayer(sourceLayer.sourceLayer);
    if (layer) return;

    const fillColor = source.fillColor || simplifiedFillInterpolation(source);
    const fillOpacity =
      source.fillOpacity || simplifiedOpacityInterpolation(source);

    // console.debug(`addFillLayer ${current_metric}`, sourceLayer);
    map.addLayer(
      {
        id: sourceLayer.sourceLayer,
        type: "fill",
        source: sourceLayer.source,
        "source-layer": sourceLayer.sourceLayer,
        minzoom: sourceLayer.minzoom,
        maxzoom: sourceLayer?.maxzoom || 24,
        paint: {
          "fill-opacity": fillOpacity,
          "fill-color": fillColor,
        },
        metadata: {
          name: dataLayer.label,
          formatting,

          metric: current_metric,
        },
      },
      firstSymbolLayerID
    );

    if (sourceLayer.interactive) {
      bindPopup(
        map,
        sourceLayer,
        new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          closeOnMove: true,
          className: "wind-resource-popup",
        }),
        simulationSlug
      );
    }
  });
}

function bindPopup(
  map: mapboxgl.Map,
  sourceLayer: SourceLayer,
  popup: mapboxgl.Popup,
  simulationSlug: string
) {
  const layerID = sourceLayer.sourceLayer;
  let clickedFeatureID: string | number | undefined;

  map.on("click", layerID, async (event) => {
    if (clickedFeatureID) {
      map.removeFeatureState({
        source: sourceLayer.source,
        sourceLayer: sourceLayer.sourceLayer,
        id: clickedFeatureID,
      });
    }

    if (!event.features || event.features.length === 0) return;
    const feature = event.features[0];
    clickedFeatureID = feature.id;
    const center = centerOfMass(feature);

    const properties = feature.properties;

    if (!properties) return;

    const cellData = await getCellData({
      simulationSlug,
      gridId: properties.sgid || properties.id,
    });

    const coordinates = center.geometry.coordinates;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popupContents = formatWindResourcePopup(cellData, properties);
    popup.setLngLat(coordinates).setHTML(popupContents).addTo(map);

    map.setFeatureState(
      {
        source: sourceLayer.source,
        sourceLayer: sourceLayer.sourceLayer,
        id: feature.id,
      },
      {
        clicked: true, // this styling does seem to be working yet
      }
    );
  });

  map.on("zoomstart", () => {
    if (popup) popup.remove();
  });
}

// TODO: decide where to put this
function simplifiedFillInterpolation(source: WindMapSource) {
  return {
    property: "wsaf",
    stops: [
      [source.color_stops[0], "#c7eaf0"],
      [source.color_stops[1], "#299ae9"],
    ],
  };
}

function simplifiedOpacityInterpolation(source: WindMapSource) {
  return {
    property: "wsaf",
    stops: [
      [source.color_stops[0], 0.6],
      [source.color_stops[1], 0.8],
    ],
  };
}
