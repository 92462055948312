import mapboxgl, { GeoJSONSource } from "mapbox-gl";

/**
 * Make the bounding box moveable by clicking the edges and dragging. We temporarily hide the handles while dragging.
 *
 */
export default function addBoundingBoxMoveability(
  map: mapboxgl.Map,
  bboxPolygon: GeoJSON.Position[],
  HANDLES_LAYER_IDS: string[],
  BBOX_LAYER_ID: string,
  BBOX_SOURCE_ID: string,
  interactionState: { current: "resize" | "move" | null },
  setInteraction: (interaction: "resize" | "move" | null) => void,
  onChangeBounds?: (bounds: mapboxgl.LngLatBounds) => void
) {
  let isDragging = false;
  let startLngLat: mapboxgl.LngLat;

  map.on("mouseenter", BBOX_LAYER_ID, () => {
    // console.debug("bbox moveability mouseenter");
    map.getCanvas().style.cursor = "move";
  });

  map.on("mouseleave", BBOX_LAYER_ID, () => {
    // console.debug("bbox moveability mouseleave");
    map.getCanvas().style.cursor = "";
  });

  map.on("mousedown", BBOX_LAYER_ID, (e) => {
    if (interactionState.current) return;
    console.debug("bbox moveability mousedown");
    setInteraction("move");
    isDragging = true;
    map.dragPan.disable();
    HANDLES_LAYER_IDS.forEach((layerId) => {
      map.setPaintProperty(layerId, "circle-opacity", 0);
    });

    startLngLat = e.lngLat;
    e.originalEvent.stopPropagation();
  });

  map.on("mousemove", (e) => {
    if (!isDragging) return;
    if (interactionState.current !== "move") return;
    // console.debug("bbox moveability mousemove");

    const deltaLng = e.lngLat.lng - startLngLat.lng;
    const deltaLat = e.lngLat.lat - startLngLat.lat;

    bboxPolygon = bboxPolygon.map((coord) => [
      coord[0] + deltaLng,
      coord[1] + deltaLat,
    ]);

    (map.getSource(BBOX_SOURCE_ID) as GeoJSONSource).setData({
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [bboxPolygon],
      },
    });

    startLngLat = e.lngLat;
  });

  map.on("mouseup", BBOX_LAYER_ID, () => {
    if (!isDragging) return;
    if (interactionState.current !== "move") return;

    console.debug("bbox moveability mouseup");
    isDragging = false;
    setInteraction(null);
    if (onChangeBounds) {
      const updatedBounds = new mapboxgl.LngLatBounds();
      bboxPolygon.forEach((coord) =>
        updatedBounds.extend([coord[0], coord[1]])
      );
      onChangeBounds(updatedBounds);
    }

    HANDLES_LAYER_IDS.forEach((layerId) => {
      map.setPaintProperty(layerId, "circle-opacity", 1);
    });
    map.dragPan.enable();
  });
}
