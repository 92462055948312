import mapboxgl from "mapbox-gl";
import "./index.css";
import { WindResourceData } from "../Map/windResourceStore";
function generate(data?: WindResourceData): string {
  if (!data) return "";

  return `<div class="map-wind-resource-data">${data.wsaf.toFixed(2)} m/s</div>
  `;
}

export class CurrentWindResource implements mapboxgl.IControl {
  _map: mapboxgl.Map | undefined;
  _container: HTMLElement | undefined;
  _data: WindResourceData | undefined;

  constructor(data: WindResourceData | undefined) {
    this._data = data || { id: 1, wsaf: 234 };
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl map-wind-resource-ctrl";
    return this._container;
  }

  setData(data?: WindResourceData) {
    this._data = data;
    this.update();
  }

  onRemove() {
    if (this._container) {
      this._container.parentNode?.removeChild(this._container);
    }
    this._map = undefined;
  }

  update() {
    if (!this._map) return;
    if (!this._container) return;
    this._container.innerHTML = generate(this._data);
  }
}
