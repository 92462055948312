import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Privacy, Project } from "../../API";
import * as persist from "./persist";

interface ProjectState {
  projects: Project[];
  loaded: boolean;
  currentProject?: Partial<Project>;

  editingProject: boolean;
  setEditingProject: (editing: boolean) => void;
  setCurrentProject: (project?: Partial<Project>) => void;
  saveProject: (params: Partial<Project>) => Promise<boolean>;
  deleteProject: (projectId: string) => Promise<boolean>;

  newProject: () => void;
  fetch: () => Promise<void>;
}

const useProjectStore = create<ProjectState>()(
  devtools(
    (set, get) => ({
      projects: [],
      loaded: false,

      editingProject: false,
      setEditingProject: (editing: boolean) => {
        set({ editingProject: editing });
      },

      currentProject: undefined,
      setCurrentProject: (project?: Partial<Project>) => {
        set({
          currentProject: project,
        });
      },

      fetch: async (forceRefetch = false) => {
        try {
          if (get().loaded && !forceRefetch) return;
          const projects = await persist.fetchProjects();
          set({ projects, loaded: true });
        } catch (error) {
          console.error(error);
        }
      },

      newProject: () => {
        try {
          const newProject: Partial<Project> = {
            __typename: "Project",
            privacy: Privacy.PRIVATE,
          };

          get().setCurrentProject(newProject);
        } catch (error) {
          console.error(error);
        }
      },

      saveProject: async (params: Partial<Project>) => {
        try {
          const project = await persist.saveProject(params);

          let projects = get().projects;
          // add or update in state
          if (!projects.find((s) => s.id === project.id)) {
            projects = [project, ...projects] as Project[];
          } else {
            projects = projects.map((s) => (s.id === project.id ? project : s));
          }

          set((_state) => ({
            projects: projects,
            currentProject: project,
          }));
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },

      deleteProject: async (projectId: string) => {
        try {
          await persist.deleteProjectAndCascade(projectId);
          set((state) => ({
            projects: state.projects.filter((t) => t.id !== projectId),
          }));
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
    }),
    { name: "projectStore" }
  )
);

export default useProjectStore;
