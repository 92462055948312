import { Domain, DomainStatus, Privacy } from "../API";

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const oneMonthAgo = new Date();
oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

const era5_global: Domain = {
  __typename: "Domain",
  createdAt: oneWeekAgo.toISOString(),
  updatedAt: oneWeekAgo.toISOString(),
  id: "d-era5",
  slug: "domain_era5_global",
  name: "ERA5 Global",
  centerLat: 0,
  centerLng: 0,
  resolution: 23000,
  width: 0,
  height: 0,
  privacy: Privacy.PUBLIC,
  status: DomainStatus.LOCKED,
};

const boem_maine: Domain = {
  id: "domain_mlwqv62r509a",
  slug: "domain_mlwqv62r509a",
  name: "BOEM Maine",
  centerLng: -68.98,
  centerLat: 42.5945,
  width: 127.0,
  height: 202.0,
  resolution: 2000.0,
  privacy: Privacy.PUBLIC,
  status: DomainStatus.LOCKED,
  createdAt: oneWeekAgo.toISOString(),
  updatedAt: oneWeekAgo.toISOString(),
  owner: "63fcace4-0e4b-4e27-a878-02f1ca0c2fad",
  __typename: "Domain",
};

const texas_panhandle: Domain = {
  __typename: "Domain",
  createdAt: oneWeekAgo.toISOString(),
  updatedAt: oneWeekAgo.toISOString(),
  id: "d-texas-demo",
  slug: "domain_texas_demo",
  name: "Texas Panhandle",
  centerLat: 32.5064,
  centerLng: -100.8848,
  resolution: 2000.0,
  width: 193.0,
  height: 198.0,
  privacy: Privacy.PUBLIC,
  status: DomainStatus.LOCKED,
};

const domains: Record<string, Domain> = {
  era5_global: era5_global,
  texas_panhandle: texas_panhandle,
  boem_maine: boem_maine,
};

export default domains;
