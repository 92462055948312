import { Box, Text } from "@chakra-ui/react";
import { Simulation } from "../../API";

export default function SimulationStatusMessage(props: {
  simulation: Partial<Simulation>;
}) {
  const { statusMessage } = props.simulation;
  if (!statusMessage) return null;

  return (
    <Box width="100%" mt={1}>
      <Text fontSize="xs" color="gray.500">
        {statusMessage}
      </Text>
    </Box>
  );
}
