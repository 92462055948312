import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7b6d9bb9c0dd5dfd8a5914f08b5b76af@o4506854557351936.ingest.us.sentry.io/4506854565085184",
  environment: import.meta.env.DEV ? "development" : "production",

  // disable in dev environment
  enabled: !import.meta.env.DEV,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 0.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    RegExp(
      "https://wijue3qogjdp3d4f4fgdttkxdy.appsync-api.us-west-2.amazonaws.com/graphql"
    ),
    RegExp("https://t3uj22wpy4.execute-api.us-west-2.amazonaws.com/"),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
