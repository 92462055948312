import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { removeBoundingBox } from "./addBoundingBox";
import { removeMapLayer, removeMapSource } from "./removeMapElements";

export const TURBINE_COLORS = [
  "#DD6B20",
  "#1e1e1e",
  "#F687B3",
  "#9F7AEA",
  "#00ba3f",
  "#97266D",
  "#2C7A7B",
  "#fa2f62",
  "#652B19",
];

const TURBINE_FALLBACK_COLOR = "#0b002e";

export function addSimulationTurbines(
  map: mapboxgl.Map,
  geojson: any
  //onMouseOver?: (data: TurbineData | null) => void
) {
  if (!map) return;

  const source = map.getSource("turbines-preview-source") as GeoJSONSource;
  if (source) {
    source.setData(geojson);
  } else {
    map.addSource("turbines-preview-source", {
      type: "geojson",
      data: geojson,
    });
  }

  if (geojson.features.length === 0) {
    return;
  }

  const circleColors: mapboxgl.Expression = ["match", ["get", "slug"]];
  const turbineTypes: string[] = [];
  geojson.features.forEach((feature: any) => {
    const type = feature.properties.slug;
    if (!turbineTypes.includes(type)) {
      turbineTypes.push(type);
      circleColors.push(type);
      const turbineColor =
        TURBINE_COLORS[turbineTypes.indexOf(type) % TURBINE_COLORS.length];
      circleColors.push(turbineColor);
    }
  });
  circleColors.push(TURBINE_FALLBACK_COLOR); // other

  const layer = map.getLayer("turbines-preview-layer");
  if (!layer) {
    map.addLayer({
      id: "turbines-preview-layer",
      type: "circle",
      source: "turbines-preview-source",
      paint: {
        "circle-opacity": {
          stops: [
            [0, 0],
            [2, 1],
          ],
        },
        "circle-radius": {
          // base: 0,
          stops: [
            [0, 0],
            [2.79, 0],
            [2.8, 3],
            [7, 1],
            [11, 3],
          ],
        },
        "circle-color": circleColors,
      },
    });
  }

  // turn off popup for now because it is conflicting with wind resource popup
  // bindPopup(map, "turbines-preview-layer", popup);
}

export function removeSimulationPreviewTurbines(map: mapboxgl.Map | null) {
  if (!map) return;
  Array.from(document.getElementsByClassName("turbine-popup")).forEach(
    (popup) => {
      popup.remove();
    }
  );

  try {
    removeBoundingBox(map);
    removeMapLayer(map, "turbines-preview-layer");
    removeMapSource(map, "turbines-preview-source");
  } catch (e) {
    console.warn(
      "Error removing simulation preview turbines - this is expected during dev",
      e
    );
  }
}
