import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PowerCurve } from "../Turbines/generatePowerCurveJSON";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  powerCurve: PowerCurve | undefined;
  rowLimit: number;
}

export function PowerThrustModal(props: Props) {
  const { isOpen, onClose, powerCurve, rowLimit } = props;

  if (!powerCurve || powerCurve.length < 1) return null;
  const rows = powerCurve.slice(0, rowLimit).map((row, i) => {
    return (
      <Tr key={i}>
        <Td fontSize="xs" isNumeric>
          {row[0]}
        </Td>
        <Td fontSize="xs" isNumeric>
          {row[1]}
        </Td>
        <Td fontSize="xs" isNumeric>
          {row[2]}
        </Td>
      </Tr>
    );
  });

  const placeholder =
    powerCurve.length > rowLimit ? (
      <Tr key="placeholder">
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
      </Tr>
    ) : null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Power/Thrust</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer mb={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th verticalAlign="bottom" isNumeric>
                    Wind Speed
                  </Th>
                  <Th verticalAlign="bottom" isNumeric>
                    C<sub>t</sub>
                  </Th>
                  <Th verticalAlign="bottom" isNumeric>
                    Power
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {rows}
                {placeholder}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PowerThrustModal;
