import mapboxgl from "mapbox-gl";
import zip from "lodash/zip";

export const scaleValueToRange = (
  val: number,
  x1: number,
  y1: number,
  x2 = 0,
  y2 = 100
) => ((val - x1) * (y2 - x2)) / (y1 - x1) + x2;

/* Heavily borrowed from https://github.com/markusand/mapboxgl-legend */
export default function generateLegend(
  expression: mapboxgl.StyleFunction,
  name?: string,
  formatting?: string
): string {
  if (!expression.stops) return "";

  const stops = expression.stops;
  const [inputs] = zip(...stops);
  const min = Math.min(...(inputs.flat(2) as number[]));
  const max = Math.max(...(inputs.flat(2) as number[]));
  const mid = (min + max) / 2;
  const gradient = stops.map(
    ([value, color]) => `${color} ${scaleValueToRange(value, min, max)}%`
  );

  let values;

  if (formatting === "ws") {
    values = `
      <span style="left: 0%">${min.toFixed(1)} m/s</span>
      <span style="left: 50%; transform: translateX(-50%)">${mid.toFixed(
        1
      )} m/s</span>
      <span style="left: 100%">${max.toFixed(1)} m/s</span>
    `;
  } else {
    values = `
      <span style="left: 0%">${min.toFixed(2)}</span>
      <span style="left: 50%; transform: translateX(-50%)">${mid.toFixed(
        2
      )}</span>
      <span style="left: 100%">${max.toFixed(2)}</span>
    `;
  }

  return `<div class="wind-speed-legend-control">
    <div class="legend">
      <h6 class="title">${name}</h6>
      <p class="values">
        ${values}
      </p>
      <div
        class="bar"
        style="background-image: linear-gradient(90deg, ${gradient});"></div>
      </div>
    </div>
  `;
}
