// @ts-expect-error
import bboxPolygon from "@turf/bbox-polygon";
import mapboxgl from "mapbox-gl";
import addBoundingBoxMoveability from "./addBoundingBoxMoveability";
import addBoundingBoxResizability from "./addBoundingBoxResizability";
import { removeMapLayer, removeMapSource } from "./removeMapElements";
const BBOX_SOURCE_ID = "bounding-box";
const BBOX_LAYER_ID = "bounding-box-layer";
const HANDLES_SOURCE_ID = "handles";
const HANDLES_LAYER_ID = "handles-layer";
const HANDLES_INNER_LAYER_ID = "handles-layer-inner";
const HANDLE_DIRECTIONS = ["sw", "se", "ne", "nw"];
// checkout https://github.com/mapbox/mapbox-gl-js/blob/466ee28b7ee6a1bff63d0d6d591cc535c70f487f/src/ui/handler/box_zoom.js
// for some inspiration for a simpler refactor

export function addBoundingBox(
  map: mapboxgl.Map,
  bounds: mapboxgl.LngLatBounds | null,
  fitBounds: boolean = true,
  onChangeBounds?: (bounds: mapboxgl.LngLatBounds) => void
) {
  if (!map || !bounds) return;

  const interactionState = {
    current: null as "resize" | "move" | null,
  };

  function setInteraction(interaction: "resize" | "move" | null) {
    console.debug("bbox setInteraction", interaction);
    interactionState.current = interaction;
  }

  removeBoundingBox(map);
  const isEditable = !!onChangeBounds;

  const boundsArray = [
    bounds.getWest(),
    bounds.getSouth(),
    bounds.getEast(),
    bounds.getNorth(),
  ] as [number, number, number, number];

  const boundsFeature = bboxPolygon(
    boundsArray
  ) as GeoJSON.Feature<GeoJSON.Polygon>;

  const boundsPolygon = boundsFeature.geometry.coordinates[0];

  const handles: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: "FeatureCollection",
    features: boundsPolygon.slice(0, 4).map((coord, idx) => ({
      id: idx + 1,
      type: "Feature",
      properties: { idx, direction: HANDLE_DIRECTIONS[idx] },
      geometry: {
        type: "Point",
        coordinates: coord,
      },
    })),
  };

  map.addSource(BBOX_SOURCE_ID, {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [boundsPolygon],
      },
    },
  });

  const dashArray = isEditable ? [1, 2] : [1, 0];

  const lineWidth = isEditable ? 3 : 1;
  const lineColor = isEditable ? "#C53030" : "#ECC94B";

  map.addLayer({
    id: BBOX_LAYER_ID,
    type: "line",
    source: BBOX_SOURCE_ID,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": lineColor,
      "line-width": lineWidth,
      "line-dasharray": dashArray,
      // "fill-color": "#088",
      // "fill-opacity": 0.3,
    },
  });

  if (isEditable) {
    map.addSource(HANDLES_SOURCE_ID, { type: "geojson", data: handles });

    map.addLayer({
      id: HANDLES_LAYER_ID,
      type: "circle",
      source: HANDLES_SOURCE_ID,
      paint: {
        "circle-radius": 10,
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#F6E05E",
          "rgba(255, 0, 0, 0.2)",
        ],
      },
    });

    map.addLayer({
      id: HANDLES_INNER_LAYER_ID,
      type: "circle",
      source: HANDLES_SOURCE_ID,
      paint: {
        "circle-radius": 5,
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#F6E05E",
          "#C53030",
        ],
      },
    });

    addBoundingBoxResizability(
      map,
      boundsPolygon,
      handles,
      HANDLES_LAYER_ID,
      HANDLES_SOURCE_ID,
      BBOX_SOURCE_ID,
      interactionState,
      setInteraction,
      onChangeBounds
    );

    addBoundingBoxMoveability(
      map,
      boundsPolygon,
      [HANDLES_LAYER_ID, HANDLES_INNER_LAYER_ID],
      BBOX_LAYER_ID,
      BBOX_SOURCE_ID,
      interactionState,
      setInteraction,
      onChangeBounds
    );
  }

  if (fitBounds) {
    try {
      map.fitBounds(bounds, { padding: 20, animate: false });
    } catch (e) {
      console.warn(e);
    }
  }
}

export function removeBoundingBox(map: mapboxgl.Map | null) {
  if (!map) return;
  removeMapLayer(map, HANDLES_LAYER_ID);
  removeMapLayer(map, HANDLES_INNER_LAYER_ID);
  removeMapLayer(map, BBOX_LAYER_ID);
  removeMapSource(map, HANDLES_SOURCE_ID);
  removeMapSource(map, BBOX_SOURCE_ID);
}
