import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
  CreateProjectInput,
  CreateProjectMutation,
  CreateSimulationInput,
  CreateSimulationMutation,
  CreateDomainInput,
  CreateDomainMutation,
  DeleteProjectMutation,
  DeleteSimulationMutation,
  GetSimulationQuery,
  GetDomainQuery,
  ListProjectsQuery,
  ListDomainsQuery,
  ListSimulationsQuery,
  Privacy,
  Project,
  Simulation,
  Domain,
  DomainStatus,
  SimulationStatus,
  UpdateProjectInput,
  UpdateProjectMutation,
  UpdateSimulationInput,
  UpdateSimulationMutation,
  UpdateDomainInput,
  UpdateDomainMutation,
} from "../../API";
import { DEMO_SIMULATIONS } from "../../demo/simulations";
import DEMO_DOMAINS from "../../demo/domains";
import generateId from "../../generateId";
import {
  createProject,
  createSimulation,
  createDomain,
  deleteProject,
  deleteSimulation,
  updateProject,
  updateSimulation,
  updateDomain,
} from "../../graphql/mutations";
import {
  getSimulation,
  getDomain,
  listProjects,
  listDomains,
  listSimulations,
} from "../../graphql/queries";
import {
  listSimulationsToc,
  ListSimulationsTocQuery,
} from "../../graphql/customQueries";
import submitSimulation from "../../endpoints/submitSimulation.js";

const fetchSimulations = async (): Promise<Partial<Simulation>[]> => {
  const simulations: Partial<Simulation>[] = [];
  try {
    let nextToken: string | null | undefined = undefined;

    let apiData: GraphQLResult<GraphQLQuery<ListSimulationsTocQuery>>;

    do {
      apiData = await API.graphql<GraphQLQuery<ListSimulationsTocQuery>>(
        graphqlOperation(listSimulationsToc, {
          nextToken: nextToken,
          limit: 50,
        })
      );
      nextToken = apiData?.data?.listSimulations?.nextToken;

      apiData?.data?.listSimulations?.items?.forEach(
        (simulation: Partial<Simulation>) => {
          if (!simulation) return;
          simulations.push(simulation);
        }
      );
    } while (nextToken);

    DEMO_SIMULATIONS.forEach((demoSimulation: Simulation) => {
      simulations.push(demoSimulation);
    });

    return simulations || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const fetchLocalSimulation = (simulationId: string): Simulation | undefined => {
  for (const demoSimulation of DEMO_SIMULATIONS) {
    if (demoSimulation.id === simulationId) {
      return demoSimulation;
    }
  }

  return undefined;
};

const retrieveSimulation = async (
  id: string
): Promise<Simulation | undefined> => {
  try {
    let simulation: Simulation | undefined = fetchLocalSimulation(id);
    if (simulation) return simulation;

    const apiData = await API.graphql<GraphQLQuery<GetSimulationQuery>>(
      graphqlOperation(getSimulation, { id: id })
    );
    simulation = apiData?.data?.getSimulation || undefined;
    return simulation;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

const saveSimulation = async (
  params: Partial<UpdateSimulationInput> | Partial<CreateSimulationInput>
): Promise<Simulation> => {
  params.privacy = Privacy.PRIVATE;
  if (!params.status) {
    params.status = SimulationStatus.READY_TO_SUBMIT;
  }

  if (params.id) {
    const result = await API.graphql<GraphQLQuery<UpdateSimulationMutation>>(
      graphqlOperation(updateSimulation, {
        input: params,
      })
    );

    const simulation = result?.data?.updateSimulation as Simulation;
    return simulation;
  } else {
    const id = generateId("sim");
    const result = await API.graphql<GraphQLQuery<CreateSimulationMutation>>(
      graphqlOperation(createSimulation, {
        input: { ...params, id: id, slug: id },
      })
    );

    if (!result?.data?.createSimulation?.id) {
      throw new Error("No simulation id");
    }

    const simulation = result?.data?.createSimulation as Simulation;
    return simulation;
  }
};

const saveProject = async (
  params: Partial<UpdateProjectInput> | Partial<CreateProjectInput>
): Promise<Project> => {
  params.privacy = Privacy.PRIVATE;
  if (params.id) {
    const result = await API.graphql<GraphQLQuery<UpdateProjectMutation>>(
      graphqlOperation(updateProject, {
        input: params,
      })
    );

    const project = result?.data?.updateProject as Project;
    return project;
  } else {
    const id = generateId("project");

    const result = await API.graphql<GraphQLQuery<CreateProjectMutation>>(
      graphqlOperation(createProject, {
        input: { ...params, id: id, slug: id },
      })
    );

    if (!result?.data?.createProject?.id) {
      throw new Error("No project id");
    }

    const project = result?.data?.createProject as Project;
    return project;
  }
};

const fetchProjects = async (): Promise<Project[]> => {
  try {
    const apiData = await API.graphql<GraphQLQuery<ListProjectsQuery>>(
      graphqlOperation(listProjects)
    );
    const projects = apiData?.data?.listProjects?.items as Project[];

    return projects || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const saveDomain = async (
  params: Partial<UpdateDomainInput> | Partial<CreateDomainInput>
): Promise<Domain> => {
  params.privacy = Privacy.PRIVATE;

  if (params.id) {
    const result = await API.graphql<GraphQLQuery<UpdateDomainMutation>>(
      graphqlOperation(updateDomain, {
        input: params,
      })
    );

    const domain = result?.data?.updateDomain as Domain;
    return domain;
  } else {
    const id = generateId("domain");

    const result = await API.graphql<GraphQLQuery<CreateDomainMutation>>(
      graphqlOperation(createDomain, {
        input: { ...params, id: id, slug: id },
      })
    );

    if (!result?.data?.createDomain?.id) {
      throw new Error("No domainId");
    }

    const domain = result?.data?.createDomain as Domain;
    return domain;
  }
};

const fetchDomains = async (): Promise<Domain[]> => {
  const domains: Domain[] = Object.values(DEMO_DOMAINS);
  try {
    let nextToken: string | null | undefined = undefined;

    do {
      const apiData: GraphQLResult<GraphQLQuery<ListDomainsQuery>> =
        await API.graphql<GraphQLQuery<ListDomainsQuery>>(
          graphqlOperation(listDomains, {
            nextToken: nextToken,
            limit: 100,
          })
        );

      apiData?.data?.listDomains?.items.forEach((domain) => {
        if (!domain) return;
        domains.push(domain);
      });

      nextToken = apiData?.data?.listDomains?.nextToken;
    } while (nextToken);

    return domains || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const deleteProjectAndCascade = async (projectId: string) => {
  // fetch all simulations for this project
  const simulations = await API.graphql<GraphQLQuery<ListSimulationsQuery>>(
    graphqlOperation(listSimulations, {
      limit: 1000,
      filter: {
        projectId: {
          eq: projectId,
        },
      },
    })
  );

  // update all simulations to remove projectId
  simulations?.data?.listSimulations?.items.forEach(async (simulation) => {
    await API.graphql<GraphQLQuery<UpdateSimulationMutation>>(
      graphqlOperation(updateSimulation, {
        input: {
          id: simulation?.id,
          projectId: null,
          project: null,
        },
      })
    );
  });

  // delete project
  await API.graphql<GraphQLQuery<DeleteProjectMutation>>(
    graphqlOperation(deleteProject, {
      input: {
        id: projectId,
      },
    })
  );
};

const deleteCurrentSimulation = async (id: string) => {
  try {
    await API.graphql<GraphQLQuery<DeleteSimulationMutation>>(
      graphqlOperation(deleteSimulation, { input: { id: id } })
    );
  } catch (error) {
    console.error(error);
  }
};

const updateDomainCoordinates = async (
  domainId: string,
  centerLng: number,
  centerLat: number,
  width: number,
  height: number,
  resolution: number
) => {
  // retrieve domain
  const result = await API.graphql<GraphQLQuery<GetDomainQuery>>(
    graphqlOperation(getDomain, { id: domainId })
  );

  const domain = result?.data?.getDomain as Domain | undefined;

  if (!domain) {
    throw new Error("No domain");
  }

  if (domain.status !== DomainStatus.EDITABLE) {
    throw new Error("Domain is not editable");
  }

  // update domain
  await API.graphql<GraphQLQuery<UpdateDomainMutation>>(
    graphqlOperation(updateDomain, {
      input: {
        id: domainId,
        centerLng: centerLng,
        centerLat: centerLat,
        width: width,
        height: height,
        resolution: resolution,
      },
    })
  );

  // update all simulations in the saved domain
  const simulations = await API.graphql<GraphQLQuery<ListSimulationsQuery>>(
    graphqlOperation(listSimulations, {
      filter: {
        domainId: {
          eq: domainId,
        },
      },
    })
  );

  simulations?.data?.listSimulations?.items.forEach(async (simulation) => {
    await API.graphql<GraphQLQuery<UpdateSimulationMutation>>(
      graphqlOperation(updateSimulation, {
        input: {
          id: simulation?.id,
          centerLng: centerLng,
          centerLat: centerLat,
          width: width,
          height: height,
          resolution: resolution,
        },
      })
    );
  });
};

export {
  deleteCurrentSimulation,
  deleteProjectAndCascade,
  fetchProjects,
  fetchDomains,
  fetchSimulations,
  retrieveSimulation,
  saveProject,
  saveSimulation,
  saveDomain,
  submitSimulation,
  updateDomainCoordinates,
};
