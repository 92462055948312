import { Heading } from "@aws-amplify/ui-react";
import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  Text,
  VStack,
  defineStyle,
} from "@chakra-ui/react";
import { Element, Link as ScrollLink } from "react-scroll";

const paragraphStyles = defineStyle({
  fontSize: "md",
  lineHeight: "tall",
  color: "white",
  my: 4,
});

const hedStyles = defineStyle({
  color: "white",
  fontSize: "xl",
});

const subhedStyles = defineStyle({
  color: "white",
  fontSize: "lg",
});

const topics: Record<string, string> = {
  new_simulation: "Creating a New Simulation",
  turbine_power: "Adding Turbine Power and Thrust Curves",
  map_interface: "Map Interface",
  launching_simulation: "Launching a Simulation",
  downloading_results: "Downloading Results",
};

const Sidebar = () => (
  <Box>
    <List spacing={3}>
      {Object.keys(topics).map((topic, index) => {
        const topicName = topics[topic];
        return (
          <ListItem key={index}>
            <Link
              as={ScrollLink}
              smooth={true}
              to={topic}
              spy={true}
              containerId="docs-body"
              activeStyle={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {topicName}
            </Link>
          </ListItem>
        );
      })}
    </List>
  </Box>
);

const Content = () => {
  return (
    <Box>
      <VStack gap={2}>
        <Box w="full" bg="blackAlpha.500" p={5}>
          <Element name={"new_simulation"}>
            <Box mb={4}>
              <Heading {...hedStyles}>Creating a New Simulation</Heading>
            </Box>

            <Heading {...subhedStyles}>Domain:</Heading>
            <Text {...paragraphStyles}>
              Either create a new domain by specifying key information or load
              an existing spatial domain. When creating a new domain, you can
              save the domain for use in another simulation (e.g., with a
              different turbine layout).
            </Text>
            <Heading {...subhedStyles}>Timeframe:</Heading>
            <Text {...paragraphStyles}>
              Choose between a custom timeframe or a Typical Meteorological Year
              (where we select 12 independent calendar months most
              representative of long-term conditions for that month).
            </Text>
            <Heading {...subhedStyles}>Turbines (optional):</Heading>
            <Text {...paragraphStyles}>
              Upload a CSV file containing info for wind turbines to be modeled
              in the simulation. A CSV template is available for download. The
              required fields, in order, are Turbine ID (any number will do),
              latitude, longitude, hub-height, turbine model<sup>1</sup>, and
              associated wind farm<sup>2</sup>.
            </Text>
            <Heading {...subhedStyles}>Note 1:</Heading>
            <Text {...paragraphStyles}>
              the turbine_model field must match to a corresponding entry in the
              user’s Turbines database. See the section on “Adding Turbiner
              Power and Thrust Curves”.
            </Text>
            <Heading {...subhedStyles}>Note 2:</Heading>
            <Text {...paragraphStyles}>
              Adding the wind farm field allows WakeMap to produce a single
              timeseries file for the wind farm as a whole, which includes sum
              of power across all turbines.
            </Text>
            <Heading {...subhedStyles}>Timeseries Extraction:</Heading>
            <Text {...paragraphStyles}>
              Click this box to extract timeseries data at every grid cell that
              contains a turbine.
            </Text>
            <Heading {...subhedStyles}>Virtual Met Masts (optional):</Heading>
            <Text {...paragraphStyles}>
              Upload a CSV file containing additional coordinates where you’d
              like timeseries data extracted. A CSV template is available for
              download. The required fields, in order, are ID (any number will
              do), latitude, and longitude.
            </Text>
            <Heading {...subhedStyles}>Extract WRGs (optional):</Heading>
            <Text {...paragraphStyles}>
              Select heights at which to extract WRG data. Height options are
              80m, 120m, 160m, and 200m.
            </Text>
            <Heading {...subhedStyles}>Price Estimate:</Heading>
            <Text {...paragraphStyles}>
              The price estimate shown at the bottom of the panel and is based
              on the number of grid cells modeled, the spatial resolution, the
              number of timeseries requested, and the number of WRGs requested.
            </Text>
          </Element>
        </Box>

        <Box w="full" bg="blackAlpha.500" p={5}>
          <Element name={"turbine_power"}>
            <Heading {...hedStyles}>
              Adding Turbine Power and Thrust Curves
            </Heading>

            <Text {...paragraphStyles}>
              When wind turbines are included in the WakeMap simulation, power
              and thrust curves must be specified. For each unique turbine model
              specified in the turbine location CSV file (see Creating a
              Simulation section), a corresponding CSV file that provides power
              and thrust data must be provided.
            </Text>
            <Heading {...subhedStyles}>Turbine Identifier: </Heading>
            <Text {...paragraphStyles}>
              The name used to identify a wind turbine model. Crucially, this
              string must match the name specified in the turbine locations CSV
              file (see Creating a Simulation Section).
            </Text>
            <Heading {...subhedStyles}>Description (optional):</Heading>
            <Text {...paragraphStyles}>
              Enter a more detailed description of the wind turbine here.
            </Text>
            <Heading {...subhedStyles}>Rotor diameter:</Heading>
            <Text {...paragraphStyles}>Specify in meters. </Text>
            <Heading {...subhedStyles}>Rated power:</Heading>
            <Text {...paragraphStyles}>Specify in kW.</Text>
          </Element>
        </Box>

        <Box bg="blackAlpha.500" p={5}>
          <Element name={"map_interface"}>
            <Heading {...hedStyles}>Map Interface</Heading>

            <Text {...paragraphStyles}>
              Use the map interface to fine-tune the spatial domain (using the
              corners of the red box) and to confirm the turbine locations and
              models.
            </Text>
          </Element>
        </Box>

        <Box bg="blackAlpha.500" p={5}>
          <Element name={"launching_simulation"}>
            <Heading {...hedStyles}>Submitting a Simulation</Heading>

            <Text {...paragraphStyles}>
              Once a simulation has been saved, it can be submitted by going
              back to the main menu, locating the simulation entry, and clicking
              on the start button.
            </Text>
          </Element>
        </Box>
        <Box bg="blackAlpha.500" p={5}>
          <Element name={"downloading_results"}>
            <Heading {...hedStyles}>Downloading Results</Heading>

            <Text {...paragraphStyles}>
              Once you’re notified that your simulation is complete, navigate to
              the simulation in the main menu and you should now see a download
              link for a tar.gz file. This file will include the requested
              timeseries and WRG data.
            </Text>
          </Element>
        </Box>
      </VStack>
    </Box>
  );
};

export default function Docs() {
  return (
    <Container
      maxW="container.xl"
      className="docs-container"
      bgColor="blackAlpha.800"
      textColor="white"
      zIndex={10}
      pointerEvents={"auto"}
      id="docs-body"
      position="absolute"
      height="100%"
      width="100%"
      left={0}
      top={0}
      overflowX={"auto"}
    >
      <Box
        id="docs-sidebar"
        h="full"
        w="300px"
        position="fixed"
        height="100%"
        zIndex={2}
        left={0}
        overflowX={"auto"}
        p="5"
        display={{ base: "none", md: "block" }}
      >
        <Sidebar />
      </Box>
      <Box p={5} ml={{ base: 0, md: "300px" }}>
        <Content />
      </Box>
    </Container>
  );
}
