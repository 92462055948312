import { Box, Text } from "@chakra-ui/react";

interface Props {
  gridCellCount: number | undefined;
  resolution: number | null | undefined;
}

function SimulationGridCellCount(props: Props) {
  const { gridCellCount, resolution } = props;
  if (!gridCellCount || !resolution) return null;

  return (
    <Box width="100%">
      {gridCellCount && gridCellCount >= 0 ? (
        <Text
          fontSize="sm"
          display={"block"}
          borderColor="teal.900"
          borderStyle="solid"
          borderWidth={1}
          background="yellow.100"
          color={"blackAlpha.800"}
          borderRadius={6}
          p={4}
        >
          <Text as="b">
            {gridCellCount.toLocaleString()} grid cells @{" "}
            {(resolution / 1000.0 || 0).toLocaleString()} km resolution
          </Text>
        </Text>
      ) : undefined}
    </Box>
  );
}

export default SimulationGridCellCount;
