import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { FormSelect } from "../FormControls";
import useDomainStore from "../Simulations/domainStore";
import { SimulationFormValues } from "../Simulations/types";
import ManageDomains from "./ManageDomains";
import { Privacy } from "../../API";

type Props = {
  register: UseFormRegister<SimulationFormValues>;
  errors: FieldErrors<SimulationFormValues>;
  handleSelectDomain: (domainId: string) => void;
};

export default function SelectSavedDomain(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { domains, fetch } = useDomainStore();
  const { register, errors } = props;

  useEffect(() => {
    async function load() {
      await fetch();
    }
    load();
  }, [fetch]);

  const onCloseDomainModal = (domainId: string | null) => {
    if (domainId) {
      props.handleSelectDomain(domainId);
    }
    onClose();
  };

  const privateDomains = domains.filter(
    (domain) => domain.privacy === Privacy.PRIVATE
  );

  const domainOptions = privateDomains.map((domain) => ({
    value: domain.id || "",
    label: domain.name || "",
  }));

  domainOptions.unshift({ value: "", label: "- manual entry -" });

  return (
    <Box>
      <ManageDomains isOpen={isOpen} onClose={onCloseDomainModal} />

      <Box>
        <FormSelect
          name="domainId"
          options={domainOptions}
          register={register}
          error={errors.domainId}
        />
      </Box>

      <Button
        display={"inline-block"}
        my={4}
        colorScheme="gray"
        size="xs"
        variant={"outline"}
        onClick={onOpen}
      >
        Edit
      </Button>
    </Box>
  );
}
