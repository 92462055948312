import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { SimulationTurbineList } from "../TurbineLocationUploader/helpers";
import TurbineLocationsTable from "./Table";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  turbineLocations: SimulationTurbineList | undefined;
  rowLimit: number;
}

function TurbineLocationsModal(props: Props) {
  const { isOpen, onClose, rowLimit, turbineLocations } = props;

  if (!turbineLocations) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Turbine Positions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TurbineLocationsTable
            turbineLocations={turbineLocations}
            rowLimit={rowLimit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TurbineLocationsModal;
