import {
  ChakraProvider,
  ColorModeScript,
  defineStyle,
  extendTheme,
} from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import RouterErrorBoundary from "./components/RouterErrorBoundary";
import SimulationResults from "./components/SimulationResults";
import Simulations from "./components/Simulations";
import SimulationForm from "./components/SimulationForm";
import Turbines from "./components/Turbines";
import Docs from "./routes/docs.tsx";
import TurbineForm from "./components/Turbines/TurbineForm.tsx";
import TurbineReview from "./components/TurbineReview/index.tsx";
import "./configureSentry";
import "./configureGoogleAnalytics";

const labelStyles = defineStyle({
  // fontWeight: 700,
  color: "whiteAlpha.900",
  fontSize: "12px",
  textTransform: "uppercase",
  letterSpacing: "1px",
});

// 2. Add your color mode config
const themeConfig = {
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  components: {
    // Heading: headingStyles,
    FormLabel: { baseStyle: labelStyles },
    p: {
      baseStyle: {
        marginBottom: "2rem",
      },
    },
  },
  fonts: {
    // heading: `'Agbalumo', sans-serif`,
    body: `'Open Sans', -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
};

// 3. extend the theme
const theme = extendTheme(themeConfig);

// const loader = async () => {
//   const simulations =
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <RouterErrorBoundary />,
    children: [
      { path: "/", element: <Simulations /> },
      { path: "/simulations", element: <Simulations /> },
      {
        path: "/simulations/new",
        element: <SimulationForm />,
      },
      {
        path: "/simulations/:simulationSlug",
        element: <SimulationResults />,
      },
      {
        path: "/simulations/:simulationSlug/edit",
        element: <SimulationForm />,
      },
      { path: "/turbines", element: <Turbines /> },
      { path: "/turbines/new", element: <TurbineForm /> },
      { path: "/turbines/:turbineSlug", element: <TurbineReview /> },
      { path: "/turbines/:turbineSlug/edit", element: <TurbineForm /> },
      { path: "/docs", element: <Docs /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <RouterProvider router={router} />
  </ChakraProvider>
);
