import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { MetMastLocations } from "../MetMastUploader/helpers";
import MetMastsModal from "../MetMastsModal";

type Props = {
  metMastLocations: MetMastLocations | undefined;
};

export default function MetMasts(props: Props) {
  const { metMastLocations } = props;
  if (!metMastLocations || metMastLocations.length < 1) return null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button size="sm" colorScheme="teal" onClick={onOpen}>
        Met Masts ({metMastLocations.length})
      </Button>
      <MetMastsModal
        isOpen={isOpen}
        onClose={onClose}
        rowLimit={20}
        metMastLocations={metMastLocations}
      />
    </Box>
  );
}
