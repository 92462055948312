import { Box, CloseButton, Flex, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  closeTo: string;
}

export default function SectionHeading(props: Props) {
  const { title, closeTo } = props;
  return (
    <Flex
      gap={24}
      alignContent={"space-between"}
      mb={12}
      width={"100%"}
      pb={2}
      className="section-heading"
      borderWidth={"0 0 1px"}
      borderStyle="solid"
      borderColor={"whiteAlpha.300"}
    >
      <Box flex={1}>
        <Heading
          size="md"
          fontWeight={700}
          letterSpacing={1}
          textShadow={"1px 1px 3px rgba(219,233,56,0.5)"}
        >
          {title}
        </Heading>
      </Box>
      <Box>
        <CloseButton as={Link} to={closeTo} aria-label="Cancel" />
      </Box>
    </Flex>
  );
}
