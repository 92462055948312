import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import PowerThrustChart from "./PowerThrustChart";
import { PowerCurve } from "./generatePowerCurveJSON";

interface Props {
  powerCurve: PowerCurve;
}

function PowerThrustCharts(props: Props) {
  const { powerCurve } = props;
  if (!powerCurve || powerCurve.length < 1) {
    return false;
  }

  const powerColor = useColorModeValue("teal.800", "teal.800");
  const thrustColor = useColorModeValue("red.600", "red.600");

  return (
    <Box
      m={0}
      p={0}
      maxWidth="100%"
      position={"relative"}
      overflowX={"auto"}
      className="power-thrust-charts"
    >
      <Stack direction={{ base: "column" }} spacing={4}>
        <PowerThrustChart
          powerCurve={powerCurve}
          metric="power"
          color={powerColor}
          label="Power"
          xLabel="m/s"
          yLabel="kW"
        />
        <PowerThrustChart
          powerCurve={powerCurve}
          metric="thrust"
          color={thrustColor}
          label="Thrust"
          xLabel="m/s"
          yLabel="Ct"
        />
      </Stack>
    </Box>
  );
}

export default PowerThrustCharts;
