import mapboxgl, { GeoJSONSource } from "mapbox-gl";

export default function addBoundingBoxResizability(
  map: mapboxgl.Map,
  bboxPolygon: GeoJSON.Position[],
  handles: GeoJSON.FeatureCollection<GeoJSON.Point>,
  HANDLES_LAYER_ID: string,
  HANDLES_SOURCE_ID: string,
  BBOX_SOURCE_ID: string,
  interactionState: { current: "resize" | "move" | null },
  setInteraction: (interaction: "resize" | "move" | null) => void,
  onChangeBounds?: (bounds: mapboxgl.LngLatBounds) => void
) {
  let isDragging = false;
  let draggedHandleIndex: number | null = null;

  let hoveredPolygonId: string | number | null | undefined = null;
  map.on("mouseenter", HANDLES_LAYER_ID, function (e) {
    if (!e.features) return;
    if (e.features.length === 0) return;
    e.originalEvent.stopPropagation();
    const feature = e.features[0];

    const direction = feature.properties?.direction;
    let cursor = "move";

    switch (direction) {
      case "nw":
      case "se":
        cursor = "nwse-resize";
        break;
      case "ne":
      case "sw":
        cursor = "nesw-resize";
        break;
    }

    map.getCanvas().style.cursor = cursor;
    if (hoveredPolygonId === e.features[0].id) {
      // console.debug("hover ok, no-op");
      return;
    }

    if (hoveredPolygonId) {
      // console.debug("hovering akready so removing", hoveredPolygonId);
      map.setFeatureState(
        { source: HANDLES_SOURCE_ID, id: hoveredPolygonId },
        { hover: false }
      );
    }

    hoveredPolygonId = e.features[0].id;

    if (hoveredPolygonId) {
      // if (hoveredPolygonId) console.debug("hovering new", e.features[0]);
      map.setFeatureState(
        { source: HANDLES_SOURCE_ID, id: hoveredPolygonId },
        { hover: true }
      );
    } else {
      // console.warn("no id in", e.features[0]);
    }
  });

  map.on("mouseleave", HANDLES_LAYER_ID, () => {
    // console.debug("bbox resizability mouseleave");
    map.getCanvas().style.cursor = "";
    if (hoveredPolygonId !== null) {
      map.setFeatureState(
        { source: HANDLES_SOURCE_ID, id: hoveredPolygonId },
        { hover: false }
      );
    }
    hoveredPolygonId = null;
  });

  map.on("mousedown", HANDLES_LAYER_ID, function (e) {
    if (interactionState.current) {
      // console.debug(
      //   "bbox resizability mousedown skipped, currently",
      //   interactionState.current
      // );
      return;
    }
    if (!e.features || !e.features[0]) return;
    console.debug("bbox resizability mousedown");
    setInteraction("resize");
    isDragging = true;

    const idx = e.features[0]?.properties?.idx;
    draggedHandleIndex = handles.features.findIndex(
      (f) => f.properties?.idx === idx
    );
    map.getCanvas().style.cursor = "move";
    map.dragPan.disable();
    e.originalEvent.stopPropagation();
  });

  map.on("mousemove", function (e) {
    if (!isDragging || draggedHandleIndex === null) return;
    if (interactionState.current !== "resize") {
      // console.debug(
      //   "bbox resizability mousemove skipped, currently",
      //   interactionState.current
      // );
      return;
    }
    // e.originalEvent.stopPropagation();

    // console.debug("bbox resizability mousemove2");

    switch (draggedHandleIndex) {
      case 0: // Southwest corner
        bboxPolygon[0] = [e.lngLat.lng, e.lngLat.lat];
        bboxPolygon[1] = [bboxPolygon[1][0], e.lngLat.lat];
        bboxPolygon[3] = [e.lngLat.lng, bboxPolygon[3][1]];
        break;
      case 1: // Southeast corner
        bboxPolygon[1] = [e.lngLat.lng, e.lngLat.lat];
        bboxPolygon[0] = [bboxPolygon[0][0], e.lngLat.lat];
        bboxPolygon[2] = [e.lngLat.lng, bboxPolygon[2][1]];
        break;
      case 2: // Northeast corner
        bboxPolygon[2] = [e.lngLat.lng, e.lngLat.lat];
        bboxPolygon[1] = [e.lngLat.lng, bboxPolygon[1][1]]; // SE
        bboxPolygon[3] = [bboxPolygon[3][0], e.lngLat.lat];
        break;
      case 3: // Northwest corner
        bboxPolygon[3] = [e.lngLat.lng, e.lngLat.lat];
        bboxPolygon[2] = [bboxPolygon[2][0], e.lngLat.lat];
        bboxPolygon[0] = [e.lngLat.lng, bboxPolygon[0][1]];
        break;
    }

    // Close the polygon by setting the last coordinate as the first
    bboxPolygon[4] = bboxPolygon[0];

    // Update the polygon source and the handles source
    (map.getSource(BBOX_SOURCE_ID) as mapboxgl.GeoJSONSource).setData({
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [bboxPolygon],
      },
    });

    handles.features.forEach((feature, index) => {
      if (feature.geometry.type === "Point") {
        feature.geometry.coordinates = bboxPolygon[index];
      }
    });

    (map.getSource(HANDLES_SOURCE_ID) as GeoJSONSource).setData(handles);
  });

  map.on("mouseup", function () {
    if (!isDragging) return;
    if (interactionState.current !== "resize") return;
    console.debug("bbox resizability mouseup");

    isDragging = false;
    setInteraction(null);
    map.getCanvas().style.cursor = "";
    const updatedBounds = new mapboxgl.LngLatBounds();
    bboxPolygon.forEach((coord) => updatedBounds.extend([coord[0], coord[1]]));

    if (onChangeBounds) onChangeBounds(updatedBounds);
    map.dragPan.enable();
  });
}
