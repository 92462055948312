/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelSimulationFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLat?: ModelFloatInput | null,
  centerLng?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  bounds?: ModelStringInput | null,
  area?: ModelFloatInput | null,
  gridCellCount?: ModelIntInput | null,
  typicalMeteorologicalYear?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  wrgHeights?: ModelStringInput | null,
  estimatedPrice?: ModelFloatInput | null,
  estimatedPriceBreakdown?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  projectId?: ModelIDInput | null,
  turbineLocations?: ModelStringInput | null,
  turbineTypes?: ModelStringInput | null,
  turbineCount?: ModelIntInput | null,
  useTurbineLocationsAsMetMasts?: ModelBooleanInput | null,
  metMastLocations?: ModelStringInput | null,
  metMastCount?: ModelIntInput | null,
  status?: ModelSimulationStatusInput | null,
  statusMessage?: ModelStringInput | null,
  s3Bucket?: ModelStringInput | null,
  s3Prefix?: ModelStringInput | null,
  outputs?: ModelStringInput | null,
  submitterInfo?: ModelStringInput | null,
  submitterEmail?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelSimulationFilterInput | null > | null,
  or?: Array< ModelSimulationFilterInput | null > | null,
  not?: ModelSimulationFilterInput | null,
  projectSimulationsId?: ModelIDInput | null,
  simulationSetSimulationsId?: ModelIDInput | null,
  domainSimulationsId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPrivacyInput = {
  eq?: Privacy | null,
  ne?: Privacy | null,
};

export enum Privacy {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}


export type ModelSimulationStatusInput = {
  eq?: SimulationStatus | null,
  ne?: SimulationStatus | null,
};

export enum SimulationStatus {
  DRAFT = "DRAFT",
  READY_TO_SUBMIT = "READY_TO_SUBMIT",
  SUBMITTED = "SUBMITTED",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}


export type ModelSimulationConnection = {
  __typename: "ModelSimulationConnection",
  items:  Array<Simulation | null >,
  nextToken?: string | null,
};

export type Simulation = {
  __typename: "Simulation",
  id: string,
  domainId?: string | null,
  domain?: Domain | null,
  slug: string,
  name: string,
  centerLat: number,
  centerLng: number,
  resolution: number,
  width: number,
  height: number,
  bounds?: string | null,
  area?: number | null,
  gridCellCount?: number | null,
  typicalMeteorologicalYear?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
  wrgHeights?: string | null,
  estimatedPrice?: number | null,
  estimatedPriceBreakdown?: string | null,
  privacy: Privacy,
  projectId?: string | null,
  project?: Project | null,
  turbineLocations?: string | null,
  turbineTypes?: string | null,
  turbineCount?: number | null,
  useTurbineLocationsAsMetMasts?: boolean | null,
  modelWakes?: boolean | null,
  metMastLocations?: string | null,
  metMastCount?: number | null,
  status: SimulationStatus,
  statusMessage?: string | null,
  s3Bucket?: string | null,
  s3Prefix?: string | null,
  outputs?: string | null,
  submitterInfo?: string | null,
  submitterEmail?: string | null,
  submittedAt?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  deletedAt?: string | null,
  createdAt: string,
  updatedAt: string,
  projectSimulationsId?: string | null,
  simulationSetSimulationsId?: string | null,
  domainSimulationsId?: string | null,
  owner?: string | null,
};

export type Domain = {
  __typename: "Domain",
  id: string,
  slug: string,
  name: string,
  centerLng: number,
  centerLat: number,
  width: number,
  height: number,
  resolution: number,
  privacy: Privacy,
  status: DomainStatus,
  simulations?: ModelSimulationConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum DomainStatus {
  EDITABLE = "EDITABLE",
  LOCKED = "LOCKED",
}


export type Project = {
  __typename: "Project",
  id: string,
  slug: string,
  name: string,
  privacy: Privacy,
  simulations?: ModelSimulationConnection | null,
  deletedAt?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type CreateProjectInput = {
  id?: string | null,
  slug: string,
  name: string,
  privacy: Privacy,
  deletedAt?: string | null,
};

export type ModelProjectConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type UpdateProjectInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  privacy?: Privacy | null,
  deletedAt?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateSimulationSetInput = {
  id?: string | null,
  slug: string,
  name: string,
  centerLng?: number | null,
  centerLat?: number | null,
  width?: number | null,
  height?: number | null,
  resolution?: number | null,
  privacy: Privacy,
  status: SimulationSetStatus,
};

export enum SimulationSetStatus {
  EDITABLE = "EDITABLE",
  LOCKED = "LOCKED",
}


export type ModelSimulationSetConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLng?: ModelFloatInput | null,
  centerLat?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  status?: ModelSimulationSetStatusInput | null,
  and?: Array< ModelSimulationSetConditionInput | null > | null,
  or?: Array< ModelSimulationSetConditionInput | null > | null,
  not?: ModelSimulationSetConditionInput | null,
};

export type ModelSimulationSetStatusInput = {
  eq?: SimulationSetStatus | null,
  ne?: SimulationSetStatus | null,
};

export type SimulationSet = {
  __typename: "SimulationSet",
  id: string,
  slug: string,
  name: string,
  centerLng?: number | null,
  centerLat?: number | null,
  width?: number | null,
  height?: number | null,
  resolution?: number | null,
  privacy: Privacy,
  status: SimulationSetStatus,
  simulations?: ModelSimulationConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSimulationSetInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  centerLng?: number | null,
  centerLat?: number | null,
  width?: number | null,
  height?: number | null,
  resolution?: number | null,
  privacy?: Privacy | null,
  status?: SimulationSetStatus | null,
};

export type DeleteSimulationSetInput = {
  id: string,
};

export type CreateDomainInput = {
  id?: string | null,
  slug: string,
  name: string,
  centerLng: number,
  centerLat: number,
  width: number,
  height: number,
  resolution: number,
  privacy: Privacy,
  status: DomainStatus,
};

export type ModelDomainConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLng?: ModelFloatInput | null,
  centerLat?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  status?: ModelDomainStatusInput | null,
  and?: Array< ModelDomainConditionInput | null > | null,
  or?: Array< ModelDomainConditionInput | null > | null,
  not?: ModelDomainConditionInput | null,
};

export type ModelDomainStatusInput = {
  eq?: DomainStatus | null,
  ne?: DomainStatus | null,
};

export type UpdateDomainInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  centerLng?: number | null,
  centerLat?: number | null,
  width?: number | null,
  height?: number | null,
  resolution?: number | null,
  privacy?: Privacy | null,
  status?: DomainStatus | null,
};

export type DeleteDomainInput = {
  id: string,
};

export type CreateSimulationInput = {
  id?: string | null,
  domainId?: string | null,
  slug: string,
  name: string,
  centerLat: number,
  centerLng: number,
  resolution: number,
  width: number,
  height: number,
  bounds?: string | null,
  area?: number | null,
  gridCellCount?: number | null,
  typicalMeteorologicalYear?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
  wrgHeights?: string | null,
  estimatedPrice?: number | null,
  estimatedPriceBreakdown?: string | null,
  privacy: Privacy,
  projectId?: string | null,
  turbineLocations?: string | null,
  turbineTypes?: string | null,
  turbineCount?: number | null,
  useTurbineLocationsAsMetMasts?: boolean | null,

  metMastLocations?: string | null,
  metMastCount?: number | null,
  status: SimulationStatus,
  statusMessage?: string | null,
  s3Bucket?: string | null,
  s3Prefix?: string | null,
  outputs?: string | null,
  submitterInfo?: string | null,
  submitterEmail?: string | null,
  submittedAt?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  deletedAt?: string | null,
  projectSimulationsId?: string | null,
  simulationSetSimulationsId?: string | null,
  domainSimulationsId?: string | null,
};

export type ModelSimulationConditionInput = {
  domainId?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLat?: ModelFloatInput | null,
  centerLng?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  bounds?: ModelStringInput | null,
  area?: ModelFloatInput | null,
  gridCellCount?: ModelIntInput | null,
  typicalMeteorologicalYear?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  wrgHeights?: ModelStringInput | null,
  estimatedPrice?: ModelFloatInput | null,
  estimatedPriceBreakdown?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  projectId?: ModelIDInput | null,
  turbineLocations?: ModelStringInput | null,
  turbineTypes?: ModelStringInput | null,
  turbineCount?: ModelIntInput | null,
  useTurbineLocationsAsMetMasts?: ModelBooleanInput | null,
  metMastLocations?: ModelStringInput | null,
  metMastCount?: ModelIntInput | null,
  status?: ModelSimulationStatusInput | null,
  statusMessage?: ModelStringInput | null,
  s3Bucket?: ModelStringInput | null,
  s3Prefix?: ModelStringInput | null,
  outputs?: ModelStringInput | null,
  submitterInfo?: ModelStringInput | null,
  submitterEmail?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelSimulationConditionInput | null > | null,
  or?: Array< ModelSimulationConditionInput | null > | null,
  not?: ModelSimulationConditionInput | null,
  projectSimulationsId?: ModelIDInput | null,
  simulationSetSimulationsId?: ModelIDInput | null,
  domainSimulationsId?: ModelIDInput | null,
};

export type UpdateSimulationInput = {
  id: string,
  domainId?: string | null,
  slug?: string | null,
  name?: string | null,
  centerLat?: number | null,
  centerLng?: number | null,
  resolution?: number | null,
  width?: number | null,
  height?: number | null,
  bounds?: string | null,
  area?: number | null,
  gridCellCount?: number | null,
  typicalMeteorologicalYear?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
  wrgHeights?: string | null,
  estimatedPrice?: number | null,
  estimatedPriceBreakdown?: string | null,
  privacy?: Privacy | null,
  projectId?: string | null,
  turbineLocations?: string | null,
  turbineTypes?: string | null,
  turbineCount?: number | null,
  useTurbineLocationsAsMetMasts?: boolean | null,
  metMastLocations?: string | null,
  metMastCount?: number | null,
  status?: SimulationStatus | null,
  statusMessage?: string | null,
  s3Bucket?: string | null,
  s3Prefix?: string | null,
  outputs?: string | null,
  submitterInfo?: string | null,
  submitterEmail?: string | null,
  submittedAt?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  deletedAt?: string | null,
  projectSimulationsId?: string | null,
  simulationSetSimulationsId?: string | null,
  domainSimulationsId?: string | null,
};

export type DeleteSimulationInput = {
  id: string,
};

export type CreateTurbineInput = {
  id?: string | null,
  slug: string,
  description?: string | null,
  status: TurbineTypeStatus,
  privacy: Privacy,
  rotorDiameter: number,
  ratedPower: number,
  powerCurve?: string | null,
  deletedAt?: string | null,
};

export enum TurbineTypeStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}


export type ModelTurbineConditionInput = {
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelTurbineTypeStatusInput | null,
  privacy?: ModelPrivacyInput | null,
  rotorDiameter?: ModelFloatInput | null,
  ratedPower?: ModelFloatInput | null,
  powerCurve?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelTurbineConditionInput | null > | null,
  or?: Array< ModelTurbineConditionInput | null > | null,
  not?: ModelTurbineConditionInput | null,
};

export type ModelTurbineTypeStatusInput = {
  eq?: TurbineTypeStatus | null,
  ne?: TurbineTypeStatus | null,
};

export type Turbine = {
  __typename: "Turbine",
  id: string,
  slug: string,
  description?: string | null,
  status: TurbineTypeStatus,
  privacy: Privacy,
  rotorDiameter: number,
  ratedPower: number,
  powerCurve?: string | null,
  deletedAt?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateTurbineInput = {
  id: string,
  slug?: string | null,
  description?: string | null,
  status?: TurbineTypeStatus | null,
  privacy?: Privacy | null,
  rotorDiameter?: number | null,
  ratedPower?: number | null,
  powerCurve?: string | null,
  deletedAt?: string | null,
};

export type DeleteTurbineInput = {
  id: string,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type ModelSimulationSetFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLng?: ModelFloatInput | null,
  centerLat?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  status?: ModelSimulationSetStatusInput | null,
  and?: Array< ModelSimulationSetFilterInput | null > | null,
  or?: Array< ModelSimulationSetFilterInput | null > | null,
  not?: ModelSimulationSetFilterInput | null,
};

export type ModelSimulationSetConnection = {
  __typename: "ModelSimulationSetConnection",
  items:  Array<SimulationSet | null >,
  nextToken?: string | null,
};

export type ModelDomainFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  centerLng?: ModelFloatInput | null,
  centerLat?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  resolution?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  status?: ModelDomainStatusInput | null,
  and?: Array< ModelDomainFilterInput | null > | null,
  or?: Array< ModelDomainFilterInput | null > | null,
  not?: ModelDomainFilterInput | null,
};

export type ModelDomainConnection = {
  __typename: "ModelDomainConnection",
  items:  Array<Domain | null >,
  nextToken?: string | null,
};

export type ModelTurbineFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelTurbineTypeStatusInput | null,
  privacy?: ModelPrivacyInput | null,
  rotorDiameter?: ModelFloatInput | null,
  ratedPower?: ModelFloatInput | null,
  powerCurve?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelTurbineFilterInput | null > | null,
  or?: Array< ModelTurbineFilterInput | null > | null,
  not?: ModelTurbineFilterInput | null,
};

export type ModelTurbineConnection = {
  __typename: "ModelTurbineConnection",
  items:  Array<Turbine | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  privacy?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionSimulationSetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  centerLng?: ModelSubscriptionFloatInput | null,
  centerLat?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  height?: ModelSubscriptionFloatInput | null,
  resolution?: ModelSubscriptionFloatInput | null,
  privacy?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSimulationSetFilterInput | null > | null,
  or?: Array< ModelSubscriptionSimulationSetFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDomainFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  centerLng?: ModelSubscriptionFloatInput | null,
  centerLat?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  height?: ModelSubscriptionFloatInput | null,
  resolution?: ModelSubscriptionFloatInput | null,
  privacy?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDomainFilterInput | null > | null,
  or?: Array< ModelSubscriptionDomainFilterInput | null > | null,
};

export type ModelSubscriptionSimulationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  domainId?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  centerLat?: ModelSubscriptionFloatInput | null,
  centerLng?: ModelSubscriptionFloatInput | null,
  resolution?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  height?: ModelSubscriptionFloatInput | null,
  bounds?: ModelSubscriptionStringInput | null,
  area?: ModelSubscriptionFloatInput | null,
  gridCellCount?: ModelSubscriptionIntInput | null,
  typicalMeteorologicalYear?: ModelSubscriptionBooleanInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  wrgHeights?: ModelSubscriptionStringInput | null,
  estimatedPrice?: ModelSubscriptionFloatInput | null,
  estimatedPriceBreakdown?: ModelSubscriptionStringInput | null,
  privacy?: ModelSubscriptionStringInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  turbineLocations?: ModelSubscriptionStringInput | null,
  turbineTypes?: ModelSubscriptionStringInput | null,
  turbineCount?: ModelSubscriptionIntInput | null,
  useTurbineLocationsAsMetMasts?: ModelSubscriptionBooleanInput | null,
  metMastLocations?: ModelSubscriptionStringInput | null,
  metMastCount?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  statusMessage?: ModelSubscriptionStringInput | null,
  s3Bucket?: ModelSubscriptionStringInput | null,
  s3Prefix?: ModelSubscriptionStringInput | null,
  outputs?: ModelSubscriptionStringInput | null,
  submitterInfo?: ModelSubscriptionStringInput | null,
  submitterEmail?: ModelSubscriptionStringInput | null,
  submittedAt?: ModelSubscriptionStringInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  completedAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSimulationFilterInput | null > | null,
  or?: Array< ModelSubscriptionSimulationFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTurbineFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  privacy?: ModelSubscriptionStringInput | null,
  rotorDiameter?: ModelSubscriptionFloatInput | null,
  ratedPower?: ModelSubscriptionFloatInput | null,
  powerCurve?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTurbineFilterInput | null > | null,
  or?: Array< ModelSubscriptionTurbineFilterInput | null > | null,
};

export type ListSimulationsWithDomainsQueryVariables = {
  filter?: ModelSimulationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimulationsWithDomainsQuery = {
  listSimulations?:  {
    __typename: "ModelSimulationConnection",
    items:  Array< {
      __typename: "Simulation",
      id: string,
      domainId?: string | null,
      slug: string,
      domain?:  {
        __typename: "Domain",
        id: string,
        slug: string,
        name: string,
      } | null,
      project?:  {
        __typename: "Project",
        id: string,
        slug: string,
        name: string,
        privacy: Privacy,
      } | null,
      name: string,
      centerLat: number,
      centerLng: number,
      resolution: number,
      width: number,
      height: number,
      bounds?: string | null,
      area?: number | null,
      gridCellCount?: number | null,
      typicalMeteorologicalYear?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      wrgHeights?: string | null,
      estimatedPrice?: number | null,
      privacy: Privacy,
      projectId?: string | null,
      turbineLocations?: string | null,
      turbineTypes?: string | null,
      turbineCount?: number | null,
      useTurbineLocationsAsMetMasts?: boolean | null,
      metMastLocations?: string | null,
      metMastCount?: number | null,
      status: SimulationStatus,
      statusMessage?: string | null,
      s3Bucket?: string | null,
      s3Prefix?: string | null,
      outputs?: string | null,
      submitterEmail?: string | null,
      submitterInfo?: string | null,
      submittedAt?: string | null,
      startedAt?: string | null,
      completedAt?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      projectSimulationsId?: string | null,
      domainSimulationsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSimulationSetMutationVariables = {
  input: CreateSimulationSetInput,
  condition?: ModelSimulationSetConditionInput | null,
};

export type CreateSimulationSetMutation = {
  createSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSimulationSetMutationVariables = {
  input: UpdateSimulationSetInput,
  condition?: ModelSimulationSetConditionInput | null,
};

export type UpdateSimulationSetMutation = {
  updateSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSimulationSetMutationVariables = {
  input: DeleteSimulationSetInput,
  condition?: ModelSimulationSetConditionInput | null,
};

export type DeleteSimulationSetMutation = {
  deleteSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDomainMutationVariables = {
  input: CreateDomainInput,
  condition?: ModelDomainConditionInput | null,
};

export type CreateDomainMutation = {
  createDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateDomainMutationVariables = {
  input: UpdateDomainInput,
  condition?: ModelDomainConditionInput | null,
};

export type UpdateDomainMutation = {
  updateDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteDomainMutationVariables = {
  input: DeleteDomainInput,
  condition?: ModelDomainConditionInput | null,
};

export type DeleteDomainMutation = {
  deleteDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSimulationMutationVariables = {
  input: CreateSimulationInput,
  condition?: ModelSimulationConditionInput | null,
};

export type CreateSimulationMutation = {
  createSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateSimulationMutationVariables = {
  input: UpdateSimulationInput,
  condition?: ModelSimulationConditionInput | null,
};

export type UpdateSimulationMutation = {
  updateSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteSimulationMutationVariables = {
  input: DeleteSimulationInput,
  condition?: ModelSimulationConditionInput | null,
};

export type DeleteSimulationMutation = {
  deleteSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateTurbineMutationVariables = {
  input: CreateTurbineInput,
  condition?: ModelTurbineConditionInput | null,
};

export type CreateTurbineMutation = {
  createTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTurbineMutationVariables = {
  input: UpdateTurbineInput,
  condition?: ModelTurbineConditionInput | null,
};

export type UpdateTurbineMutation = {
  updateTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTurbineMutationVariables = {
  input: DeleteTurbineInput,
  condition?: ModelTurbineConditionInput | null,
};

export type DeleteTurbineMutation = {
  deleteTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSimulationSetQueryVariables = {
  id: string,
};

export type GetSimulationSetQuery = {
  getSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSimulationSetsQueryVariables = {
  filter?: ModelSimulationSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimulationSetsQuery = {
  listSimulationSets?:  {
    __typename: "ModelSimulationSetConnection",
    items:  Array< {
      __typename: "SimulationSet",
      id: string,
      slug: string,
      name: string,
      centerLng?: number | null,
      centerLat?: number | null,
      width?: number | null,
      height?: number | null,
      resolution?: number | null,
      privacy: Privacy,
      status: SimulationSetStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDomainQueryVariables = {
  id: string,
};

export type GetDomainQuery = {
  getDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListDomainsQueryVariables = {
  filter?: ModelDomainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDomainsQuery = {
  listDomains?:  {
    __typename: "ModelDomainConnection",
    items:  Array< {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSimulationQueryVariables = {
  id: string,
};

export type GetSimulationQuery = {
  getSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListSimulationsQueryVariables = {
  filter?: ModelSimulationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimulationsQuery = {
  listSimulations?:  {
    __typename: "ModelSimulationConnection",
    items:  Array< {
      __typename: "Simulation",
      id: string,
      domainId?: string | null,
      slug: string,
      name: string,
      centerLat: number,
      centerLng: number,
      resolution: number,
      width: number,
      height: number,
      bounds?: string | null,
      area?: number | null,
      gridCellCount?: number | null,
      typicalMeteorologicalYear?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      wrgHeights?: string | null,
      estimatedPrice?: number | null,
      estimatedPriceBreakdown?: string | null,
      privacy: Privacy,
      projectId?: string | null,
      turbineLocations?: string | null,
      turbineTypes?: string | null,
      turbineCount?: number | null,
      useTurbineLocationsAsMetMasts?: boolean | null,
      metMastLocations?: string | null,
      metMastCount?: number | null,
      status: SimulationStatus,
      statusMessage?: string | null,
      s3Bucket?: string | null,
      s3Prefix?: string | null,
      outputs?: string | null,
      submitterInfo?: string | null,
      submitterEmail?: string | null,
      submittedAt?: string | null,
      startedAt?: string | null,
      completedAt?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      projectSimulationsId?: string | null,
      simulationSetSimulationsId?: string | null,
      domainSimulationsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTurbineQueryVariables = {
  id: string,
};

export type GetTurbineQuery = {
  getTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTurbinesQueryVariables = {
  filter?: ModelTurbineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTurbinesQuery = {
  listTurbines?:  {
    __typename: "ModelTurbineConnection",
    items:  Array< {
      __typename: "Turbine",
      id: string,
      slug: string,
      description?: string | null,
      status: TurbineTypeStatus,
      privacy: Privacy,
      rotorDiameter: number,
      ratedPower: number,
      powerCurve?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SimulationSetsBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSimulationSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SimulationSetsBySlugQuery = {
  simulationSetsBySlug?:  {
    __typename: "ModelSimulationSetConnection",
    items:  Array< {
      __typename: "SimulationSet",
      id: string,
      slug: string,
      name: string,
      centerLng?: number | null,
      centerLat?: number | null,
      width?: number | null,
      height?: number | null,
      resolution?: number | null,
      privacy: Privacy,
      status: SimulationSetStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DomainsBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDomainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DomainsBySlugQuery = {
  domainsBySlug?:  {
    __typename: "ModelDomainConnection",
    items:  Array< {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SimulationsBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSimulationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SimulationsBySlugQuery = {
  simulationsBySlug?:  {
    __typename: "ModelSimulationConnection",
    items:  Array< {
      __typename: "Simulation",
      id: string,
      domainId?: string | null,
      slug: string,
      name: string,
      centerLat: number,
      centerLng: number,
      resolution: number,
      width: number,
      height: number,
      bounds?: string | null,
      area?: number | null,
      gridCellCount?: number | null,
      typicalMeteorologicalYear?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      wrgHeights?: string | null,
      estimatedPrice?: number | null,
      estimatedPriceBreakdown?: string | null,
      privacy: Privacy,
      projectId?: string | null,
      turbineLocations?: string | null,
      turbineTypes?: string | null,
      turbineCount?: number | null,
      useTurbineLocationsAsMetMasts?: boolean | null,
      metMastLocations?: string | null,
      metMastCount?: number | null,
      status: SimulationStatus,
      statusMessage?: string | null,
      s3Bucket?: string | null,
      s3Prefix?: string | null,
      outputs?: string | null,
      submitterInfo?: string | null,
      submitterEmail?: string | null,
      submittedAt?: string | null,
      startedAt?: string | null,
      completedAt?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      projectSimulationsId?: string | null,
      simulationSetSimulationsId?: string | null,
      domainSimulationsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    slug: string,
    name: string,
    privacy: Privacy,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSimulationSetSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationSetFilterInput | null,
  owner?: string | null,
};

export type OnCreateSimulationSetSubscription = {
  onCreateSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSimulationSetSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationSetFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSimulationSetSubscription = {
  onUpdateSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSimulationSetSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationSetFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSimulationSetSubscription = {
  onDeleteSimulationSet?:  {
    __typename: "SimulationSet",
    id: string,
    slug: string,
    name: string,
    centerLng?: number | null,
    centerLat?: number | null,
    width?: number | null,
    height?: number | null,
    resolution?: number | null,
    privacy: Privacy,
    status: SimulationSetStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDomainSubscriptionVariables = {
  filter?: ModelSubscriptionDomainFilterInput | null,
  owner?: string | null,
};

export type OnCreateDomainSubscription = {
  onCreateDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateDomainSubscriptionVariables = {
  filter?: ModelSubscriptionDomainFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDomainSubscription = {
  onUpdateDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteDomainSubscriptionVariables = {
  filter?: ModelSubscriptionDomainFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDomainSubscription = {
  onDeleteDomain?:  {
    __typename: "Domain",
    id: string,
    slug: string,
    name: string,
    centerLng: number,
    centerLat: number,
    width: number,
    height: number,
    resolution: number,
    privacy: Privacy,
    status: DomainStatus,
    simulations?:  {
      __typename: "ModelSimulationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSimulationSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationFilterInput | null,
  owner?: string | null,
};

export type OnCreateSimulationSubscription = {
  onCreateSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateSimulationSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSimulationSubscription = {
  onUpdateSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteSimulationSubscriptionVariables = {
  filter?: ModelSubscriptionSimulationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSimulationSubscription = {
  onDeleteSimulation?:  {
    __typename: "Simulation",
    id: string,
    domainId?: string | null,
    domain?:  {
      __typename: "Domain",
      id: string,
      slug: string,
      name: string,
      centerLng: number,
      centerLat: number,
      width: number,
      height: number,
      resolution: number,
      privacy: Privacy,
      status: DomainStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    slug: string,
    name: string,
    centerLat: number,
    centerLng: number,
    resolution: number,
    width: number,
    height: number,
    bounds?: string | null,
    area?: number | null,
    gridCellCount?: number | null,
    typicalMeteorologicalYear?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    wrgHeights?: string | null,
    estimatedPrice?: number | null,
    estimatedPriceBreakdown?: string | null,
    privacy: Privacy,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      slug: string,
      name: string,
      privacy: Privacy,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    turbineLocations?: string | null,
    turbineTypes?: string | null,
    turbineCount?: number | null,
    useTurbineLocationsAsMetMasts?: boolean | null,
    metMastLocations?: string | null,
    metMastCount?: number | null,
    status: SimulationStatus,
    statusMessage?: string | null,
    s3Bucket?: string | null,
    s3Prefix?: string | null,
    outputs?: string | null,
    submitterInfo?: string | null,
    submitterEmail?: string | null,
    submittedAt?: string | null,
    startedAt?: string | null,
    completedAt?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    projectSimulationsId?: string | null,
    simulationSetSimulationsId?: string | null,
    domainSimulationsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateTurbineSubscriptionVariables = {
  filter?: ModelSubscriptionTurbineFilterInput | null,
  owner?: string | null,
};

export type OnCreateTurbineSubscription = {
  onCreateTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTurbineSubscriptionVariables = {
  filter?: ModelSubscriptionTurbineFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTurbineSubscription = {
  onUpdateTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTurbineSubscriptionVariables = {
  filter?: ModelSubscriptionTurbineFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTurbineSubscription = {
  onDeleteTurbine?:  {
    __typename: "Turbine",
    id: string,
    slug: string,
    description?: string | null,
    status: TurbineTypeStatus,
    privacy: Privacy,
    rotorDiameter: number,
    ratedPower: number,
    powerCurve?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
