import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LibraryLoading from "../LibraryLoading";
import useTurbineStore from "../Turbines/turbineStore";
import sortTurbinesByRatedPower from "../Turbines/sortTurbinesByRatedPower";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function TurbineOptionsModal(props: Props) {
  const { isOpen, onClose } = props;
  const [loading, setLoading] = useState(true);
  // let turbines = useTurbineStore((state) => state.turbines);

  const fetch = useTurbineStore((state) => state.fetch);
  const { turbines } = useTurbineStore();

  useEffect(() => {
    async function fetchTurbines() {
      try {
        setLoading(true);
        await fetch();
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    if (!turbines || turbines.length === 0) {
      fetchTurbines();
    }
  }, [turbines]);

  const rows = sortTurbinesByRatedPower(turbines).map((turbine) => {
    return (
      <Tr key={turbine.id}>
        <Td>{turbine.slug}</Td>
        <Td textAlign="right">{turbine.rotorDiameter} m</Td>
        <Td textAlign="right">{turbine.ratedPower} kW</Td>
      </Tr>
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Available Turbine Types</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <LibraryLoading />}

          {!loading && (
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th textAlign="right">
                      Rotor
                      <br />
                      Diameter
                    </Th>
                    <Th textAlign="right">
                      Rated
                      <br />
                      Power
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{rows}</Tbody>
              </Table>
            </TableContainer>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TurbineOptionsModal;
