import { API } from "aws-amplify";

interface Params {
  simulationSlug: string;
}

export interface SimulationOutputsData {
  simulationSlug: string;
  notes?: string[];
  downloads: {
    url: string;
    name: string;
    path: string;
    downloadType: string;
  }[];
}

const era5GlobalOutputs: SimulationOutputsData = {
  simulationSlug: "sim_era5_global",
  downloads: [],
  notes: [
    "Native 0.25 degree resolution in ERA5 has been replaced by triangular grid boxes in this interface. This allows consistent grid sizes across the globe. At each of these triangular grid points, the underlying data corresponds to the nearest native ERA5 grid cell, i.e., no interpolation is performed.",
  ],
};

const getSimulationOutputs = async (
  params: Params
): Promise<SimulationOutputsData> => {
  console.debug("getSimulationOutputs api call", JSON.stringify(params));
  const apiName = "getCellData"; // as provided in 'amplify add api'

  const simulationSlug = params.simulationSlug;

  const path = `/outputs/${simulationSlug}`; // or other route you specified

  if (simulationSlug === "sim_era5_global") {
    return era5GlobalOutputs;
  }

  try {
    const result = await API.get(apiName, path, {
      response: true,
      responseType: "json",
    });
    return result.data.data as SimulationOutputsData;
  } catch (error) {
    console.error("Error in getSimulationOutputs", error);

    return {
      simulationSlug,
      downloads: [],
    };
  }
};

export default getSimulationOutputs;
