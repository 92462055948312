import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

interface DismissableAlertProps {
  title: string;
  message: string;
  onClose?: () => void;
}

const DismissableAlert = (props: DismissableAlertProps) => {
  const { title, message } = props;
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [isDismissed, setIsDismissed] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    onClose();
    setIsDismissed(true);
    if (props.onClose) {
      props.onClose();
    }
  };

  if (isDismissed) {
    return null;
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={handleClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DismissableAlert;
