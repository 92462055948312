import { Turbine } from "../../API";

export default function (turbines: Turbine[], search?: string): Turbine[] {
  return turbines
    .filter((turbine: Turbine) => {
      if (!search || search.length < 1) return true;

      const kw = search.toLowerCase();
      return (
        (turbine.slug && turbine.slug.toLowerCase().includes(kw)) ||
        (turbine.description && turbine.description.toLowerCase().includes(kw))
      );
    })
    .sort((a, b) => {
      return a.slug.localeCompare(b.slug);
    });
}
