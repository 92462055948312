import { FiDatabase } from "react-icons/fi";
import { Icon, Link, useDisclosure } from "@chakra-ui/react";
import TurbineOptionsModal from "./TurbineOptionsModal";

const ShowTurbineOptions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <TurbineOptionsModal isOpen={isOpen} onClose={onClose} />

      <Link
        color="blue.200"
        display={"inline-block"}
        mx={4}
        fontSize="xs"
        textTransform={"uppercase"}
        onClick={onOpen}
      >
        <Icon as={FiDatabase} mr={1} />
        Options
      </Link>
    </>
  );
};

export default ShowTurbineOptions;
