import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MetMastLocations } from "../MetMastUploader/helpers";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  metMastLocations: MetMastLocations | undefined;
  rowLimit: number;
}

export function MetMastsModal(props: Props) {
  const { isOpen, onClose, metMastLocations, rowLimit } = props;

  if (!metMastLocations) return null;
  const rows = metMastLocations.slice(0, rowLimit).map((row, i) => {
    return (
      <Tr key={i}>
        <Td fontSize="xs">{row[0]}</Td>
        <Td fontSize="xs" isNumeric>
          {row[1]}
        </Td>
        <Td fontSize="xs" isNumeric>
          {row[2]}
        </Td>
      </Tr>
    );
  });

  const placeholder =
    metMastLocations.length > rowLimit ? (
      <Tr key="placeholder">
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
        <Td fontSize="xs" isNumeric>
          &hellip;
        </Td>
      </Tr>
    ) : null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Virtual Met Masts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer mb={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th w={"2rem"} verticalAlign="bottom">
                    ID
                  </Th>
                  <Th verticalAlign="bottom" isNumeric>
                    Lat
                  </Th>
                  <Th verticalAlign="bottom" isNumeric>
                    Lng
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {rows}
                {placeholder}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MetMastsModal;
