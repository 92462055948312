import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Link,
  Text,
} from "@chakra-ui/react";
import useSimulationStore from "../Simulations/simulationStore";
import useMapStore from "../Map/mapStore";

export default function OutOfBoundsWarning() {
  const { outOfBoundsCount, regenerateBounds } = useSimulationStore();
  const { repositionMap } = useMapStore();

  const regenerateBoundsAndRepositionMap = () => {
    regenerateBounds();
    repositionMap();
  };

  if (outOfBoundsCount <= 0) {
    return null;
  }
  return (
    <Alert status="warning">
      <AlertIcon />
      <Box fontSize="sm">
        <AlertDescription>
          {outOfBoundsCount === 1 ? (
            <Text>1 feature is out of bounds.</Text>
          ) : (
            <Text>{outOfBoundsCount} features are out of bounds.</Text>
          )}

          <Text mt={2}>
            Do you want us to{" "}
            <Link
              onClick={regenerateBoundsAndRepositionMap}
              textDecoration={"underline"}
            >
              recalculate the bounds to include all the features?
            </Link>
          </Text>
        </AlertDescription>
      </Box>
    </Alert>
  );
}
