import { Badge } from "@chakra-ui/react";
import { Privacy } from "../API";

export default function PrivacyBadge(props: { privacy: Privacy }) {
  if (props.privacy === Privacy.PRIVATE) return;

  return (
    <Badge colorScheme={"pink"} variant="outline">
      Demo
    </Badge>
  );
}
