export const MAX_X_EXTENT = 40000;
export const MAX_Y_EXTENT = 40000;

import { AVAILABLE_RESOLUTIONS } from "../constants";
const RESOLUTION_LABELS: Record<string, string> = {
  "700": "700 m",
  "800": "800 m",
  "900": "900 m",
  "1000": "1 km",
  "1200": "1.2 km",
  "1400": "1.4 km",
  "1600": "1.6 km",
  "1800": "1.8 km",
  "2000": "2 km",
  "3000": "3 km",
  "4000": "4 km",
  "5000": "5 km",
  "6000": "6 km",
};

const MIN_CELLS = 500;
const OPTIMIUM_CELLS = 2000;

export function generateResolutionOptions(widthKm: number, heightKm: number) {
  let minDistanceFromOptimum = Infinity;
  let suggestedIndex = 0;

  const width = widthKm * 1000;
  const height = heightKm * 1000;

  // Calculate the number of cells for each resolution
  const resolutions = AVAILABLE_RESOLUTIONS.map((res, index) => {
    const cellCount = (width * height) / res ** 2; // cell count at this resolution

    const disabled = cellCount < MIN_CELLS;
    let label = RESOLUTION_LABELS[res.toString()] || `${res} km`;

    const distance = Math.abs(cellCount - OPTIMIUM_CELLS);
    if (distance < minDistanceFromOptimum) {
      minDistanceFromOptimum = distance;
      suggestedIndex = index;
    }
    return {
      value: res,
      label: label,
      disabled,
      cellCount,
      suggested: false,
      distanceFromOptimum: distance,
    };
  });

  // if all resolutions are disabled, enable the first one
  if (resolutions.every((res) => res.disabled)) {
    resolutions[0].disabled = false;
    suggestedIndex = 0;
  }

  // add " (Disabled)" to the label of disabled resolutions
  resolutions.forEach((res, index) => {
    if (res.disabled) {
      resolutions[index].label += " (Disabled)";
    }
  });

  // Set the suggested resolution
  resolutions[suggestedIndex].label += " (Suggested)";
  resolutions[suggestedIndex].suggested = true;

  return resolutions;
}
