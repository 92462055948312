import { Box } from "@chakra-ui/react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useMemo, useRef, useState } from "react";
import useSimulationStore from "../Simulations/simulationStore";
import { TurbineLegend } from "../TurbineLegend";
import { roundCoordinate } from "../util";
import { RenderCoordinatesControl } from "./RenderCoordinatesControl";
import { addBoundingBox, removeBoundingBox } from "./lib/addBoundingBox";
import { addMetMasts, removeMetMasts } from "./lib/addMetMasts";
import {
  TURBINE_COLORS,
  addSimulationTurbines,
  removeSimulationPreviewTurbines,
} from "./lib/addSimulationTurbines";
import convertBoundsToExtents from "./lib/convertBoundsToExtents";
import getInitialCoordinates from "./lib/getInitialCoordinates";
import useWindMapSource from "./useWindMapSource";
import useRegionBoundaries from "./useRegionBoundaries";
import useMapStore from "./mapStore";
import shouldRenderBoundingBox from "./lib/shouldRenderBoundingBox";

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_PUBLIC_TOKEN;

let turbineControlRef: any;

function setURL(zoom: number, lng: number, lat: number) {
  document.location.hash = [
    zoom.toFixed(2),
    roundCoordinate(lng),
    roundCoordinate(lat),
  ].join("/");
}

export default function Map() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const lastMapReposition = useRef<number>(0);
  const { mapRef, setMap, repositionMapTs } = useMapStore();
  const map = mapRef;

  const {
    turbineGeoJSON,
    metMastGeoJSON,
    geography,
    currentSimulation,
    setSimulationCoordinates,
    windMapSource,
    windMapSourceLayers,
    editingSimulation,
    lockedCoordinates,
    turbineTypes,
  } = useSimulationStore();

  const [loaded, setLoaded] = useState(false);
  const initialMapRender = useRef(true);
  const simulationBounds = geography?.bounds;
  const allowEditingBounds = editingSimulation && !lockedCoordinates;

  const turbineLegendPalette = useMemo(() => {
    if (!turbineTypes) return {};
    const palette: Record<string, string> = {};
    turbineTypes.forEach((t, i) => {
      const turbineColor = TURBINE_COLORS[i % TURBINE_COLORS.length];
      palette[t.slug] = turbineColor;
    });

    return palette;
  }, [turbineTypes]);
  useEffect(() => {
    if (map.current) return;
    if (!mapContainer.current) return;

    const { lng, lat, zoom } = getInitialCoordinates();

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/optism/clnusdci0000101r8ccfw7zbo",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 1,
      maxZoom: 11,
      boxZoom: false,
    });

    // TODO: figure this out in relation to the sidebar and Chakra units
    // https://docs.mapbox.com/mapbox-gl-js/example/offset-vanishing-point-with-padding/
    map.current.jumpTo({
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 400,
      },
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      zoom: 4,
      trackProximity: false,
      placeholder: "Location",
      marker: false,
    });

    map.current.addControl(geocoder, "bottom-right");
    map.current.addControl(new RenderCoordinatesControl(), "bottom-right");
    map.current.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    setMap(map.current);

    return () => {
      console.debug("Unloading map");
      setMap(null);
      map.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("load", () => {
      setLoaded(true);
    });

    map.current.on("moveend", () => {
      const center = map.current!.getCenter();
      setURL(map.current!.getZoom(), center.lng, center.lat);
    });
  }, []);

  useEffect(() => {
    initialMapRender.current = true;
  }, [currentSimulation, turbineGeoJSON, metMastGeoJSON]);

  useEffect(() => {
    if (!map.current) return;
    if (!loaded) return;

    if (turbineControlRef) {
      map.current.removeControl(turbineControlRef);
    }
    if (turbineGeoJSON && turbineGeoJSON.features.length > 0) {
      addSimulationTurbines(map.current, turbineGeoJSON);
      turbineControlRef = new TurbineLegend(turbineLegendPalette);
      map.current.addControl(turbineControlRef, "top-right");
    } else {
      removeSimulationPreviewTurbines(map.current);
    }

    // return () => {
    //   removeSimulationPreviewTurbines(map.current);
    //   if (turbineControlRef) {
    //     map.current?.removeControl(turbineControlRef);
    //   }
    // };
  }, [map, loaded, turbineGeoJSON]);

  useEffect(() => {
    if (!map.current) return;
    if (!loaded) return;

    if (metMastGeoJSON && metMastGeoJSON.features.length > 0) {
      addMetMasts(map.current, metMastGeoJSON);
    } else {
      removeMetMasts(map.current);
    }

    // return () => {
    //   removeMetMasts(map.current);
    // };
  }, [map, loaded, metMastGeoJSON]);

  // when the user adjusts the bounds of the simulation we only want the center+extent data
  // and not the bounds so the we always have consistent geography
  // so we convert the LngLatBounds to center+extents and re-derive the bounds from that
  const onBoundsChange = (bounds: mapboxgl.LngLatBounds) => {
    const extents = convertBoundsToExtents(bounds);
    setSimulationCoordinates(
      extents.centerLng,
      extents.centerLat,
      extents.width,
      extents.height
    );
  };

  useEffect(() => {
    if (!map.current) return;
    if (!loaded) return;

    if (simulationBounds) {
      // for some special-case simulations like era5 we do not have a bounding box
      if (shouldRenderBoundingBox(geography)) {
        addBoundingBox(
          map.current,
          simulationBounds,
          initialMapRender.current,
          allowEditingBounds ? onBoundsChange : undefined
        );

        if (lastMapReposition.current < repositionMapTs) {
          lastMapReposition.current = repositionMapTs;
          initialMapRender.current = true;
        }

        if (initialMapRender.current) {
          lastMapReposition.current = repositionMapTs;
          try {
            map.current.fitBounds(simulationBounds, {
              padding: 40,
              animate: false,
              maxZoom: 11,
            });
          } catch (e) {
            console.warn(e);
          }
        }

        initialMapRender.current = false;
      }
    } else {
      removeBoundingBox(map.current);
    }

    // return () => {
    //   removeBoundingBox(map.current);
    // };
  }, [
    map,
    loaded,
    turbineGeoJSON,
    metMastGeoJSON,
    simulationBounds,
    allowEditingBounds,
    repositionMapTs,
  ]);

  useWindMapSource(map, loaded, windMapSource, windMapSourceLayers);
  useRegionBoundaries(map, loaded, allowEditingBounds);

  return (
    <Box
      id="map"
      ref={mapContainer}
      m={0}
      position="relative"
      zIndex={1}
      height={"100%"}
    />
  );
}
