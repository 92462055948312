import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Simulation } from "../../API";
import calculatePrice, {
  SimulationPriceResult,
} from "../../endpoints/calculatePrice";
import useSimulationStore from "./simulationStore";
import { SimulationFormValues } from "./types";

interface Props
  extends Pick<Simulation, "gridCellCount" | "startDate" | "endDate"> {
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<SimulationFormValues>;
}

function formatPrice(cents: number) {
  return `$${(cents / 100).toFixed(2)}`;
}

const SimulationPrice: React.FC<Props> = (props) => {
  const { gridCellCount, watch } = props;

  const { metMastLocations, turbineLocations, turbineTypes } =
    useSimulationStore();
  const [fetching, setFetching] = useState<boolean>(false);
  const [price, setPrice] = useState<SimulationPriceResult | undefined>(
    undefined
  );
  const turbineCount = (turbineLocations || []).length;
  const metMastCount = (metMastLocations || []).length;
  const turbinePowerRatings =
    turbineTypes?.map((t) => {
      return { ratedPower: t.ratedPower };
    }) || [];

  const resolution = parseInt(watch("resolution") || "0");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const typicalMeteorologicalYear =
    watch("typicalMeteorologicalYear") == "true" ? true : false;
  const wrgHeights = watch("wrgHeights");
  const useTurbineLocationsAsMetMasts = watch("useTurbineLocationsAsMetMasts");

  useEffect(() => {
    const fetchPrice = async () => {
      if (fetching) return;
      setFetching(true);

      const result = await calculatePrice({
        endDate,
        gridCellCount,
        metMastCount,
        resolution,
        startDate,
        turbineCount,
        turbineTypes: turbinePowerRatings,
        typicalMeteorologicalYear,
        useTurbineLocationsAsMetMasts,
        wrgHeights,
      }).catch(console.error);

      if (!result) {
        setPrice(undefined);
        setFetching(false);
        return;
      }

      setPrice(result);
      setFetching(false);
    };

    fetchPrice().catch(console.error);
  }, [
    endDate,
    gridCellCount,
    metMastCount,
    resolution,
    startDate,
    turbineCount,
    turbineTypes,
    typicalMeteorologicalYear,
    useTurbineLocationsAsMetMasts,
    wrgHeights,
  ]);

  useEffect(() => {
    props.setValue(
      "estimatedPrice",
      price && price.totalCostCents ? price.totalCostCents / 100.0 : null
    );
    props.setValue("estimatedPriceBreakdown", JSON.stringify(price) || null);
  }, [price]);

  if (!price) return null;

  if (fetching) {
    return (
      <Box mt={4} minHeight={10} background={"teal.900"} borderRadius={6} p={4}>
        <Spinner size="sm" color="white" thickness="2px" speed="1s" />
      </Box>
    );
  }

  return (
    <Box
      minHeight="6.5rem"
      background={"teal.200"}
      color={"blackAlpha.800"}
      borderRadius={6}
      p={4}
      fontSize="sm"
    >
      <Text as="b">
        Estimated Price: {formatPrice(price.totalCostCents)} {price.currency}
      </Text>

      <Box fontSize="xs">
        <Text>Simulation: {formatPrice(price.simulationCostCents)}</Text>
        <Text>
          Timeseries Extraction: {formatPrice(price.timeseriesCostCents)}
        </Text>
        <Text>WRG Extraction: {formatPrice(price.wrgCostCents)}</Text>
      </Box>
    </Box>
  );
};

export default SimulationPrice;
