// @ts-expect-error
import distance from "@turf/distance";
import mapboxgl from "mapbox-gl";

type Extents = {
  centerLng: number;
  centerLat: number;
  width: number;
  height: number;
};

export function extendBounds(
  bounds: mapboxgl.LngLatBounds,
  additionalPoints: [[any, number, number]]
) {
  additionalPoints.forEach((point) => {
    bounds.extend([point[2], point[1]]);
  });

  return bounds;
}

export default function convertBoundsToExtents(
  bounds: mapboxgl.LngLatBounds
): Extents {
  const center = bounds.getCenter();
  const centerLng = center.lng;
  const centerLat = center.lat;
  const west = bounds.getWest();
  const east = bounds.getEast();
  const north = bounds.getNorth();
  const south = bounds.getSouth();

  const width = distance([west, centerLat], [east, centerLat], {
    units: "kilometers",
  });
  const height = distance([centerLng, north], [centerLng, south], {
    units: "kilometers",
  });

  return { centerLng, centerLat, width, height };
}
