import { Privacy, Simulation, SimulationStatus } from "../API";
import domains from "./domains";

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const oneMonthAgo = new Date();
oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

const domain = domains.era5_global;

const simulation: Simulation = {
  id: "sim_era5_global",
  slug: "sim_era5_global",
  name: "ERA5",
  centerLat: 0,
  centerLng: 0,
  width: 0,
  height: 0,
  resolution: 23000,
  gridCellCount: Math.round(510000000 / (20 * 20)),
  bounds: undefined,
  privacy: Privacy.PUBLIC,
  domainId: domain.id,
  domain: domain,
  turbineLocations: "[]",
  turbineTypes: "[]",
  turbineCount: 0,
  wrgHeights: JSON.stringify([]),
  outputs: JSON.stringify({
    // wind_map_source: {
    //   id: "era5_global",
    //   type: "vector",
    //   url: "mapbox://optism.era5_global",
    //   color_stops: [5, 20],
    // },
    wind_map_source: {
      id: "era5_triangular",
      type: "vector",
      url: "mapbox://optism.era5_triangular",
      color_stops: [3, 14],
      fillColor: {
        property: "wsaf",
        stops: [
          [3, "#00ff00"],
          [10, "#990099"],
          [14, "#ff00ff"],
        ],
      },
      fillOpacity: [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        0.9,
        ["boolean", ["feature-state", "hover"], false],
        0.9,
        ["interpolate", ["linear"], ["get", "wsaf"], 3, 0.2, 9, 0.8],
      ],
    },
    wind_map_source_layers: [
      {
        source: "era5_triangular",
        sourceLayer: "era5_res5",
        minzoom: 0,
        maxzoom: 3,
        interactive: false,
        hasDownloads: false,
        lores: true,
      },
      {
        source: "era5_triangular",
        sourceLayer: "era5_res6",
        minzoom: 3,
        maxzoom: 4,
        interactive: false,
        hasDownloads: false,
        lores: false,
      },
      {
        source: "era5_triangular",
        sourceLayer: "era5_res7",
        minzoom: 4,
        maxzoom: 5,
        interactive: false,
        hasDownloads: false,
        lores: false,
      },
      {
        source: "era5_triangular",
        sourceLayer: "era5_res8",
        minzoom: 5,
        interactive: true,
        hasDownloads: true,
        lores: false,
      },

      // {
      //   source: "era5_global",
      //   sourceLayer: "era5_global_025_degree",
      //   minzoom: 4,
      //   maxzoom: 11,
      //   interactive: true,
      //   lores: false,
      // },
      // {
      //   source: "era5_global",
      //   sourceLayer: "era5_global_050_degree",
      //   minzoom: 3,
      //   maxzoom: 5,
      //   interactive: false,
      //   lores: true,
      // },
      // {
      //   source: "era5_global",
      //   sourceLayer: "era5_global_100_degree",
      //   minzoom: 0,
      //   maxzoom: 3,
      //   interactive: true,
      //   lores: false,
      // },
    ],
  }),
  typicalMeteorologicalYear: false,
  startDate: "2000-01-01",
  endDate: oneMonthAgo.toISOString(),
  status: SimulationStatus.SUCCEEDED,
  submittedAt: oneWeekAgo.toISOString(),
  startedAt: oneWeekAgo.toISOString(),
  completedAt: oneWeekAgo.toISOString(),
  deletedAt: null,
  createdAt: oneWeekAgo.toISOString(),
  updatedAt: oneWeekAgo.toISOString(),
  projectSimulationsId: null,
  __typename: "Simulation",
};
export default simulation;
