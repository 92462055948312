import React, { useState } from "react";
import { SourceLayer, WindMapSource } from "../components/Map/lib/types";

interface MapContextProps {
  windMapSource: WindMapSource | null;
  setWindMapSource: (source: WindMapSource | null) => void;

  windMapSourceLayers: SourceLayer[] | null;
  setWindMapSourceLayers: (layers: SourceLayer[] | null) => void;
}

const MapContext = React.createContext<MapContextProps>({} as MapContextProps);

const MapProvider = (props: any) => {
  const [windMapSource, setWindMapSource] = useState<WindMapSource | null>(
    null
  );

  const [windMapSourceLayers, setWindMapSourceLayers] = useState<
    SourceLayer[] | null
  >(null);

  return (
    <MapContext.Provider
      value={{
        windMapSourceLayers,
        setWindMapSourceLayers,
        windMapSource,
        setWindMapSource,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

export { MapContext, MapProvider };
