import { Box, BoxProps } from "@chakra-ui/react";
type Props = {
  children?: React.ReactNode;
  w?: BoxProps["w"];
  maxW?: BoxProps["maxW"];
  minW?: BoxProps["minW"];
};

function SidebarLeft(props: Props) {
  const bgColor = "blackAlpha.900";
  const textColor = "white";

  return (
    <Box
      className="sidebar-left"
      bg={bgColor}
      color={textColor}
      w={{ base: "100%", md: "25rem" }}
      maxW={{ sm: "25rem" }}
      h={{ base: "100%" }}
      maxH="100%"
      marginRight={{ base: "1rem" }}
      pointerEvents={"auto"}
      zIndex={10}
      overflowX={"auto"}
      {...props}
    >
      <Box>{props.children}</Box>
    </Box>
  );
}

export default SidebarLeft;
