import { Box, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { isOutsideAvailableRegions } from "../Map/useRegionBoundaries";
import useSimulationStore from "../Simulations/simulationStore";

export default function OutOfAvailableRegionsWarning() {
  const { geography } = useSimulationStore();
  const [outOfBounds, setOutOfBounds] = useState(false);
  const bounds = geography?.bounds;

  useEffect(() => {
    if (!bounds) return;
    setOutOfBounds(isOutsideAvailableRegions(bounds));
  }, [bounds]);

  if (!outOfBounds) return;

  return (
    <Box
      minHeight={10}
      background={"red.800"}
      color={"white"}
      borderRadius={6}
      p={4}
      fontSize="sm"
    >
      <Text>
        Your spatial domain is outside the bounds of available regions.
      </Text>

      <Text mt={2}>
        If you need simulation runs like this, please email us at{" "}
        <Link href={"mailto:wakemap@veer.eco"} textDecoration={"underline"}>
          wakemap@veer.eco
        </Link>
        .
      </Text>
    </Box>
  );
}
