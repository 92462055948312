import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import anemometer from "../../../assets/anemometer.png";
import { removeMapLayer, removeMapSource } from "./removeMapElements";

const IMAGE_ID = "custom-marker";
const SOURCE_ID = "metmast-source";
const LAYER_ID = "metmast-layer";

export function addMetMasts(map: mapboxgl.Map, geojson: any) {
  if (!map) return;

  map.loadImage(anemometer, (error, image) => {
    if (error) throw error;
    if (!image) throw new Error("image is undefined");

    if (!map.hasImage(IMAGE_ID)) {
      map.addImage(IMAGE_ID, image, { sdf: true });
    }

    const source = map.getSource(SOURCE_ID) as GeoJSONSource;
    if (source) {
      source.setData(geojson);
    } else {
      map.addSource(SOURCE_ID, { type: "geojson", data: geojson });
    }

    if (geojson.features.length === 0) {
      return;
    }

    const layer = map.getLayer(LAYER_ID);
    if (!layer) {
      map.addLayer({
        id: LAYER_ID,
        type: "symbol",
        source: SOURCE_ID,
        paint: {
          "icon-opacity": ["step", ["zoom"], 0, 2.8, 1],
          "icon-color": "#C53030",
        },
        layout: {
          "icon-size": {
            stops: [
              [0, 0],
              [2.8, 0.1],
              [8, 0.35],
            ],
          },
          "icon-image": IMAGE_ID,
          "icon-allow-overlap": true,
        },
      });
    }
  });
}

export function removeMetMasts(map: mapboxgl.Map | null) {
  if (!map) return;
  removeMapLayer(map, LAYER_ID);
  removeMapSource(map, SOURCE_ID);
}
