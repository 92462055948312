import { DeleteIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { DomainStatus } from "../../API";
import useDomainStore from "../Simulations/domainStore";

interface Props {
  isOpen: boolean;
  onClose: (domainId: string | null) => void;
}

export default function ManageDomains(props: Props) {
  const { domains } = useDomainStore();

  // const { domains, deleteProject, saveProject } = useProjectStore();
  const { isOpen } = props;

  const onClose = (domainId?: string) => {
    props.onClose(domainId || null);
  };

  const domainList = domains.map((domain) => {
    return (
      <ListItem key={domain.id} role="group">
        <Box
          onClick={() => onClose(domain.id)}
          cursor={"pointer"}
          display="block"
          _groupHover={{ background: "gray.800" }}
          rounded={"md"}
          p={4}
        >
          <Flex direction="row" justifyContent="space-between">
            <Box flex="1">
              <Text fontSize="md">
                {domain.name}{" "}
                {domain.status === DomainStatus.LOCKED && (
                  <Badge variant={"outline"} ml={2}>
                    Locked
                  </Badge>
                )}
              </Text>
              <Text fontSize={"sm"} color={"gray.400"}>
                {domain.centerLat}, {domain.centerLng}
              </Text>

              <Text fontSize={"sm"} color={"gray.400"}>
                {domain.width}km x {domain.height}km @ {domain.resolution}m
              </Text>
            </Box>

            <Box>
              <IconButton
                aria-label="Delete domain"
                icon={<DeleteIcon />}
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                  alert("not implemented yet");
                }}
              />
            </Box>
          </Flex>
        </Box>
      </ListItem>
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Saved Domains</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List mt={8} spacing={4}>
            {domainList}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
