import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  Select,
  SelectProps,
  CheckboxProps,
} from "@chakra-ui/react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";

interface WrapperProps {
  name: string;
  label?: string;
  error?: FieldError;
  helperText?: string;
  helperPrompt?: any;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
}

interface FormTextInputProps extends WrapperProps, Omit<InputProps, "name"> {}

const FormTextInput = (props: FormTextInputProps) => {
  const { name } = props;
  const {
    label,
    error,
    helperText,
    helperPrompt,
    register,
    registerOptions,
    ...inputProps
  } = props;
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel htmlFor={props.id || name}>
          {label} {helperPrompt}
        </FormLabel>
      )}
      <Input
        className="text-input"
        {...register(name, registerOptions)}
        {...inputProps}
      />

      {helperText && helperText.length > 0 && (
        <FormHelperText>{helperText}</FormHelperText>
      )}

      {error && (
        <FormErrorMessage className="error">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

const FormTextInputWithUnit = (
  props: FormTextInputProps & { unitLabel: string }
) => {
  const { name } = props;
  const {
    label,
    error,
    helperText,
    helperPrompt,
    unitLabel,
    register,
    registerOptions,
    ...inputProps
  } = props;
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel htmlFor={props.id || props.name}>
          {label} {helperPrompt}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          className="text-input"
          {...register(name, registerOptions)}
          {...inputProps}
        />
        <InputRightAddon children={props.unitLabel} />
      </InputGroup>
      {helperText && helperText.length > 0 ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}

      {error ? (
        <FormErrorMessage className="error">{error.message}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

interface FormSelectProps extends WrapperProps, Omit<SelectProps, "name"> {
  options: {
    value: string | number | boolean;
    label: string;
    disabled?: boolean;
  }[];
}

const FormSelect = (props: FormSelectProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const { name } = props;
  const {
    label,
    error,
    helperText,
    helperPrompt,
    options,
    register,
    registerOptions,
    ...inputProps
  } = props;

  const selectOptions = options.map((option) => (
    <option
      key={option.value.toString()}
      value={option.value.toString()}
      disabled={option.disabled}
    >
      {option.label}
    </option>
  ));

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel htmlFor={props.id || props.name}>
          {label} {helperPrompt}
        </FormLabel>
      )}
      <Select
        className="text-input"
        {...inputProps}
        {...register(name, registerOptions)}
      >
        {selectOptions}
      </Select>
      {helperText && helperText.length > 0 ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}

      {error ? (
        <FormErrorMessage className="error">{error.message}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

interface FormCheckboxProps extends WrapperProps, Omit<CheckboxProps, "name"> {}

const FormCheckbox = (props: FormCheckboxProps) => {
  const { name } = props;
  const {
    label,
    error,
    helperText,
    helperPrompt,
    register,
    registerOptions,
    ...inputProps
  } = props;

  return (
    <FormControl isInvalid={!!error}>
      <Checkbox {...register(name, registerOptions)} {...inputProps}>
        {label}
        {helperPrompt}
      </Checkbox>

      {helperText && helperText.length > 0 ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}

      {error ? (
        <FormErrorMessage className="error">{error.message}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

export { FormCheckbox, FormSelect, FormTextInput, FormTextInputWithUnit };
