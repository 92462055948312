import { LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import trim from "lodash/trim";
import { useEffect, useMemo, useState } from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Domain } from "../../API";
import { FormSelect } from "../FormControls";
import useMapStore from "../Map/mapStore";
import useDomainStore from "../Simulations/domainStore";
import useSimulationStore from "../Simulations/simulationStore";
import { SimulationFormValues } from "../Simulations/types";
import { generateResolutionOptions } from "../../endpoints/calculateResolutionOptions";
import SelectSavedDomain from "./SelectSavedDomain";
import { MAX_Y_EXTENT } from "../../constants";

const TABS = ["New Domain", "Saved Domain"];

interface Props {
  editing?: boolean;
  currentDomain: Domain | undefined;
  currentResolution: number;
  domains: Domain[];
  errors: FieldErrors<SimulationFormValues>;
  register: UseFormRegister<SimulationFormValues>;
  setValue: UseFormSetValue<SimulationFormValues>;
  handleSelectDomain: (domainId: string | null) => void;
}

function SimulationDomain(props: Props) {
  const { setSimulationCoordinates, geography } = useSimulationStore();
  const { saveDomain } = useDomainStore();
  const { repositionMap } = useMapStore();

  const [domainName, setDomainName] = useState("");
  const [saving, setSaving] = useState(false);
  const [latValue, setLatValue] = useState(String(geography?.centerLat));
  const [lngValue, setLngValue] = useState(String(geography?.centerLng));
  const [xExtentValue, setXExtentValue] = useState(String(geography?.width));
  const [yExtentValue, setYExtentValue] = useState(String(geography?.height));

  const width = geography?.width || 0;
  const height = geography?.height || 0;

  const { currentDomain, currentResolution, setValue } = props;
  const lockedDomain = currentDomain?.status === "LOCKED";
  const selectedDomainId = currentDomain?.id;

  const resolutionOptions = useMemo(() => {
    return generateResolutionOptions(width, height);
  }, [width, height]);

  useEffect(() => {
    if (!geography) return;

    const { centerLng, centerLat, width, height } = geography;
    setLatValue(String(centerLat));
    setLngValue(String(centerLng));
    setXExtentValue(String(width));
    setYExtentValue(String(height));

    if (!currentDomain) return;

    // if the user has changed domain values (lat, lng, width, height, resolution) either
    // via the map or via the inputs
    // AND if the values don't match those in the currentDomain
    // then we need to clear domainId here
    if (
      String(currentDomain.centerLat) !== String(centerLat) ||
      String(currentDomain.centerLng) !== String(centerLng) ||
      String(currentDomain.width) !== String(width) ||
      String(currentDomain.height) !== String(height) ||
      String(currentDomain.resolution) !== String(currentResolution)
    ) {
      props.handleSelectDomain(null);
    }
  }, [geography, currentDomain, currentResolution]);

  useEffect(() => {
    if (selectedDomainId) {
      const domain = props.domains.find((d) => d.id === selectedDomainId);
      if (domain) {
        setDomainName(domain.name);
        return;
      }
    }

    setDomainName("");
  }, [selectedDomainId, props.domains]);

  useEffect(() => {
    if (!currentResolution) return;

    const allowed = resolutionOptions.filter((option) => !option.disabled);
    const allowedValues = allowed.map((option) => option.value);
    const selectedIsAllowed = allowedValues.includes(currentResolution);
    if (selectedIsAllowed) return;

    if (selectedDomainId && !selectedIsAllowed) {
      console.warn(
        "saved domain has a disallowed resolution - leaving it as is, but this shouldn't be possible",
        {
          currentResolution,
          allowed,
        }
      );
      return;
    }

    const suggestedValue = resolutionOptions.find(
      (option) => option.suggested
    )?.value;

    if (suggestedValue) {
      console.debug(
        `currentResolution=${currentResolution} is not allowed, choosing suggested=${suggestedValue}`
      );
      setValue("resolution", suggestedValue, { shouldTouch: true });
      return;
    }

    console.debug(`using first allowed resolution=${allowedValues[0]}`);
    setValue("resolution", allowedValues[0], {
      shouldTouch: true,
    });
  }, [selectedDomainId, currentResolution, setValue, width, height]);

  function updateCoordinates() {
    if (!latValue || !lngValue || !xExtentValue || !yExtentValue) return;
    try {
      setSimulationCoordinates(
        parseFloat(lngValue),
        parseFloat(latValue),
        parseFloat(xExtentValue),
        parseFloat(yExtentValue)
      );
    } catch (e) {
      console.warn("updateCoordinates error", e);
    }
  }

  const handleSaveDomain = async () => {
    const valueToSave = trim(domainName);
    if (!valueToSave.length) return;
    setSaving(true);
    const domain = await saveDomain({
      name: valueToSave,
      centerLng: geography?.centerLng,
      centerLat: geography?.centerLat,
      width: geography?.width,
      height: geography?.height,
      resolution: currentResolution,
    });
    console.debug("handleSubmit domain", domain);
    setSaving(false);
    props.handleSelectDomain(domain.id);
  };

  const readOnlyBadge = lockedDomain ? (
    <Box as="span" mx={1} position="relative">
      <LockIcon boxSize={3} mt={0} color="whiteAlpha.600" />
    </Box>
  ) : null;

  return (
    <Box width="100%">
      <Box>
        <Tabs isFitted isLazy colorScheme="white" size="lg" align="start">
          <TabList>
            <Tab fontWeight={600}>{TABS[0]}</Tab>
            <Tab fontWeight={600}>{TABS[1]}</Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <VStack spacing={4} alignItems="flex-start">
                <HStack>
                  <FormControl>
                    <FormLabel>Lat {readOnlyBadge}</FormLabel>
                    <Input
                      type="text"
                      value={latValue}
                      onChange={(e) => setLatValue(e.target.value)}
                      onBlur={updateCoordinates}
                      isReadOnly={lockedDomain}
                      isDisabled={lockedDomain}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Lon {readOnlyBadge}</FormLabel>
                    <Input
                      type="text"
                      value={lngValue}
                      onChange={(e) => setLngValue(e.target.value)}
                      onBlur={updateCoordinates}
                      isReadOnly={lockedDomain}
                      isDisabled={lockedDomain}
                    />
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl>
                    <FormLabel>X Extent (km) {readOnlyBadge}</FormLabel>
                    <Input
                      type="number"
                      value={xExtentValue}
                      onChange={(e) => setXExtentValue(e.target.value)}
                      onBlur={updateCoordinates}
                      isReadOnly={lockedDomain}
                      isDisabled={lockedDomain}
                      max={MAX_Y_EXTENT}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Y Extent (km) {readOnlyBadge}</FormLabel>
                    <Input
                      type="number"
                      value={yExtentValue}
                      onChange={(e) => setYExtentValue(e.target.value)}
                      onBlur={updateCoordinates}
                      isReadOnly={lockedDomain}
                      isDisabled={lockedDomain}
                      max={MAX_Y_EXTENT}
                    />
                  </FormControl>
                </HStack>

                <FormSelect
                  id="simulation_resolution"
                  name="resolution"
                  label="Resolution"
                  options={resolutionOptions}
                  register={props.register}
                  registerOptions={{ valueAsNumber: true }}
                  isReadOnly={lockedDomain}
                  isDisabled={lockedDomain}
                  helperPrompt={lockedDomain ? readOnlyBadge : null}
                />

                <HStack
                  width="100%"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <FormControl>
                    <Input
                      placeholder="Domain name (optional)"
                      value={domainName}
                      onChange={(e) => setDomainName(e.target.value)}
                      isReadOnly={selectedDomainId ? true : false}
                      isDisabled={selectedDomainId ? true : false}
                    />
                    {!selectedDomainId && (
                      <FormHelperText>
                        You can enter a name to save this domain for future
                        simulations
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    onClick={handleSaveDomain}
                    colorScheme="gray"
                    variant="outline"
                    isLoading={saving}
                    isDisabled={selectedDomainId ? true : false}
                  >
                    {selectedDomainId ? "Saved" : "Save"}
                  </Button>
                </HStack>
              </VStack>
            </TabPanel>
            <TabPanel px={0}>
              <SelectSavedDomain
                {...props}
                handleSelectDomain={props.handleSelectDomain}
              />
              <VStack spacing={4} alignItems="flex-start"></VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Divider />
        <Box mt={4}>
          <Button
            onClick={repositionMap}
            colorScheme="teal"
            size="xs"
            variant={"outline"}
          >
            Zoom to Domain
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SimulationDomain;
