import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/veerearth-v1.png";

interface NavLinkProps {
  isActive?: boolean;
  children: React.ReactNode;
  to: string;
  onClick?: () => void;
}

const Links = ["Simulations", "Turbines", "Docs"];
const NavLink = (props: NavLinkProps) => {
  const { children, isActive } = props;
  const hoverColor = isActive ? "teal.300" : "teal.300";
  const initialBG = isActive ? "teal.400" : "transparent";

  return (
    <Box
      as={Link}
      to={props.to}
      px={3}
      py={2}
      rounded={"md"}
      cursor="pointer"
      onClick={props.onClick}
      color={isActive ? "white" : "white"}
      textShadow={isActive ? "0 0 5px rgba(0,0,0,0.5)" : "none"}
      bg={initialBG}
      fontWeight={700}
      fontSize={"sm"}
      letterSpacing={"1px"}
      textTransform={"uppercase"}
      boxShadow={isActive ? "0 0 5px rgba(0,0,0,0.5)" : "none"}
      _hover={{
        textDecoration: "none",
        bg: hoverColor,
        shadow: "md",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
      }}
    >
      {children}
    </Box>
  );
};

interface HeaderProps {
  userLabel: string;
  onSetAppSection: (section: string) => void;
  appSection: string;
  signOut: () => void;
}

export default function HeaderProps(props: HeaderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const signOut = () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      props.signOut();
    }
  };

  return (
    <Box px={6}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={6} alignItems={"center"}>
          <Box color="white" as={Link} to="/">
            <Image
              src={logo}
              alt="veerEarth logo"
              w={"10rem"}
              ml={"-1rem"}
              mt={"0.5rem"}
            />
          </Box>
          <HStack
            as={"nav"}
            spacing={8}
            mt={2}
            ml={6}
            display={{ base: "none", md: "flex" }}
          >
            {Links.map((link) => (
              <NavLink
                key={link}
                isActive={location.pathname.startsWith(
                  `/${link.toLowerCase()}`
                )}
                to={link.toLowerCase()}
                onClick={onClose}
              >
                {link}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"} display={{ base: "none", md: "flex" }}>
          <Button
            variant={"link"}
            cursor={"pointer"}
            minW={0}
            onClick={signOut}
            display="block"
            top={0.5}
          >
            <Text fontSize={"xs"}>{props.userLabel}</Text>
          </Button>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box
          pb={4}
          mx={-6}
          px={6}
          mt={1}
          display={{ md: "none" }}
          bg="blackAlpha.900"
        >
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link} to={link.toLowerCase()} onClick={onClose}>
                {link}
              </NavLink>
            ))}
            <NavLink to={"/"} onClick={signOut}>
              Sign out
            </NavLink>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
