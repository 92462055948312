import mapboxgl from "mapbox-gl";

export type metrics = "wsnf" | "wsaf" | "wsd" | "gcfnf" | "gcfaf" | "gcfd";

export type metricsOrNone = metrics | "none";

const DEFICIT_PALETTE = [
  "#de425b", // red
  "#e66572",
  "#ed848b",
  "#f2a0a4",
  "#f5bcbe",
  "#f5d8d8",
  "#f3f3f3", // white-ish
  "#d8e3d2",
  "#bcd2b1",
  "#a0c190",
  "#84b071",
  "#67a051",
  "#488f31", // green
];

export interface WindMapSource {
  id: string;
  type: "vector";
  url: string;
  color_stops: [number, number];
  fillColor?: mapboxgl.Expression;
  fillOpacity?: mapboxgl.Expression;
}

export interface SourceLayer {
  source: string;
  sourceLayer: string;
  isSourceLoaded: boolean;
  minzoom: number;
  maxzoom: number;

  /* Should we should wind speed on hover and allow clicking to reveal more detail? Usually only on the most zoomed-in layer. */
  interactive: boolean;

  /* Should we show a download button for this layer? */
  hasDownloads: false;

  /* The lores layer doesn't interpolate the opacity based on the diminishedOnMapRange. */
  lores: boolean;
}

const WS_POSITIVE_ONLY_PALETTE = [
  // "#fffdea", // white = 0
  // "#e8e8c6",
  // "#ced5a4",
  // "#b1c384",
  // "#92b166",
  // "#70a04b",
  // "#488f31", // greenest

  // from https://gka.github.io/palettes/#/9|s|ffffff,00b9a9|ffffe0,ff005e,93003a|1|1
  "#ffffff", // lightest
  "#e8f7f4",
  "#d0eee9",
  "#b9e5de",
  "#a0ddd3",
  "#86d4c8",
  "#6acbbe",
  "#48c2b3",
  "#00b9a9",

  // from Mike Nov 21
  // "#fcfffc",
  // "#e0f3e9",
  // "#bee7de",
  // "#99dbda",
  // "#71cedd",
  // "#48bfe2",
  // "#22aee8",
  // "#299ae9",
];

const GCF_POSITIVE_ONLY_PALETTE = [
  // "#f3feff", // lightest
  // "#c2d9dd",
  // "#93b4bf",
  // "#6790a2",
  // "#3b6e87",
  // "#004c6d",
  // from https://gka.github.io/palettes/#/9|s|27a9ff,ffffff|ffffe0,ff005e,93003a|1|1
  // "#ffffff", // lightest
  // "#ecf4ff",
  // "#d8e9ff",
  // "#c3deff",
  // "#add3ff",
  // "#96c8ff",
  // "#7bbdff",
  // "#5bb3ff",
  // "#27a9ff",

  // from Mike Nov 21
  "#fcfffc",
  "#e0f3e9",
  "#bee7de",
  "#99dbda",
  "#71cedd",
  "#48bfe2",
  "#22aee8",
  "#299ae9",
];

export interface DataLayer {
  label: string;

  metric: metrics;

  /* actual range of the data we will encounter */
  data_range: [number, number];

  /* range of the data used for scaling the colour palette, used for the hires layer and also for lo-res unless lores_palette_range is provided */
  palette_range: [number, number];

  /* range of the data used for scaling the colour palette on the lo-res / zoomed out version */
  lores_palette_range?: [number, number];

  /* Range where the value is so low that we do not show the values when the user hovers over the cell
     Used to suppress rendering tiny "diff" amounts
   */
  statisticallyInsignifcantRange?: [number, number];

  /* Range where the values are so low that we make the map cells almost completely transparent

     For example, to show cells with values between -0.02 and 0.01 as mostly transparent, code as
     `diminishedOnMapRange: [-0.02, 0.01]`
   */
  diminishedOnMapRange?: [number, number];

  hasNegative: boolean;

  /* colour palette */
  palette: string[];
}

type DATA_LAYERS = {
  [keyName in metrics]: DataLayer;
};

/* Maximum `dist` value that will be display in the CellDetail;
   anything greater than this distance is assumed to be too far
   away to be affected by wind turbines */
export const MAX_DIST_TO_DISPLAY = 200;

export const DATA_LAYERS: DATA_LAYERS = {
  wsnf: {
    metric: "wsnf",
    label: "Unwaked Wind Speed",
    data_range: [0.86, 12.65],
    palette_range: [2, 11],
    diminishedOnMapRange: [0, 4],
    hasNegative: false,
    palette: WS_POSITIVE_ONLY_PALETTE,
  },

  wsaf: {
    metric: "wsaf",
    label: "Waked Wind Speed",
    data_range: [2, 12.65],
    palette_range: [2, 11],
    diminishedOnMapRange: [0, 5],
    hasNegative: false,
    palette: WS_POSITIVE_ONLY_PALETTE,
  },

  wsd: {
    metric: "wsd",
    label: "Difference in Wind Speed",
    data_range: [-5.39, 0.17],
    palette_range: [-1, 0.5],
    lores_palette_range: [-0.5, 0.5],
    statisticallyInsignifcantRange: [-0.01, 0.01],
    diminishedOnMapRange: [-0.00001, 0.000001],
    hasNegative: true,
    palette: DEFICIT_PALETTE,
  },

  gcfd: {
    metric: "gcfd",
    label: "Difference in GCF",
    data_range: [-0.5, 0.01],
    palette_range: [-0.1, 0.05],
    lores_palette_range: [-0.05, 0.1],
    statisticallyInsignifcantRange: [-0.001, 0.001],
    diminishedOnMapRange: [-0.000007, 0.0000007],
    hasNegative: true,
    palette: DEFICIT_PALETTE,
  },

  gcfnf: {
    metric: "gcfnf",
    label: "Unwaked GCF",
    data_range: [0, 0.7],
    palette_range: [0, 0.8],
    hasNegative: false,
    diminishedOnMapRange: [0, 0.3],
    palette: GCF_POSITIVE_ONLY_PALETTE,
  },

  gcfaf: {
    metric: "gcfaf",
    label: "Waked GCF",
    data_range: [0, 0.7],
    palette_range: [0, 0.8],
    diminishedOnMapRange: [0, 0.3],
    hasNegative: false,
    palette: GCF_POSITIVE_ONLY_PALETTE,
  },
};

export interface WindResourceExtras {
  wsr: number;
  gcfr: number;
}

export type TurbineSearchResult = Pick<
  TurbineData,
  "p_name" | "xlong" | "ylat" | "t_state"
>;

export interface TurbineData {
  case_id: string;
  p_name: string;

  /* Commissioning date */
  p_year: number | string;

  // case_id: 4,
  t_state: string;

  /* Total project capacity (MW) */
  p_cap: number;

  /* Turbine rated capacity (kW) */
  t_cap: number;

  /* Rotor diameter (m) */
  t_rd: number;

  /* Hub height (m) */
  t_hh: number;

  /* Manufacturer */
  t_manu: string;

  /* Model */
  t_model: string;

  ylat: number;
  xlong: number;
}

export type RegionCoordinates = [number, number, number, number];

export const AVAILABLE_REGIONS: Record<string, RegionCoordinates> = {
  north_america: [70, -170, 0, -40],
  europe: [71.5, -25.3, 36.5, 50],
  south_america: [15.3, -87.3, -55.9, -29.3],
  east_asia: [29.8, 66.5, 0.8, 115.8],
};

export const AVAILABLE_REGION_FEATURES: Record<
  string,
  GeoJSON.Feature<GeoJSON.Polygon>
> = {};

Object.keys(AVAILABLE_REGIONS).forEach((region) => {
  const [n, w, s, e] = AVAILABLE_REGIONS[region];

  AVAILABLE_REGION_FEATURES[region] = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [w, n],
          [e, n],
          [e, s],
          [w, s],
          [w, n],
        ],
      ],
    },
  };
});
