import { LngLatBounds } from "mapbox-gl";
import convertBoundsToExtents from "../Map/lib/convertBoundsToExtents";
import expandBounds from "../Map/lib/expandBounds";
import { generateSimulationGeography } from "../Map/lib/generateSimulationGeography";
import {
  MetMastLocation,
  MetMastLocations,
  csvToGeoJSON as metMastCsvToGeoJSON,
} from "../MetMastUploader/helpers";
import {
  SimulationTurbineList,
  TurbinePosition,
  csvToGeoJSON as turbineCsvToGeoJSON,
} from "../TurbineLocationUploader/helpers";
import outOfBounds from "./outOfBounds";
import { SimulationState } from "./simulationStore";

export function deriveStateAfterUpload(
  turbineLocations?: SimulationTurbineList,
  metMastLocations?: MetMastLocations,
  centerLng: number = 0,
  centerLat: number = 0,
  width: number = 0,
  height: number = 0,
  regenerateGeoJSON = false,
  recalculateBounds = false
): Partial<SimulationState> {
  // when turbines or metmasts are uploaded, we discard any existing bounds
  // and generate new coordinates to include all the turbines and metmasts
  if (recalculateBounds) {
    const newBounds = new LngLatBounds();

    (turbineLocations || []).forEach((turbine: TurbinePosition) => {
      newBounds.extend([turbine[2], turbine[1]]);
    });

    (metMastLocations || []).forEach((metMast: MetMastLocation) => {
      newBounds.extend([metMast[2], metMast[1]]);
    });

    if (!newBounds.isEmpty()) {
      // expand the bounds by 20km to give some breathing room
      const newExtent = convertBoundsToExtents(expandBounds(newBounds, 20));
      centerLng = newExtent.centerLng;
      centerLat = newExtent.centerLat;
      width = newExtent.width;
      height = newExtent.height;
    }
  }

  const geography = generateSimulationGeography(
    centerLng,
    centerLat,
    width,
    height
  );

  const outOfBoundsCount = outOfBounds(
    turbineLocations,
    metMastLocations,
    geography?.bounds
  );

  const state: Partial<SimulationState> = {
    turbineLocations,
    geography,
    outOfBoundsCount,
  };

  if (regenerateGeoJSON) {
    state.turbineGeoJSON = turbineCsvToGeoJSON(turbineLocations);
    state.metMastGeoJSON = metMastCsvToGeoJSON(metMastLocations);
  }

  return state;
}
