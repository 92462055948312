import { Badge } from "@chakra-ui/react";
import { format } from "timeago.js";
import { Simulation } from "../../API";

const COLORS_SCHEMES = {
  READY_TO_SUBMIT: "yellow",
  SUBMITTED: "yellow",
  DRAFT: "gray",
  RUNNING: "yellow",
  FAILED: "red",
  SUCCEEDED: "green",
};

const LABELS = {
  READY_TO_SUBMIT: "Ready to submit",
  SUBMITTED: "Submitted",
  DRAFT: "Draft",
  RUNNING: "Running",
  FAILED: "Failed",
  SUCCEEDED: "Succeeded",
};

export default function SimulationStatusBadge(props: {
  simulation: Partial<Simulation>;
}) {
  const { status, submittedAt } = props.simulation;
  if (!status) return null;

  const label = LABELS[status] || "Unknown status";
  const colorScheme = COLORS_SCHEMES[status] || "gray";

  let ts: string | undefined;
  if (status === "SUBMITTED" && submittedAt) {
    ts = format(Date.parse(submittedAt)) || undefined;
  }

  return (
    <Badge colorScheme={colorScheme} variant="outline">
      {label} {ts}
    </Badge>
  );
}
