import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { Privacy, Turbine } from "../../API";
import * as persist from "./persist";

interface TurbineState {
  turbines: Turbine[];
  loaded: boolean;
  currentTurbine?: Partial<Turbine>;
  newTurbine: () => void;
  getTurbineSlugs(): string[];
  loadCurrentTurbine: (slug?: string) => void;
  setCurrentTurbine: (turbine?: Turbine) => void;
  saveTurbine: (params: Partial<Turbine>) => Promise<Partial<Turbine>>;
  deleteTurbine: (turbineId: string) => Promise<boolean>;
  fetch: (forceRefetch?: boolean) => Promise<void>;
  reset: () => void;
}

const useTurbineStore = create<TurbineState>()(
  devtools(
    (set, get) => ({
      turbines: [],
      loaded: false,
      currentTurbine: undefined,

      reset: () => {
        set({ turbines: [], loaded: false, currentTurbine: undefined });
      },

      setCurrentTurbine: (turbine?: Turbine) => {
        set({ currentTurbine: turbine });
      },

      getTurbineSlugs: () => {
        return get().turbines.map((t) => t.slug);
      },

      newTurbine: () => {
        set(() => ({
          currentTurbine: {
            __typename: "Turbine",
            privacy: Privacy.PRIVATE,
          },
        }));
      },

      fetch: async (forceRefetch = false) => {
        try {
          if (get().loaded && !forceRefetch) {
            return;
          }
          const turbines = await persist.fetchTurbines();
          set({ turbines, loaded: true });
        } catch (error) {
          console.error(error);
        }
      },

      loadCurrentTurbine: async (slug?: string) => {
        if (!slug) {
          set({ currentTurbine: undefined });
          return;
        }

        if (!get().loaded) {
          await get().fetch();
        }

        const currentTurbine = get().turbines.find(
          (t) => t.id === slug || t.slug === slug
        );
        set({ currentTurbine });
      },

      saveTurbine: async (
        params: Partial<Turbine>
      ): Promise<Partial<Turbine>> => {
        try {
          const turbine = await persist.saveTurbine(params);

          let turbines = get().turbines;
          // // add or update in state
          if (!turbines.find((t) => t.id === turbine.id)) {
            turbines = [turbine, ...turbines] as Turbine[];
          } else {
            turbines = turbines.map((t) => (t.id === turbine.id ? turbine : t));
          }

          set(() => ({
            turbines: turbines,
            currentTurbine: turbine,
          }));

          return turbine;
        } catch (error) {
          console.error(error);
          return params;
        }
      },

      deleteTurbine: async (turbineId: string) => {
        try {
          await persist.deleteCurrentTurbine(turbineId);
          set((state) => ({
            turbines: state.turbines.filter((t) => t.id !== turbineId),
          }));
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
    }),
    { name: "turbineStore" }
  )
);

export default useTurbineStore;
