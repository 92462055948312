import { MutableRefObject } from "react";
import { create } from "zustand";

interface MapState {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  setMap: (map: mapboxgl.Map | null) => void;

  /* Allow triggering a map fitBounds call when coordinates change */
  repositionMapTs: number;
  repositionMap: () => void;
}

export const useMapStore = create<MapState>((set) => ({
  mapRef: { current: null },
  setMap: (map) => {
    set(() => ({ mapRef: { current: map } }));
  },
  repositionMapTs: 0,
  repositionMap() {
    set(() => ({
      repositionMapTs: Date.now(),
    }));
  },
}));

export default useMapStore;
