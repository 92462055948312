import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex, FormControl, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { useCSVReader } from "react-papaparse";
import DownloadTemplateLink from "../Common/DownloadTemplateLink";
import useSimulationStore from "../Simulations/simulationStore";
import UploaderErrors from "../UploaderErrors";
import UploaderFileTarget from "../UploaderFileTarget";
import Results from "./Results";
import { MetMastLocations, parseCSV } from "./helpers";

interface Props {
  metMastLocations?: MetMastLocations;
}

function MetMastsUploader(props: Props) {
  const { CSVReader } = useCSVReader();

  const { setMetMastLocations } = useSimulationStore();
  const [uploaded, setUploaded] = useState<MetMastLocations | undefined>(
    props.metMastLocations
  );

  const [showUploader, setShowUploader] = useState<boolean>(
    (uploaded || []).length > 0
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [zoneHover, setZoneHover] = useState(false);

  const showUploadUI = showUploader && (!uploaded || uploaded.length < 1);

  const clearUpload = () => {
    setUploaded(undefined);
    setMetMastLocations(undefined, false);
  };

  const onUploadAccepted = (results: any) => {
    const { metMastLocations, errors } = parseCSV(results.data);
    if (errors.length > 0) {
      setErrors(errors);
      clearUpload();
      return;
    }

    setErrors([]);
    setUploaded(metMastLocations);
    setMetMastLocations(metMastLocations, false);
  };

  return (
    <FormControl width={"100%"}>
      <CSVReader
        onUploadAccepted={(results: any) => {
          setZoneHover(false);
          onUploadAccepted(results);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, ProgressBar, getRemoveFileProps, Remove }: any) => {
          return (
            <Box>
              <Flex>
                <Box flex={1}>
                  <Checkbox
                    onChange={() => setShowUploader(!showUploader)}
                    isChecked={showUploader}
                    colorScheme="teal"
                  >
                    Virtual Met Masts
                  </Checkbox>

                  {showUploadUI && (
                    <DownloadTemplateLink href="/csv-samples/simulation-metmasts.csv" />
                  )}
                </Box>
                <Box>
                  <Tooltip
                    label="Additional locations to extract timeseries data"
                    fontSize="sm"
                  >
                    <QuestionOutlineIcon ml={2} color={"gray.200"} />
                  </Tooltip>
                </Box>
              </Flex>

              {showUploadUI && (
                <Box my={4}>
                  <UploaderFileTarget
                    getRootProps={getRootProps}
                    hovering={zoneHover}
                    label="Add Met Masts"
                  />
                </Box>
              )}

              <UploaderErrors errors={errors} />

              {showUploader && uploaded ? (
                <Results
                  metMastLocations={uploaded}
                  clearUpload={clearUpload}
                  getRemoveFileProps={getRemoveFileProps}
                  ProgressBar={ProgressBar}
                  Remove={Remove}
                />
              ) : null}
            </Box>
          );
        }}
      </CSVReader>
    </FormControl>
  );
}

export default MetMastsUploader;
