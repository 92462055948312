// This is a Lambda + API Gateway endpoint that calculates the price of a simulation
// based on the input parameters.
//
// It isn't maintained as part of this Amplify project so that Mike can edit it as needed.

const ESTIMATE_PRICE_ENDPOINT =
  "https://t3uj22wpy4.execute-api.us-west-2.amazonaws.com/dev/calculate";

interface Params {
  resolution?: number | string | null;
  gridCellCount?: number | null;
  metMastCount?: number | null;
  turbineCount?: number | null;
  turbineTypes?: { ratedPower: number }[] | null;
  typicalMeteorologicalYear?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  wrgHeights?: number[] | null | undefined;
  useTurbineLocationsAsMetMasts: boolean;
}

interface Response {
  num_cells: number;
  num_extraction_cells: number;
  resolution: number;
  simulation_cost: number;
  timeseries_cost: number;
  total_cost: number;
  wrg_cost: number;
  currency: string;
}

export interface SimulationPriceResult {
  totalCostCents: number;
  simulationCostCents: number;
  timeseriesCostCents: number;
  wrgCostCents: number;

  currency: string;
  success: boolean;
}

const calculatePrice = async (
  params: Params
): Promise<SimulationPriceResult | null> => {
  const endpointParams = {
    resolution:
      typeof params.resolution === "string"
        ? parseInt(params.resolution)
        : params.resolution,
    gridCellCount: params.gridCellCount || 0,
    turbineCount: params.turbineCount || 0,
    metMastCount: params.metMastCount || 0,
    startDate: params.startDate,
    endDate: params.endDate,
    typicalMeteorologicalYear: params.typicalMeteorologicalYear,
    wrgHeights: params.wrgHeights || [],
    turbineTypes: params.turbineTypes,
    useTurbineLocationsAsMetMasts: params.useTurbineLocationsAsMetMasts,
  };

  if (!endpointParams.resolution) {
    return null;
  }

  if (endpointParams.gridCellCount < 1) {
    return null;
  }

  const result = await fetch(ESTIMATE_PRICE_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(endpointParams),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return {
        totalCostCents: res.total_cost * 100,
        simulationCostCents: res.simulation_cost * 100,
        timeseriesCostCents: res.timeseries_cost * 100,
        wrgCostCents: res.wrg_cost * 100,
        currency: res.currency || "USD",
        success: true,
      };
    })
    .catch(console.error);

  return result as SimulationPriceResult;
};

export default calculatePrice;
