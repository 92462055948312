import mapboxgl from "mapbox-gl";
import "./index.css";

function generateLegend(palette: Record<string, string>): string {
  const records = Object.keys(palette).map((slug) => {
    const color = palette[slug];
    return `<div class="entry"><span class="bullet" style="color: ${color}">⬤</span> <span class="title">${slug}</span></div>`;
  });

  if (records.length === 0) return "";

  return `<div class="map-legend">
    <div class="legend">
      ${records.join("")}
    </div>
  `;
}

export class TurbineLegend implements mapboxgl.IControl {
  _map: mapboxgl.Map | undefined;
  _container: HTMLElement | undefined;
  _palette: Record<string, string>;

  constructor(palette: Record<string, string>) {
    this._palette = palette;
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl map-legend-ctrl";
    this._map.on("data", () => {
      this.update();
    });

    return this._container;
  }

  onRemove() {
    if (this._container) {
      this._container.parentNode?.removeChild(this._container);
    }
    this._map = undefined;
  }

  update() {
    if (!this._map) return;
    if (!this._container) return;
    this._container.innerHTML = generateLegend(this._palette);
  }
}
