import { API } from "aws-amplify";

interface JsonResponse {
  simulation: {
    id: string;
    slug: string;
  };
}

const submitSimulation = async (simulationId: string): Promise<boolean> => {
  try {
    const apiName = "submitSimulation"; // as provided in 'amplify add api'
    const path = `/submitSimulation/${simulationId}`; // or other route you specified

    const result: JsonResponse = await API.post(apiName, path, {
      response: true,
      responseType: "json",
    });
    console.debug("submitSimulation", { result });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default submitSimulation;
