import { Simulation } from "../API";
import era5Global from "./simulations.era5Global";
import texasPandhandle from "./simulations.texasPanhandle";
import boemMaine from "./simulations.boemMaine";

export const DEMO_SIMULATIONS: Simulation[] = [
  texasPandhandle,
  era5Global,
  boemMaine,
];
