import { Stack, Skeleton, Box } from "@chakra-ui/react";

export default function LibraryLoading() {
  return (
    <Box p={8}>
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </Box>
  );
}
