import mapboxgl from "mapbox-gl";
import generateLegend from "./generateLegend";
import "./index.css";

export class WindSpeedLegend implements mapboxgl.IControl {
  _map: mapboxgl.Map | undefined;
  _container: HTMLElement | undefined;

  constructor() {}

  onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl map-legend-ctrl";

    this._map.on("data", () => {
      this.update();
    });

    return this._container;
  }

  onRemove() {
    if (this._container) {
      this._container.parentNode?.removeChild(this._container);
    }
    this._map = undefined;
  }

  update() {
    if (!this._map) return;
    if (!this._container) return;

    const styles = this._map.getStyle();
    const currentZoom = this._map.getZoom();
    const windLayers = Object.values(
      styles.layers as mapboxgl.FillLayer[]
    ).filter((l) => {
      return l.metadata?.metric === "wsaf";
    });

    const visibleWindLayers = windLayers.filter((l) => {
      return (
        (!l.minzoom || l.minzoom <= currentZoom) &&
        (!l.maxzoom || l.maxzoom >= currentZoom)
      );
    });

    if (!visibleWindLayers || visibleWindLayers.length < 1) {
      this._container.innerHTML = "";
      return;
    }

    visibleWindLayers.forEach((windLayer) => {
      if (!this._container) return;
      windLayer = windLayer as mapboxgl.FillLayer;
      this._container.innerHTML = generateLegend(
        windLayer.paint!["fill-color"] as mapboxgl.StyleFunction,
        windLayer.metadata?.name,
        windLayer.metadata?.formatting
      );
    });
  }
}
