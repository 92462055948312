import { SimulationGeography } from "./generateSimulationGeography";

export default function shouldRenderBoundingBox(
  geography: SimulationGeography | undefined
) {
  if (!geography) return false;
  if (geography.width <= 0 || geography.height <= 0) return false;
  if (!geography.centerLat || !geography.centerLng) return false;
  return true;
}
