import mapboxgl from "mapbox-gl";

export function removeMapSource(map: mapboxgl.Map | undefined, source: string) {
  try {
    if (map && map.getSource(source)) {
      map.removeSource(source);
    }
  } catch (e) {
    console.debug(`Error removing source ${source}: ${(e as Error).message}`);
  }
}

export function removeMapLayer(map: mapboxgl.Map | undefined, layer: string) {
  try {
    if (map && map.getLayer(layer)) {
      map.removeLayer(layer);
    }
  } catch (e) {
    console.debug(`Error removing layer ${layer}: ${(e as Error).message}`);
  }
}
