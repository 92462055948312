import { Box, useColorModeValue, useToken } from "@chakra-ui/react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { memo, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { PowerCurve } from "./generatePowerCurveJSON";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
interface Props {
  powerCurve: PowerCurve;
  metric: "power" | "thrust";
  xLabel?: string;
  yLabel?: string;
  color?: string;

  label?: string;
}

const PowerThrustChart = memo(function PowerThrustChart(props: Props) {
  const { metric, powerCurve, label, yLabel, xLabel } = props;
  const color = props.color
    ? useToken("colors", props.color)
    : "rgba(255, 99, 132, 0.2)";

  const textColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "whiteAlpha.300");

  const data: any = useMemo(() => {
    let values = [];
    switch (props.metric) {
      case "power": {
        values = powerCurve.map((row) => {
          return { x: row[0], y: row[2] };
        });
        break;
      }
      case "thrust": {
        values = powerCurve.map((row) => {
          return { x: row[0], y: row[1] };
        });
        break;
      }
    }

    return {
      labels: props.powerCurve.map((row) => row[0]),
      datasets: [
        {
          id: metric,
          label: label || metric,
          data: values,
          fill: false,
          backgroundColor: color,
          borderWidth: 4,
          borderColor: color,
        },
      ],
    };
  }, [metric, powerCurve, label]);

  return (
    <Box bg={bgColor} color={textColor} p={4} borderRadius="md" mb={4}>
      <div className="chart-container" style={{ position: "relative" }}>
        <Line
          data={data}
          width={"400px"}
          height={"220px"}
          options={{
            responsive: false,
            plugins: {
              legend: {
                labels: {
                  color: "white",
                  font: {
                    size: 18,
                  },
                },
              },
            },

            scales: {
              x: {
                ticks: {
                  color: textColor,
                },
                display: !!xLabel,
                title: {
                  display: true,
                  text: xLabel,
                  color: textColor,
                },
                beginAtZero: true,
              },
              y: {
                ticks: {
                  color: textColor,
                },
                display: !!yLabel,
                title: {
                  display: true,
                  text: yLabel,
                  color: textColor,
                },
                beginAtZero: true,
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
          }}
          datasetIdKey={metric}
        />
      </div>
    </Box>
  );
});

export default PowerThrustChart;
