import { roundCoordinate } from "../util";

export type MetMastLocation = [number, number, number];
export type MetMastLocations = MetMastLocation[];

type ParseCSVResult = {
  errors: string[];
  metMastLocations: MetMastLocations;
};

function csvToGeoJSON(
  metMastLocations: MetMastLocations | null | undefined
): GeoJSON.FeatureCollection<GeoJSON.Point> {
  const features = (metMastLocations || []).map((location) => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [location[2], location[1]],
      },
      properties: {
        id: location[0],
      },
    } as GeoJSON.Feature<GeoJSON.Point>;
  });

  return {
    type: "FeatureCollection",
    features,
  };
}

function parseCSV(data: any[]): ParseCSVResult {
  let errors: string[] = [];

  // let maybeHeaderRow = true;
  let locations: MetMastLocations = [];
  try {
    for (var i = 1; i < data.length; i++) {
      const row = data[i];
      if (row.length <= 1) continue;
      let parsed = [
        parseInt(row[0], 10),
        roundCoordinate(parseFloat(row[1])),
        roundCoordinate(parseFloat(row[2])),
      ];

      if (!parsed) continue;

      locations.push(parsed as MetMastLocation);
    }

    return { errors, metMastLocations: locations };
  } catch (e) {
    console.error(e);
    return { errors: [(e as Error).message], metMastLocations: [] };
  }
}

export { parseCSV, csvToGeoJSON };
