import { Alert, AlertDescription, Box } from "@chakra-ui/react";

export default function TurbineLocationsUploaderErrors(props: {
  errors: string[];
}) {
  const { errors } = props;
  if (errors.length < 1) return null;

  return (
    <Box mt={4}>
      <Alert status="error" variant="left-accent" rounded={4}>
        <AlertDescription fontSize={"sm"}>
          <Box>
            {errors.map((error, i) => (
              <Box key={i}>{error}</Box>
            ))}
          </Box>
        </AlertDescription>
      </Alert>
    </Box>
  );
}
