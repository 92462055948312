import * as APITypes from "../API";

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

interface SimulationsTocResult
  extends Pick<
    APITypes.Simulation,
    | "__typename"
    | "id"
    | "name"
    | "status"
    | "statusMessage"
    | "privacy"
    | "domainId"
    | "domain"
    | "slug"
    | "createdAt"
    | "updatedAt"
    | "deletedAt"
  > {}

export type ListSimulationsTocQueryVariables = {
  limit?: number | null;
  nextToken?: string | null;
};

export const listSimulationsToc = /* GraphQL */ `
  query ListSimulations(
  $limit: Int
  $nextToken: String
) {
  listSimulations(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        privacy
        statusMessage
        domainId
        slug
        domain {
            id
            slug
            name
          }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
    __typename
    }
  }
` as GeneratedQuery<ListSimulationsTocQueryVariables, ListSimulationsTocQuery>;

export type ListSimulationsTocQuery = {
  listSimulations?: {
    __typename: "ModelSimulationConnection";
    items: Partial<SimulationsTocResult>[];
    nextToken?: string | null;
  } | null;
};
