/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    slug
    name
    privacy
    simulations {
      nextToken
      __typename
    }
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    slug
    name
    privacy
    simulations {
      nextToken
      __typename
    }
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    slug
    name
    privacy
    simulations {
      nextToken
      __typename
    }
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createSimulationSet = /* GraphQL */ `mutation CreateSimulationSet(
  $input: CreateSimulationSetInput!
  $condition: ModelSimulationSetConditionInput
) {
  createSimulationSet(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSimulationSetMutationVariables,
  APITypes.CreateSimulationSetMutation
>;
export const updateSimulationSet = /* GraphQL */ `mutation UpdateSimulationSet(
  $input: UpdateSimulationSetInput!
  $condition: ModelSimulationSetConditionInput
) {
  updateSimulationSet(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSimulationSetMutationVariables,
  APITypes.UpdateSimulationSetMutation
>;
export const deleteSimulationSet = /* GraphQL */ `mutation DeleteSimulationSet(
  $input: DeleteSimulationSetInput!
  $condition: ModelSimulationSetConditionInput
) {
  deleteSimulationSet(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSimulationSetMutationVariables,
  APITypes.DeleteSimulationSetMutation
>;
export const createDomain = /* GraphQL */ `mutation CreateDomain(
  $input: CreateDomainInput!
  $condition: ModelDomainConditionInput
) {
  createDomain(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDomainMutationVariables,
  APITypes.CreateDomainMutation
>;
export const updateDomain = /* GraphQL */ `mutation UpdateDomain(
  $input: UpdateDomainInput!
  $condition: ModelDomainConditionInput
) {
  updateDomain(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDomainMutationVariables,
  APITypes.UpdateDomainMutation
>;
export const deleteDomain = /* GraphQL */ `mutation DeleteDomain(
  $input: DeleteDomainInput!
  $condition: ModelDomainConditionInput
) {
  deleteDomain(input: $input, condition: $condition) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDomainMutationVariables,
  APITypes.DeleteDomainMutation
>;
export const createSimulation = /* GraphQL */ `mutation CreateSimulation(
  $input: CreateSimulationInput!
  $condition: ModelSimulationConditionInput
) {
  createSimulation(input: $input, condition: $condition) {
    id
    domainId
    domain {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    slug
    name
    centerLat
    centerLng
    resolution
    width
    height
    bounds
    area
    gridCellCount
    typicalMeteorologicalYear
    startDate
    endDate
    wrgHeights
    estimatedPrice
    estimatedPriceBreakdown
    privacy
    projectId
    project {
      id
      slug
      name
      privacy
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    turbineLocations
    turbineTypes
    turbineCount
    useTurbineLocationsAsMetMasts
    modelWakes
    metMastLocations
    metMastCount
    status
    statusMessage
    s3Bucket
    s3Prefix
    outputs
    submitterInfo
    submitterEmail
    submittedAt
    startedAt
    completedAt
    deletedAt
    createdAt
    updatedAt
    projectSimulationsId
    simulationSetSimulationsId
    domainSimulationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSimulationMutationVariables,
  APITypes.CreateSimulationMutation
>;
export const updateSimulation = /* GraphQL */ `mutation UpdateSimulation(
  $input: UpdateSimulationInput!
  $condition: ModelSimulationConditionInput
) {
  updateSimulation(input: $input, condition: $condition) {
    id
    domainId
    domain {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    slug
    name
    centerLat
    centerLng
    resolution
    width
    height
    bounds
    area
    gridCellCount
    typicalMeteorologicalYear
    startDate
    endDate
    wrgHeights
    estimatedPrice
    estimatedPriceBreakdown
    privacy
    projectId
    project {
      id
      slug
      name
      privacy
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    turbineLocations
    turbineTypes
    turbineCount
    useTurbineLocationsAsMetMasts
    modelWakes
    metMastLocations
    metMastCount
    status
    statusMessage
    s3Bucket
    s3Prefix
    outputs
    submitterInfo
    submitterEmail
    submittedAt
    startedAt
    completedAt
    deletedAt
    createdAt
    updatedAt
    projectSimulationsId
    simulationSetSimulationsId
    domainSimulationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSimulationMutationVariables,
  APITypes.UpdateSimulationMutation
>;
export const deleteSimulation = /* GraphQL */ `mutation DeleteSimulation(
  $input: DeleteSimulationInput!
  $condition: ModelSimulationConditionInput
) {
  deleteSimulation(input: $input, condition: $condition) {
    id
    domainId
    domain {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    slug
    name
    centerLat
    centerLng
    resolution
    width
    height
    bounds
    area
    gridCellCount
    typicalMeteorologicalYear
    startDate
    endDate
    wrgHeights
    estimatedPrice
    estimatedPriceBreakdown
    privacy
    projectId
    project {
      id
      slug
      name
      privacy
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    turbineLocations
    turbineTypes
    turbineCount
    useTurbineLocationsAsMetMasts
    modelWakes
    metMastLocations
    metMastCount
    status
    statusMessage
    s3Bucket
    s3Prefix
    outputs
    submitterInfo
    submitterEmail
    submittedAt
    startedAt
    completedAt
    deletedAt
    createdAt
    updatedAt
    projectSimulationsId
    simulationSetSimulationsId
    domainSimulationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSimulationMutationVariables,
  APITypes.DeleteSimulationMutation
>;
export const createTurbine = /* GraphQL */ `mutation CreateTurbine(
  $input: CreateTurbineInput!
  $condition: ModelTurbineConditionInput
) {
  createTurbine(input: $input, condition: $condition) {
    id
    slug
    description
    status
    privacy
    rotorDiameter
    ratedPower
    powerCurve
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTurbineMutationVariables,
  APITypes.CreateTurbineMutation
>;
export const updateTurbine = /* GraphQL */ `mutation UpdateTurbine(
  $input: UpdateTurbineInput!
  $condition: ModelTurbineConditionInput
) {
  updateTurbine(input: $input, condition: $condition) {
    id
    slug
    description
    status
    privacy
    rotorDiameter
    ratedPower
    powerCurve
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTurbineMutationVariables,
  APITypes.UpdateTurbineMutation
>;
export const deleteTurbine = /* GraphQL */ `mutation DeleteTurbine(
  $input: DeleteTurbineInput!
  $condition: ModelTurbineConditionInput
) {
  deleteTurbine(input: $input, condition: $condition) {
    id
    slug
    description
    status
    privacy
    rotorDiameter
    ratedPower
    powerCurve
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTurbineMutationVariables,
  APITypes.DeleteTurbineMutation
>;
