import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import { useCSVReader } from "react-papaparse";
import DownloadTemplateLink from "../Common/DownloadTemplateLink";
import UploaderErrors from "../UploaderErrors";
import UploaderFileTarget from "../UploaderFileTarget";
import Results from "./Results";

import generatePowerCurveJSON, {
  PowerCurve,
} from "../Turbines/generatePowerCurveJSON";

interface Props {
  powerCurve?: PowerCurve | undefined;
  onUpload: (powerCurve: PowerCurve | undefined) => void;
}

function PowerThrustUploader(props: Props) {
  const { onUpload } = props;
  const { CSVReader } = useCSVReader();
  const [uploaded, setUploaded] = useState<PowerCurve | undefined>(
    props.powerCurve
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [zoneHover, setZoneHover] = useState(false);

  const showUploadUI = !uploaded || uploaded.length < 1;

  const clearUpload = () => {
    setUploaded(undefined);
    onUpload(undefined);
  };

  const onUploadAccepted = (results: any) => {
    const { powerCurve, errors } = generatePowerCurveJSON(results.data);
    if (errors.length > 0) {
      setErrors(errors);
      clearUpload();
      return;
    }

    setErrors([]);
    setUploaded(powerCurve);
    onUpload(powerCurve);
  };

  return (
    <Box width={"100%"}>
      <CSVReader
        onUploadAccepted={(results: any) => {
          setZoneHover(false);
          onUploadAccepted(results);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, ProgressBar, getRemoveFileProps, Remove }: any) => {
          return (
            <FormControl>
              <FormLabel htmlFor="simulationTurbines">
                Power/Thrust
                {showUploadUI && (
                  <DownloadTemplateLink href="/csv-samples/turbine-power-curve-valid.csv" />
                )}
              </FormLabel>

              {showUploadUI && (
                <Box my={4}>
                  <UploaderFileTarget
                    getRootProps={getRootProps}
                    hovering={zoneHover}
                    label="Add Data"
                  />
                </Box>
              )}

              <UploaderErrors errors={errors} />

              {uploaded ? (
                <Box mb={10}>
                  <Results
                    powerCurve={uploaded}
                    clearUpload={clearUpload}
                    getRemoveFileProps={getRemoveFileProps}
                    ProgressBar={ProgressBar}
                    Remove={Remove}
                  />
                </Box>
              ) : null}
            </FormControl>
          );
        }}
      </CSVReader>
    </Box>
  );
}

export default PowerThrustUploader;
