import "./index.css";
import { Box } from "@chakra-ui/react";
import MapboxMap from "./MapboxMap";
import MapPlaceholder from "./MapPlaceholder";

interface MapContainerProps {
  disabled: boolean;
}

export default function MapContainer(props: MapContainerProps) {
  const { disabled } = props;

  return (
    <Box
      className="map map-container"
      position="absolute"
      top={0}
      left={0}
      right={0}
      width={"100%"}
      height="100%"
      zIndex={1}
    >
      {disabled ? (
        <MapPlaceholder />
      ) : (
        <>
          <MapboxMap />
        </>
      )}
    </Box>
  );
}
