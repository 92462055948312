import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Privacy, Simulation, SimulationStatus, Turbine } from "../../API";
import {
  SimulationGeography,
  generateSimulationGeography,
} from "../Map/lib/generateSimulationGeography";
import { SourceLayer, WindMapSource } from "../Map/lib/types";
import { MetMastLocations } from "../MetMastUploader/helpers";
import { SimulationTurbineList } from "../TurbineLocationUploader/helpers";
import formatTurbineForSimulation from "../Turbines/formatTurbineForSimulation";
import useTurbineStore from "../Turbines/turbineStore";
import { deriveStateAfterUpload } from "./deriveStateAfterUpload";
import * as persist from "./persist";
import useDomainStore from "./domainStore";
import { canEditCoordinates } from "./simulationStates";
export const POSSIBLE_WRG_HEIGHTS = ["60", "80", "100", "120", "160", "200"]; // in meters

function recalculateGeography(
  simulation: Partial<Simulation>
): SimulationGeography | undefined {
  try {
    if (!simulation) return undefined;
    const { centerLng, centerLat, width, height } = simulation;
    if (!centerLng || !centerLat || !width || !height) return undefined;
    return generateSimulationGeography(centerLng, centerLat, width, height);
  } catch (error) {
    console.error(error);
  }
}

export interface SimulationState {
  loaded: boolean;
  simulations: Partial<Simulation>[];
  currentSimulation?: Partial<Simulation>;

  loadCurrentSimulation: (
    slug: string | undefined,
    forEditing?: boolean
  ) => Promise<void>;

  editingSimulation: boolean;
  lockedCoordinates: boolean;
  setLockedCoordinates: (locked: boolean) => void;

  setCurrentSimulation: (
    simulation: Partial<Simulation> | undefined,
    forEditing?: boolean
  ) => void;
  saveSimulation: (params: Partial<Simulation>) => Promise<Partial<Simulation>>;
  deleteSimulation: (simulationId: string) => Promise<boolean>;

  currentSimulationErrors: string[];

  windMapSource: WindMapSource | undefined;
  windMapSourceLayers: SourceLayer[];

  geography: SimulationGeography | undefined;

  setSimulationCoordinates: (
    centerLng: number,
    centerLat: number,
    width: number,
    height: number
  ) => void;

  turbineTypes: Turbine[];

  turbineLocations: SimulationTurbineList | undefined;
  setTurbineLocations: (
    turbineLocations: SimulationTurbineList | undefined,
    recalculateBounds: boolean
  ) => void;
  turbineGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;
  outOfBoundsCount: number;

  metMastLocations: MetMastLocations | undefined;
  setMetMastLocations: (
    metMastLocations: MetMastLocations | undefined,
    recalculateBounds: boolean
  ) => void;
  metMastGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;

  newSimulation: (forEditing?: boolean) => void;
  fetch: (forceRefetch?: boolean) => Promise<void>;
  submitSimulation: (simulationId: string) => Promise<boolean>;
  regenerateBounds: () => void;

  reset: () => void;
}

function filterTurbineLocations(
  turbineLocations: SimulationTurbineList,
  availableTurbineSlugs: string[]
): {
  turbineLocations: SimulationTurbineList | undefined;
  usedTurbineSlugs: string[];
  unknownTurbineTypes: string[];
} {
  if (!turbineLocations) {
    return {
      turbineLocations: [],
      unknownTurbineTypes: [],
      usedTurbineSlugs: [],
    };
  }

  const usedTurbineSlugs = new Set<string>();
  const unknownTurbineSlugs = new Set<string>();
  const filtered = turbineLocations.filter((turbine) => {
    const slug = turbine[4];
    if (!availableTurbineSlugs.includes(slug)) {
      console.warn("could not find", slug, "in", availableTurbineSlugs);
      unknownTurbineSlugs.add(slug);
      return false;
    }
    usedTurbineSlugs.add(slug);
    return true;
  });

  return {
    turbineLocations: filtered,
    usedTurbineSlugs: Array.from(usedTurbineSlugs),
    unknownTurbineTypes: Array.from(unknownTurbineSlugs),
  };
}

const blankState: Partial<SimulationState> = {
  simulations: [],
  loaded: false,
  currentSimulation: undefined,
  editingSimulation: false,
  turbineTypes: [],
  geography: undefined,
  windMapSource: undefined,
  windMapSourceLayers: [],
  metMastLocations: undefined,
  turbineLocations: undefined,
  turbineGeoJSON: undefined,
  metMastGeoJSON: undefined,
  currentSimulationErrors: [],
  lockedCoordinates: false,
  outOfBoundsCount: 0,
};

const useSimulationStore = create<SimulationState>()(
  devtools(
    (set, get) => ({
      simulations: [] as Partial<Simulation>[],
      loaded: false as boolean,
      lockedCoordinates: false as boolean,

      reset: () => {
        set(blankState);
      },

      setLockedCoordinates: (locked: boolean) => {
        set({ lockedCoordinates: locked });
      },

      currentSimulationErrors: [] as string[],
      editingSimulation: false as boolean,

      loadCurrentSimulation: async (
        slug: string | undefined,
        forEditing = false
      ) => {
        if (!slug) {
          get().setCurrentSimulation(undefined, forEditing);
          return;
        }

        if (!get().loaded) await get().fetch();

        const currentSimulationId = get().simulations.find(
          (s) => s.id === slug || s.slug === slug
        )?.id;

        if (!currentSimulationId)
          throw new Error(`Could not find simulation with slug ${slug}`);

        let currentSimulation = await persist.retrieveSimulation(
          currentSimulationId
        );

        if (currentSimulation && currentSimulation.domainId) {
          // TODO: we should be able to do this with graphql
          if (!currentSimulation.domain) {
            console.warn("fetching and assigning domain for simulation");
            const domains = useDomainStore.getState().domains;
            const currentDomain = domains.find(
              (s) => s.id === currentSimulation?.domainId
            );
            currentSimulation.domain = currentDomain;
          }
        }

        get().setCurrentSimulation(currentSimulation, forEditing);
      },

      currentSimulation: undefined as Partial<Simulation> | undefined,
      setCurrentSimulation: (
        simulation?: Partial<Simulation>,
        forEditing = false
      ) => {
        let turbineLocations: SimulationTurbineList | undefined;
        let turbineTypes: Turbine[] = [];
        let geography: SimulationGeography | undefined;
        let windMapSource: WindMapSource | undefined;
        let windMapSourceLayers: SourceLayer[] = [];
        let metMastLocations: MetMastLocations = [];

        if (simulation) {
          geography = recalculateGeography(simulation);
        }

        if (simulation && simulation.turbineLocations) {
          turbineLocations = JSON.parse(simulation.turbineLocations);
        }

        if (simulation && simulation.turbineTypes) {
          turbineTypes = JSON.parse(simulation.turbineTypes);
        }

        if (simulation && simulation.metMastLocations) {
          metMastLocations = JSON.parse(simulation.metMastLocations);
        }

        if (simulation && simulation.outputs) {
          const outputs = JSON.parse(simulation.outputs);

          if (outputs.wind_map_source) {
            windMapSource = outputs.wind_map_source;
          }
          if (outputs.wind_map_source_layers) {
            windMapSourceLayers = outputs.wind_map_source_layers;
          }
        } else {
          windMapSource = undefined;
          windMapSourceLayers = [];
        }

        const lockedCoordinates = canEditCoordinates(simulation || {});

        set({
          currentSimulation: simulation,
          editingSimulation: forEditing,
          turbineTypes,
          geography,
          windMapSource,
          windMapSourceLayers,
          metMastLocations,
          lockedCoordinates,
          currentSimulationErrors: [],
          ...deriveStateAfterUpload(
            turbineLocations,
            metMastLocations,
            simulation?.centerLng,
            simulation?.centerLat,
            simulation?.width,
            simulation?.height,
            true,
            false
          ),
        });
      },

      windMapSource: undefined as WindMapSource | undefined,
      windMapSourceLayers: [] as SourceLayer[],

      turbineGeoJSON: undefined as
        | GeoJSON.FeatureCollection<GeoJSON.Point>
        | undefined,
      turbineTypes: [] as Turbine[],

      metMastLocations: undefined as MetMastLocations | undefined,
      metMastGeoJSON: undefined as
        | GeoJSON.FeatureCollection<GeoJSON.Point>
        | undefined,
      setMetMastLocations: (
        metMastLocations?: MetMastLocations,
        recalculateBounds = false
      ) => {
        const turbineLocations = get().turbineLocations;
        const geography = get().geography;

        set({
          metMastLocations,
          currentSimulationErrors: [],
          ...deriveStateAfterUpload(
            turbineLocations,
            metMastLocations,
            geography?.centerLng,
            geography?.centerLat,
            geography?.width,
            geography?.height,
            true,
            recalculateBounds
          ),
        });
      },

      turbineLocations: undefined,
      setTurbineLocations: async (
        turbineLocations?: SimulationTurbineList,
        recalculateBounds = false
      ) => {
        await useTurbineStore.getState().fetch();

        const turbineTypes = useTurbineStore
          .getState()
          .turbines.map((t) => formatTurbineForSimulation(t));
        const availableTurbineSlugs = turbineTypes.map((t) => t.slug);
        let currentSimulationErrors: string[] = [];

        const { unknownTurbineTypes, usedTurbineSlugs } =
          filterTurbineLocations(turbineLocations || [], availableTurbineSlugs);

        const usedTurbineTypes = usedTurbineSlugs
          .map((slug) => {
            const turbineType = turbineTypes.find((t) => t.slug === slug);
            return turbineType;
          })
          .filter((t) => t !== undefined) as Turbine[];

        if (unknownTurbineTypes.length > 0) {
          currentSimulationErrors.push(
            `Some unknown turbine types were uploaded.  We have excluded them from this simulation: ${unknownTurbineTypes.join(
              ", "
            )}`
          );
        }

        const geography = get().geography;
        const metMastLocations = get().metMastLocations;
        set({
          turbineTypes: usedTurbineTypes,
          currentSimulationErrors,
          ...deriveStateAfterUpload(
            turbineLocations,
            metMastLocations,
            geography?.centerLng,
            geography?.centerLat,
            geography?.width,
            geography?.height,
            true,
            recalculateBounds
          ),
        });
      },

      geography: undefined,

      /* Updates the `geography` state with the new coordinates but does not touch
       the actual `currentSimulation` */
      setSimulationCoordinates: (
        centerLng: number,
        centerLat: number,
        width: number,
        height: number
      ) => {
        console.debug("setSimulationCoordinates");
        const turbineLocations = get().turbineLocations;
        const metMastLocations = get().metMastLocations;

        const geography = generateSimulationGeography(
          centerLng,
          centerLat,
          width,
          height
        );

        set({
          geography,
          ...deriveStateAfterUpload(
            turbineLocations,
            metMastLocations,
            centerLng,
            centerLat,
            width,
            height,
            false,
            false
          ),
        });
      },

      outOfBoundsCount: 0,

      fetch: async (forceRefetch = false) => {
        try {
          if (get().loaded && !forceRefetch) {
            return;
          }
          const simulations = await persist.fetchSimulations();
          set({ simulations, loaded: true });
        } catch (error) {
          console.error(error);
        }
      },

      newSimulation: (forEditing?: boolean) => {
        try {
          const newSimulation: Partial<Simulation> = {
            __typename: "Simulation",
            privacy: Privacy.PRIVATE,
            status: SimulationStatus.DRAFT,
            name: "",
            centerLat: 35.88,
            centerLng: -106.3,
            width: 100,
            height: 100,
            resolution: 2000,
            typicalMeteorologicalYear: true,
            useTurbineLocationsAsMetMasts: true,
            modelWakes: true,
            turbineLocations: undefined,
            metMastLocations: undefined,
            turbineTypes: undefined,
            projectId: undefined,
            wrgHeights: undefined,
            startDate: "",
            endDate: "",
          };

          get().setCurrentSimulation(newSimulation, forEditing);
        } catch (error) {
          console.error(error);
        }
      },

      saveSimulation: async (
        params: Partial<Simulation>
      ): Promise<Partial<Simulation>> => {
        try {
          const simulation = await persist.saveSimulation(params);

          let simulations = get().simulations;
          // add or update in state
          if (!simulations.find((s) => s.id === simulation.id)) {
            simulations = [simulation, ...simulations] as Simulation[];
          } else {
            simulations = simulations.map((s) =>
              s.id === simulation.id ? simulation : s
            );
          }

          if (
            simulation.domainId &&
            simulation.domainId?.length > 0 &&
            simulation.centerLng &&
            simulation.centerLat &&
            simulation.width &&
            simulation.height &&
            simulation.resolution
          ) {
            console.log("updateDomainCoordinates for related");
            await persist.updateDomainCoordinates(
              simulation.domainId,
              simulation.centerLng,
              simulation.centerLat,
              simulation.width,
              simulation.height,
              simulation.resolution
            );
          }

          set(() => ({
            currentSimulation: simulation,
            simulations,
            currentSimulationErrors: [],
          }));
          return simulation;
        } catch (error: any) {
          set(() => ({
            currentSimulationErrors: [error.toString()],
          }));
          return params;
        }
      },

      deleteSimulation: async (simulationId: string) => {
        try {
          await persist.deleteCurrentSimulation(simulationId);
          set((state) => ({
            simulations: state.simulations.filter((t) => t.id !== simulationId),
          }));
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },

      submitSimulation: async (simulationId: string) => {
        console.log("submitSimulation in store", simulationId);
        try {
          const success = await persist.submitSimulation(simulationId);
          if (success) {
            const simulation = await persist.retrieveSimulation(simulationId);
            if (!simulation) {
              throw new Error("Could not retrieve simulation during submit");
            }
            set((state) => ({
              simulations: state.simulations.map((s) =>
                s.id === simulation.id ? simulation : s
              ),
            }));
            return true;
          }
          return false;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      regenerateBounds: () => {
        const turbineLocations = get().turbineLocations;
        const metMastLocations = get().metMastLocations;
        const geography = get().geography;

        set({
          ...deriveStateAfterUpload(
            turbineLocations,
            metMastLocations,
            geography?.centerLng,
            geography?.centerLat,
            geography?.width,
            geography?.height,
            true,
            true
          ),
        });
      },
    }),
    { name: "simulationStore" }
  )
);

export default useSimulationStore;
