import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Simulation } from "../../API";
import { SimulationLibraryItem } from "./SimulationLibraryItem";
import sortSimulationsByName from "./sortSimulationsByName";

interface Props {
  isFiltered: boolean;
  simulations: Simulation[];
}

function GroupedSimulations(props: Omit<Props, "isFiltered">) {
  const simulations = sortSimulationsByName(props.simulations);
  return (
    <Box>
      {simulations.map((simulation: Simulation) => (
        <Box key={simulation.slug}>
          <Divider />

          <SimulationLibraryItem simulation={simulation} />
        </Box>
      ))}
    </Box>
  );
}

export default function SimulationLibraryTable(props: Props) {
  const { simulations, isFiltered } = props;

  const simulationsBySet = useMemo<Record<string, Simulation[]>>(() => {
    const byDomain: any = {};
    simulations.forEach((simulation: Simulation) => {
      const { domain } = simulation;

      if (!domain) {
        byDomain["Simulations"] = byDomain["Simulations"] || [];
        byDomain["Simulations"].push(simulation);
      } else {
        byDomain[domain.name] = byDomain[domain.name] || [];
        byDomain[domain.name].push(simulation);
      }
    });
    return byDomain;
  }, [simulations]);

  let index: number[] | undefined = undefined;
  if (isFiltered) {
    // show all projects if filtered
    index = Array(Object.keys(simulationsBySet).length)
      .fill(0)
      .map((_, i) => i);
  }

  return (
    <Accordion allowMultiple index={index}>
      {Object.keys(simulationsBySet).map((projectName) => {
        return (
          <AccordionItem key={projectName}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" pl={2}>
                  {projectName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel px={0} pb={4}>
              <GroupedSimulations simulations={simulationsBySet[projectName]} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
