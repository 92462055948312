import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

interface Props {
  onSetAppSection: (section: string) => void;
  appSection: string;
  signOut: () => void;
}

export default function AppBody(_props: Props) {
  return (
    <Box
      position="absolute"
      height="100%"
      width="100%"
      zIndex={2}
      left={0}
      top={0}
      pointerEvents={"none"}
      overflowX={"auto"}
    >
      <Outlet />
    </Box>
  );
}
