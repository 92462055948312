import mapboxgl, { MapMouseEvent } from "mapbox-gl";

export class RenderCoordinatesControl implements mapboxgl.IControl {
  _map: mapboxgl.Map | undefined;
  _container: HTMLElement | undefined;

  onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl map-coordinates-control";

    this._map.on("moveend", () => {
      this.update();
    });

    this._map.on("mousemove", (e: MapMouseEvent) => {
      this.update(e.lngLat);
    });

    this._map.on("zoomend", () => {
      this.update();
    });

    this.update();
    return this._container;
  }

  onRemove() {
    if (this._container) {
      this._container.parentNode?.removeChild(this._container);
    }
    this._map = undefined;
  }

  update(lngLat?: mapboxgl.LngLat) {
    if (!this._map) return;
    if (!this._container) return;

    if (!lngLat) {
      lngLat = this._map.getCenter();
    }

    this._container.innerHTML = `[ ${lngLat.lat.toFixed(
      3
    )}, ${lngLat.lng.toFixed(3)} ] @ ${this._map.getZoom().toFixed(2)} zoom`;
  }
}
