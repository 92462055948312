import { Box, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import SubmitWithConfirmButton from "../Simulations/SubmitWithConfirmButton";
import {
  canDeleteSimulation,
  canSubmitSimulation,
} from "../Simulations/simulationStates";
import { useNavigate } from "react-router-dom";
interface Props {
  isSaving: boolean;
  onCancel: () => void;
  saving: boolean;
  currentSimulation: any;
  onDelete: () => void;
  isDirty?: boolean;
}

export default function FormButtons(props: Props) {
  const { isSaving, isDirty, onCancel, saving, currentSimulation } = props;
  const navigate = useNavigate();

  const busy = isSaving;
  return (
    <>
      <HStack spacing={4} alignItems={"flex-start"}>
        <Box flex={1}>
          <Button type="submit" disabled={busy}>
            Save {saving ? <Spinner ml={6} /> : null}
          </Button>
        </Box>
        <Box flex={1}>
          <Button disabled={busy} onClick={onCancel}>
            Close
          </Button>
        </Box>

        {canSubmitSimulation(currentSimulation) && (
          <Box flex={3}>
            <SubmitWithConfirmButton
              key="submit"
              size="md"
              variant="solid"
              disabled={busy || isDirty}
              simulationId={currentSimulation.id}
              onSuccess={() => {
                navigate("/simulations");
              }}
            />
          </Box>
        )}
      </HStack>

      {canDeleteSimulation(currentSimulation) && (
        <Box my={4}>
          <Button
            disabled={busy}
            colorScheme="red"
            size="xs"
            variant={"text"}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this simulation?"
                )
              ) {
                props.onDelete();
              }
            }}
          >
            Delete
          </Button>
        </Box>
      )}

      {currentSimulation && currentSimulation.updatedAt && (
        <Box my={4}>
          <Text fontSize="xs">Saved: {currentSimulation.updatedAt}</Text>
        </Box>
      )}
    </>
  );
}
