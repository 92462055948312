import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { MouseEvent } from "react";
import { PowerCurve } from "../Turbines/generatePowerCurveJSON";
import PowerThrustModal from "../PowerThrustModal";

interface Props {
  getRemoveFileProps: () => {
    onClick: (event: MouseEvent) => void;
  };
  ProgressBar: any;
  Remove: any;
  clearUpload: () => void;
  powerCurve: PowerCurve;
}

function SpotCheck(props: { uploaded: PowerCurve; rowLimit: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { uploaded, rowLimit } = props;
  if (!uploaded) return null;

  return (
    <Box>
      <Button size="xs" colorScheme="teal" variant="outline" onClick={onOpen}>
        Spot Check
      </Button>

      <PowerThrustModal
        isOpen={isOpen}
        onClose={onClose}
        powerCurve={uploaded}
        rowLimit={rowLimit}
      />
    </Box>
  );
}

function PowerCurveUploaderResults(props: Props) {
  const { getRemoveFileProps, ProgressBar, powerCurve } = props;
  const clearUpload = (event: MouseEvent) => {
    getRemoveFileProps().onClick(event);
    props.clearUpload();
  };
  return (
    <Box>
      <ProgressBar />

      {powerCurve && powerCurve.length > 0 ? (
        <>
          <Box>
            <Text fontSize="xs">
              {powerCurve.length} power/curve data points have been uploaded.
            </Text>
          </Box>

          <HStack mt={4} gap={4}>
            <SpotCheck uploaded={powerCurve} rowLimit={20} />
            <Button
              size="xs"
              colorScheme="red"
              variant="outline"
              onClick={clearUpload}
            >
              Remove Power/Thrust Data
            </Button>
          </HStack>
        </>
      ) : null}
    </Box>
  );
}

export default PowerCurveUploaderResults;
