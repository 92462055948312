import { Simulation } from "../../API";

export default function (
  simulations: Simulation[],
  search?: string
): Simulation[] {
  return simulations.filter((simulation: Simulation) => {
    if (!search || search.length < 1) return true;

    const kw = search.toLowerCase();
    return (
      (simulation.slug && simulation.slug.toLowerCase().includes(kw)) ||
      (simulation.name && simulation.name.toLowerCase().includes(kw))
    );
  });
}
