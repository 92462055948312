/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    slug
    name
    privacy
    simulations {
      nextToken
      __typename
    }
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      name
      privacy
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getSimulationSet =
  /* GraphQL */ `query GetSimulationSet($id: ID!) {
  getSimulationSet(id: $id) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSimulationSetQueryVariables,
    APITypes.GetSimulationSetQuery
  >;
export const listSimulationSets = /* GraphQL */ `query ListSimulationSets(
  $filter: ModelSimulationSetFilterInput
  $limit: Int
  $nextToken: String
) {
  listSimulationSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSimulationSetsQueryVariables,
  APITypes.ListSimulationSetsQuery
>;
export const getDomain = /* GraphQL */ `query GetDomain($id: ID!) {
  getDomain(id: $id) {
    id
    slug
    name
    centerLng
    centerLat
    width
    height
    resolution
    privacy
    status
    simulations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDomainQueryVariables, APITypes.GetDomainQuery>;
export const listDomains = /* GraphQL */ `query ListDomains(
  $filter: ModelDomainFilterInput
  $limit: Int
  $nextToken: String
) {
  listDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDomainsQueryVariables,
  APITypes.ListDomainsQuery
>;
export const getSimulation = /* GraphQL */ `query GetSimulation($id: ID!) {
  getSimulation(id: $id) {
    id
    domainId
    domain {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    slug
    name
    centerLat
    centerLng
    resolution
    width
    height
    bounds
    area
    gridCellCount
    typicalMeteorologicalYear
    startDate
    endDate
    wrgHeights
    estimatedPrice
    estimatedPriceBreakdown
    privacy
    projectId
    project {
      id
      slug
      name
      privacy
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    turbineLocations
    turbineTypes
    turbineCount
    useTurbineLocationsAsMetMasts
    modelWakes
    metMastLocations
    metMastCount
    status
    statusMessage
    s3Bucket
    s3Prefix
    outputs
    submitterInfo
    submitterEmail
    submittedAt
    startedAt
    completedAt
    deletedAt
    createdAt
    updatedAt
    projectSimulationsId
    simulationSetSimulationsId
    domainSimulationsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSimulationQueryVariables,
  APITypes.GetSimulationQuery
>;
export const listSimulations = /* GraphQL */ `query ListSimulations(
  $filter: ModelSimulationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSimulations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      domainId
      slug
      name
      centerLat
      centerLng
      resolution
      width
      height
      bounds
      area
      gridCellCount
      typicalMeteorologicalYear
      startDate
      endDate
      wrgHeights
      estimatedPrice
      estimatedPriceBreakdown
      privacy
      projectId
      turbineLocations
      turbineTypes
      turbineCount
      useTurbineLocationsAsMetMasts
      modelWakes
      metMastLocations
      metMastCount
      status
      statusMessage
      s3Bucket
      s3Prefix
      outputs
      submitterInfo
      submitterEmail
      submittedAt
      startedAt
      completedAt
      deletedAt
      createdAt
      updatedAt
      projectSimulationsId
      simulationSetSimulationsId
      domainSimulationsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSimulationsQueryVariables,
  APITypes.ListSimulationsQuery
>;
export const getTurbine = /* GraphQL */ `query GetTurbine($id: ID!) {
  getTurbine(id: $id) {
    id
    slug
    description
    status
    privacy
    rotorDiameter
    ratedPower
    powerCurve
    deletedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTurbineQueryVariables,
  APITypes.GetTurbineQuery
>;
export const listTurbines = /* GraphQL */ `query ListTurbines(
  $filter: ModelTurbineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTurbines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      description
      status
      privacy
      rotorDiameter
      ratedPower
      powerCurve
      deletedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTurbinesQueryVariables,
  APITypes.ListTurbinesQuery
>;
export const simulationSetsBySlug = /* GraphQL */ `query SimulationSetsBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSimulationSetFilterInput
  $limit: Int
  $nextToken: String
) {
  simulationSetsBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SimulationSetsBySlugQueryVariables,
  APITypes.SimulationSetsBySlugQuery
>;
export const domainsBySlug = /* GraphQL */ `query DomainsBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDomainFilterInput
  $limit: Int
  $nextToken: String
) {
  domainsBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      centerLng
      centerLat
      width
      height
      resolution
      privacy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DomainsBySlugQueryVariables,
  APITypes.DomainsBySlugQuery
>;
export const simulationsBySlug = /* GraphQL */ `query SimulationsBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSimulationFilterInput
  $limit: Int
  $nextToken: String
) {
  simulationsBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      domainId
      slug
      name
      centerLat
      centerLng
      resolution
      width
      height
      bounds
      area
      gridCellCount
      typicalMeteorologicalYear
      startDate
      endDate
      wrgHeights
      estimatedPrice
      estimatedPriceBreakdown
      privacy
      projectId
      turbineLocations
      turbineTypes
      turbineCount
      useTurbineLocationsAsMetMasts
      modelWakes
      metMastLocations
      metMastCount
      status
      statusMessage
      s3Bucket
      s3Prefix
      outputs
      submitterInfo
      submitterEmail
      submittedAt
      startedAt
      completedAt
      deletedAt
      createdAt
      updatedAt
      projectSimulationsId
      simulationSetSimulationsId
      domainSimulationsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SimulationsBySlugQueryVariables,
  APITypes.SimulationsBySlugQuery
>;
