import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { SimulationTurbineList } from "../TurbineLocationUploader/helpers";
import TurbineLocationsModal from "../TurbineLocationsModal";

type Props = {
  turbineLocations: SimulationTurbineList | undefined;
};

export default function TurbineLocations(props: Props) {
  const { turbineLocations } = props;
  if (!turbineLocations || turbineLocations.length < 1) return null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button size="sm" colorScheme="teal" onClick={onOpen}>
        Turbine Positions ({turbineLocations.length})
      </Button>
      <TurbineLocationsModal
        isOpen={isOpen}
        onClose={onClose}
        rowLimit={20}
        turbineLocations={turbineLocations}
      />
    </Box>
  );
}
