import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { FieldError, FieldErrors, UseFormRegister } from "react-hook-form";
import { SimulationFormValues } from "../Simulations/types";
import { toDateString } from "../util";
interface Props {
  errors: FieldErrors<SimulationFormValues>;
  register: UseFormRegister<SimulationFormValues>;
}

/*
  Date Range: TMI (Typical Meteorological Year) or Start and
  End YYYY-MM-DD in UTC (min 2000-01-01 to two weeks ago)
*/

const MIN_DATE = new Date(2000, 1, 1);
const MAX_DATE = new Date();
MAX_DATE.setDate(MAX_DATE.getDate() - 14);

const DEFAULT_START_DATE = new Date();
DEFAULT_START_DATE.setDate(MAX_DATE.getDate() - 365);
const DEFAULT_END_DATE = MAX_DATE;

type DateInputProps = Pick<Props, "register"> & {
  name: keyof SimulationFormValues;
  error?: FieldError;
};

function validateBeforeAfter(
  _: any,
  formValues: Partial<SimulationFormValues>
) {
  if (!formValues.startDate || !formValues.endDate) return true;
  if (formValues.startDate > formValues.endDate) {
    return "Start date must be before end date";
  }
  return true;
}

function DateInput(props: DateInputProps) {
  const { name, error, register } = props;
  return (
    <FormControl isInvalid={!!error}>
      <Input
        type="date"
        min={toDateString(MIN_DATE)}
        max={toDateString(MAX_DATE)}
        {...register(name, {
          min: toDateString(MIN_DATE),
          max: toDateString(MAX_DATE),
          validate: {
            validateBeforeAfter: validateBeforeAfter,
          },
        })}
      />
      {error && (
        <FormErrorMessage className="error">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
}

export default function SimulationTimeForm(props: Props) {
  const { register, errors } = props;

  return (
    <Flex width="100%" gap={4}>
      <Box flex={1}>
        <DateInput
          name="startDate"
          register={register}
          error={errors.startDate}
        />
      </Box>

      <Box flex={1}>
        <DateInput name="endDate" register={register} error={errors.endDate} />
      </Box>
    </Flex>
  );
}

export { DEFAULT_END_DATE, DEFAULT_START_DATE };
