import { GridCellData, formatGridId } from "../../../endpoints/getCellData";

/** Format the grid cell popup by combining GeoJSON property data from Mapbox
 *  with additional data from S3 that we get via getCellData API calls
 */
export default function formatWindResourcePopup(
  cellData: GridCellData,
  properties: Record<string, any>
) {
  const gridId = formatGridId(properties.id);
  const sgid = properties.sgid
    ? `<div><b>Storage ID:</b> ${formatGridId(properties.sgid)}`
    : null;
  const downloadList = cellData.downloads.map((d: any) => {
    const label = properties.sgid || properties.id;
    return `<li><a href="${d.url}" target="_blank" download="${label}-${d.path}">${label}-${d.path}</a></li>`;
  });

  const downloads = downloadList.length
    ? `<div><h4>Timeseries Downloads</h4><ul>${downloadList.join()}</ul></div>`
    : "";

  return `
    <div>
      <div><b>Veer ID:</b> ${gridId}</div>
      ${sgid}
      <div><b>Wind Speed:</b> ${properties.wsaf} m/s</div>
      <br/>
      ${downloads}
      <div><h4>Stats Visualizations</h4><p>coming soon!</p></div>
      </div>
  `;
}
