interface InitialCoordinates {
  zoom: number;
  lng: number;
  lat: number;
}

// parse the map view out of a location.hash like `#4.53/-102.726/36.668`
export default function getInitialCoordinates(): InitialCoordinates {
  if (document.location.hash && document.location.hash.length > 0) {
    const parts = document.location.hash.replace("#", "").split("/");

    if (parts.length === 3) {
      return {
        zoom: parseFloat(parts[0]),
        lng: parseFloat(parts[1]),
        lat: parseFloat(parts[2]),
      };
    }
  }

  return {
    zoom: 3,
    lng: -90,
    lat: 43,
  };
}
