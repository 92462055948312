import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Divider,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { Domain, DomainStatus, Simulation } from "../../API";
import { FormSelect, FormTextInput } from "../FormControls";
import {
  SimulationGeography,
  calculateGridCellCount,
} from "../Map/lib/generateSimulationGeography";
import useMapStore from "../Map/mapStore";
import MetMastsUploader from "../MetMastUploader";
import SectionHeading from "../SectionHeading";
import SidebarLeft from "../SidebarLeft";
import SimulationGridCellCount from "../Simulations/SimulationGridCellCount";
import SimulationPrice from "../Simulations/SimulationPrice";
import useDomainStore from "../Simulations/domainStore";
import {
  canEditCoordinates,
  shouldShowSimulationPriceEstimate,
} from "../Simulations/simulationStates";
import useSimulationStore from "../Simulations/simulationStore";
import { SimulationFormValues } from "../Simulations/types";
import TurbineLocationsUploader from "../TurbineLocationUploader";
import { toDateString } from "../util";
import FormButtons from "./FormButtons";
import OutOfBoundsWarning from "./OutOfBoundsWarning";
import SimulationDomain from "./SimulationDomain";
import SimulationTimeForm, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from "./SimulationTimeForm";
import WrgHeights from "./WrgHeights";
import SubmitterInfo from "../SimulationResults/SubmitterInfo";
import OutOfAvailableRegionsWarning from "./OutOfAvailableRegionsWarning";

export default function SimulationForm() {
  const {
    geography,
    saveSimulation,
    deleteSimulation,
    turbineLocations,
    metMastLocations,
    turbineTypes: simulationTurbineTypes,
    currentSimulation,
    currentSimulationErrors,
    setCurrentSimulation,
    loadCurrentSimulation,
    newSimulation,
    setSimulationCoordinates,
    setLockedCoordinates,
  } = useSimulationStore();
  const { fetch: fetchDomains, domains } = useDomainStore();

  const { repositionMap } = useMapStore();

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [currentDomain, setCurrentDomain] = useState<Domain | undefined>();

  // get the AWS Cognito user
  const { user } = useAuthenticator();
  const submitterEmail = user.attributes?.email;

  const { simulationSlug } = useParams<{ simulationSlug: string }>();

  useEffect(() => {
    return () => {
      setCurrentSimulation(undefined);
    };
  }, [setCurrentSimulation]);

  useEffect(() => {
    async function load() {
      await fetchDomains();
      if (simulationSlug) {
        await loadCurrentSimulation(simulationSlug, true);
      } else {
        newSimulation(true);
      }
    }
    load();
  }, [simulationSlug, loadCurrentSimulation]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,

    formState: {
      errors,
      isSubmitting,
      isDirty,
      isSubmitSuccessful,
      dirtyFields,
    },
  } = useForm<SimulationFormValues>({
    mode: "onBlur",
    defaultValues: getInitialValues(),
  });

  console.log(JSON.stringify({ isDirty, isSubmitSuccessful, dirtyFields }));

  useEffect(() => {
    reset(getInitialValues());
    setCurrentDomain(currentSimulation?.domain || undefined);
  }, [currentSimulation, reset, domains]);

  useEffect(() => {
    // reset form state after successful submit
    if (isSubmitSuccessful) {
      console.log("isSubmitSuccessful reset", isSubmitSuccessful);
      reset(getInitialValues());
    }
  }, [currentSimulation, reset, domains, isSubmitSuccessful]);

  const onCancel = () => {
    setCurrentSimulation(undefined);
    navigate("/simulations");
  };

  const onDelete = async () => {
    if (!currentSimulation || !currentSimulation.id) return;
    setSaving(true);
    deleteSimulation(currentSimulation.id);
    setCurrentSimulation(undefined);
    navigate("/simulations");
  };

  function renderErrors() {
    if (currentSimulationErrors.length < 1) return null;

    return currentSimulationErrors.map((error, index) => (
      <Alert status="error" key={index}>
        <AlertIcon />
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    ));
  }

  function getInitialValues() {
    if (!currentSimulation) return {};

    const geographyValues: Partial<SimulationGeography> = geography || {};

    const values = {
      name: currentSimulation.name,
      resolution: currentSimulation.resolution,
      domainId: currentSimulation.domainId || null,
      useTurbineLocationsAsMetMasts:
        currentSimulation.useTurbineLocationsAsMetMasts,
      modelWakes: currentSimulation.modelWakes,
      typicalMeteorologicalYear: currentSimulation.typicalMeteorologicalYear,
      startDate: currentSimulation.startDate
        ? currentSimulation.startDate
        : toDateString(DEFAULT_START_DATE),
      endDate: currentSimulation.endDate
        ? currentSimulation.endDate
        : toDateString(DEFAULT_END_DATE),
      wrgHeights: JSON.parse(currentSimulation.wrgHeights || "[]"),
      submitterEmail: submitterEmail,
      turbineLocations: currentSimulation.turbineLocations,
      metMastLocations: currentSimulation.metMastLocations,
      ...geographyValues,
    } as SimulationFormValues;
    console.log("getInitialValues", values);
    return values;
  }

  const watchTypicalMeteorologicalYear = watch(
    "typicalMeteorologicalYear",
    true
  );
  const watchResolution = watch("resolution");
  const area = geography?.area || 0;
  const watchDomainId = watch("domainId");

  // handle changing domain
  useEffect(() => {
    const ss = domains.find((ss) => ss.id === watchDomainId);
    setCurrentDomain(ss);

    if (!ss) return;

    setSimulationCoordinates(
      ss.centerLng!,
      ss.centerLat!,
      ss.width!,
      ss.height!
    );

    if (ss.centerLat)
      setValue("centerLat", ss.centerLat, { shouldDirty: true });
    if (ss.centerLng)
      setValue("centerLng", ss.centerLng, { shouldDirty: true });
    if (ss.width) setValue("width", ss.width, { shouldDirty: true });
    if (ss.height) setValue("height", ss.height, { shouldDirty: true });
    if (ss.resolution)
      setValue("resolution", ss.resolution, { shouldDirty: true });
  }, [watchDomainId, domains]);

  useEffect(() => {
    if (turbineLocations) {
      setValue("turbineLocations", JSON.stringify(turbineLocations), {
        shouldDirty: true,
      });
    }
  }, [turbineLocations]);

  useEffect(() => {
    setValue("metMastLocations", JSON.stringify(metMastLocations), {
      shouldDirty: true,
    });
  }, [metMastLocations]);

  // reposition the map when any bounding box coordinates change
  useEffect(() => {
    if (!geography) return;
    setValue("centerLat", geography.centerLat, { shouldDirty: true });
    setValue("centerLng", geography.centerLng, { shouldDirty: true });
    setValue("width", geography.width, { shouldDirty: true });
    setValue("height", geography.height, { shouldDirty: true });
    repositionMap();
  }, [geography, repositionMap]);

  useEffect(() => {
    let editableCoordinates = currentSimulation
      ? canEditCoordinates(currentSimulation)
      : true;

    if (currentDomain && currentDomain?.status !== DomainStatus.EDITABLE) {
      editableCoordinates = false;
    }

    setLockedCoordinates(!editableCoordinates);
  }, [currentSimulation, currentDomain, setLockedCoordinates]);

  const gridCellCount = useMemo(() => {
    return calculateGridCellCount(area, watchResolution);
  }, [area, watchResolution]);

  const saveForm = async (values: Partial<SimulationFormValues>) => {
    setSaving(true);

    try {
      const wrgHeights = new Set(values.wrgHeights || []);

      const typicalMeteorologicalYear =
        values.typicalMeteorologicalYear?.toString() === "true";

      const startDate =
        !typicalMeteorologicalYear && values.startDate
          ? values.startDate
          : undefined;
      const endDate =
        !typicalMeteorologicalYear && values.endDate
          ? values.endDate
          : undefined;

      const passed = {
        id: currentSimulation?.id,
        slug: currentSimulation?.slug,
        name: values.name,
        typicalMeteorologicalYear: typicalMeteorologicalYear,
        useTurbineLocationsAsMetMasts:
          values.useTurbineLocationsAsMetMasts?.toString() === "true",
        modelWakes: values.modelWakes?.toString() === "true",
        resolution: values.resolution,
        domainId: values.domainId,
        startDate: startDate,
        endDate: endDate,
        gridCellCount: gridCellCount,
        submitterEmail: submitterEmail,
        submitterInfo: JSON.stringify(user.attributes || {}),
        estimatedPrice: values.estimatedPrice,
        estimatedPriceBreakdown: values.estimatedPriceBreakdown,
      } as Partial<Simulation>;

      if (currentSimulation?.owner === user.attributes?.sub) {
        passed.submitterInfo = JSON.stringify(user.attributes);
      }

      if (wrgHeights.size > 0) {
        passed.wrgHeights = JSON.stringify(Array.from(wrgHeights));
      } else {
        passed.wrgHeights = null;
      }

      if (turbineLocations) {
        passed.turbineLocations = JSON.stringify(turbineLocations);
        passed.turbineCount = turbineLocations.length;
      }

      if (metMastLocations) {
        passed.metMastLocations = JSON.stringify(metMastLocations);
        passed.metMastCount = metMastLocations.length;
        metMastLocations.length;
      }

      if (passed.typicalMeteorologicalYear) {
        passed.startDate = null;
        passed.endDate = null;
      }

      if (simulationTurbineTypes) {
        passed.turbineTypes = JSON.stringify(simulationTurbineTypes);
      }

      if (geography) {
        passed.centerLat = geography.centerLat;
        passed.centerLng = geography.centerLng;
        passed.width = geography.width;
        passed.height = geography.height;
        passed.area = geography.area;
      }

      const saved = await saveSimulation(passed);
      console.debug("SimulationForm.saveForm", passed, saved);
      if (!saved || !saved.id) return;
      navigate(`/simulations/${saved.slug}/edit`);
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const typicalMeteorologicalYearOptions = [
    { value: true, label: "Typical Meteorological Year" },
    { value: false, label: "Custom Dates" },
  ];

  if (!currentSimulation) return null;

  function handleSelectDomain(domainId: string | null) {
    setValue("domainId", domainId);
  }

  function renderSubmitterInfo() {
    if (!currentSimulation?.submitterInfo) return null;
    const submitterInfo = JSON.parse(currentSimulation.submitterInfo) as Record<
      string,
      string
    >;
    return <SubmitterInfo submitterInfo={submitterInfo} />;
  }

  return (
    <Box>
      <SidebarLeft>
        <Box overflowY={"auto"} height={"91vh"}>
          <Box mt={6} px={6} pb={12}>
            <SectionHeading
              title="Define a Simulation"
              closeTo="/simulations"
            />

            <form onSubmit={handleSubmit(saveForm)}>
              <VStack spacing={12}>
                {renderErrors()}

                {renderSubmitterInfo()}

                <FormTextInput
                  id="simulation-name"
                  name="name"
                  label="Name"
                  error={errors.name}
                  register={register}
                  registerOptions={{
                    required: "Required",
                    maxLength: {
                      value: 100,
                      message: "Must be 100 characters or less",
                    },
                  }}
                />

                <SimulationDomain
                  domains={domains}
                  currentDomain={currentDomain}
                  currentResolution={watchResolution}
                  handleSelectDomain={handleSelectDomain}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  editing={!currentSimulation?.id}
                />
                <Box width="100%" textAlign={"left"}>
                  <Box
                    mb={6}
                    pb={2}
                    borderWidth={"0 0 1px"}
                    borderStyle="solid"
                    borderColor={"cyan.300"}
                  >
                    <Heading size="md">Parameters</Heading>
                  </Box>

                  <VStack spacing={8} align="left">
                    <FormSelect
                      id="simulation-typical-meteorological-year"
                      name="typicalMeteorologicalYear"
                      label="Timeframe"
                      options={typicalMeteorologicalYearOptions}
                      register={register}
                    />
                    {watchTypicalMeteorologicalYear.toString() ===
                    "true" ? null : (
                      <SimulationTimeForm register={register} errors={errors} />
                    )}

                    <OutOfBoundsWarning />

                    <TurbineLocationsUploader
                      turbineLocations={turbineLocations}
                      register={register}
                    />

                    <Divider />

                    <MetMastsUploader metMastLocations={metMastLocations} />
                    <Divider />

                    <WrgHeights register={register} watch={watch} />
                    <Divider />

                    <SimulationGridCellCount
                      gridCellCount={gridCellCount}
                      resolution={watchResolution}
                    />

                    <OutOfAvailableRegionsWarning />

                    {shouldShowSimulationPriceEstimate(currentSimulation) && (
                      <SimulationPrice
                        watch={watch}
                        setValue={setValue}
                        gridCellCount={gridCellCount}
                      />
                    )}
                  </VStack>
                </Box>
              </VStack>

              <Box mt={12}>
                <FormButtons
                  {...{
                    isSaving: isSubmitting,
                    isDirty,
                    onCancel,
                    saving,
                    currentSimulation,
                    onDelete,
                  }}
                />
              </Box>
            </form>
          </Box>
        </Box>
      </SidebarLeft>
    </Box>
  );
}
