import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import { FiEye } from "react-icons/fi";
import { Turbine } from "../../API.ts";
import PrivacyBadge from "../PrivacyBadge.tsx";
import {
  canDeleteTurbine,
  canEditTurbine,
  canShowTurbine,
} from "./turbineStates.ts";
import useTurbineStore from "./turbineStore.ts";

const CONFIRM_DELETE = `Are you sure you want to delete this turbine?

Any simulations that have been running using this turbine will be unaffected.

However, you won't be able to use this turbine in any new simulations.`;

type Props = {
  turbine: Turbine;
  onEditClick: (turbine: Turbine) => void;
  onShowClick: (turbine: Turbine) => void;
};

export default function TurbineLibraryItem(props: Props) {
  const { turbine, onEditClick, onShowClick } = props;
  const deleteTurbine = useTurbineStore((state) => state.deleteTurbine);

  const onDeleteClick = () => {
    if (window.confirm(CONFIRM_DELETE)) {
      deleteTurbine(turbine.id);
    }
  };

  const handleDefaultClick = () => {
    if (canShowTurbine(turbine)) {
      onShowClick(turbine);
    } else if (canEditTurbine(turbine)) {
      onEditClick(turbine);
    }
  };

  return (
    <Flex direction="row" mt={4} onClick={handleDefaultClick} cursor="pointer">
      <Box flex={1} pl={10}>
        {turbine.description && <Box>{turbine.description}</Box>}
        <Box mt={0} mb={2} fontSize={"sm"} color={"gray.500"} as="code">
          <Text>{turbine.slug}</Text>
        </Box>

        <HStack gap={2} mt={3} mb={3}>
          <PrivacyBadge privacy={turbine.privacy} />
        </HStack>
      </Box>
      <Box px={4} textAlign={"right"}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onShowClick(turbine);
          }}
          icon={<FiEye />}
          aria-label="View turbine"
          size="xs"
          variant="ghost"
          colorScheme="grey"
          hidden={!canShowTurbine(turbine)}
        />

        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onEditClick(turbine);
          }}
          icon={<EditIcon />}
          aria-label="Edit turbine"
          size="xs"
          variant="ghost"
          colorScheme="grey"
          hidden={!canEditTurbine(turbine)}
        />
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDeleteClick();
          }}
          icon={<DeleteIcon />}
          aria-label="Delete turbine"
          size="xs"
          variant="ghost"
          colorScheme="grey"
          hidden={!canDeleteTurbine(turbine)}
        />
      </Box>
    </Flex>
  );
}
