import mapboxgl from "mapbox-gl";

// roughly expand boundaries of a bounding box by a given number of kilometers
export default function expandBounds(
  bounds: mapboxgl.LngLatBounds,
  km: number
): mapboxgl.LngLatBounds {
  const approximateOffset = km / 111;

  // expand northeast by 5km
  bounds.extend([
    bounds.getNorthEast().lng + approximateOffset,
    bounds.getNorthEast().lat + approximateOffset,
  ]);

  // expand southwest by 5km
  bounds.extend([
    bounds.getSouthWest().lng - approximateOffset,
    bounds.getSouthWest().lat - approximateOffset,
  ]);
  return bounds;
}
