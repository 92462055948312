import {
  Box,
  Flex,
  HStack,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import PrivacyBadge from "../PrivacyBadge";
import SimulationActionButtons from "./SimulationActionButtons";
import SimulationStatusBadge from "./SimulationStatusBadge";
import SimulationStatusMessage from "./SimulationStatusMessage";
import { canEditSimulation, canShowSimulation } from "./simulationStates";

export const SimulationLibraryItem = memo(function SimulationLibraryItem({
  simulation,
  selected,
}: any) {
  const navigate = useNavigate();
  const selectedBgColor = useColorModeValue("gray.200", "gray.400");
  const bgColor = selected ? selectedBgColor : "transparent";

  const handleDefaultClick = () => {
    if (canShowSimulation(simulation)) {
      navigate(`/simulations/${simulation.slug}`);
    } else if (canEditSimulation(simulation)) {
      navigate(`/simulations/${simulation.slug}/edit`);
    }
  };

  return (
    <Flex
      mt={4}
      bg={bgColor}
      direction={"row"}
      onClick={handleDefaultClick}
      cursor="pointer"
    >
      <Box flex={1} pl={10}>
        <Link onClick={handleDefaultClick}>{simulation.name}</Link>

        <Box mt={0} mb={2} fontSize={"sm"} color={"gray.500"} as="code">
          <Text>{simulation.slug}</Text>
        </Box>

        <Box mt={0} fontSize={"sm"} color={"gray.500"}>
          {simulation.turbineCount > 0 && (
            <Text>{simulation.turbineCount} turbines</Text>
          )}

          {simulation.metMastCount > 0 && (
            <Text>{simulation.metMastCount} met masts</Text>
          )}
        </Box>

        <Box mt={3} mb={3}>
          <HStack gap={2}>
            <SimulationStatusBadge simulation={simulation} />
            <PrivacyBadge privacy={simulation.privacy} />
          </HStack>
          <SimulationStatusMessage simulation={simulation} />
        </Box>
      </Box>

      <Box px={4}>
        <SimulationActionButtons simulation={simulation} />
      </Box>
    </Flex>
  );
});
