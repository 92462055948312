import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex, FormControl, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { useCSVReader } from "react-papaparse";
import DownloadTemplateLink from "../Common/DownloadTemplateLink";
import { FormCheckbox } from "../FormControls";
import useSimulationStore from "../Simulations/simulationStore";
import { SimulationFormValues } from "../Simulations/types";
import useTurbineStore from "../Turbines/turbineStore";
import UploaderErrors from "../UploaderErrors";
import UploaderFileTarget from "../UploaderFileTarget";
import Results from "./Results";
import ShowTurbineOptions from "./ShowTurbineOptions";
import { SimulationTurbineList, parseCSV } from "./helpers";

interface Props {
  turbineLocations?: SimulationTurbineList;
  register: UseFormRegister<SimulationFormValues>;
}

function TurbineLocationsUploader(props: Props) {
  const { getTurbineSlugs } = useTurbineStore();
  const { CSVReader } = useCSVReader();
  const [showUploader, setShowUploader] = useState(
    props.turbineLocations && props.turbineLocations.length > 0
  );
  const { setTurbineLocations } = useSimulationStore();
  const [uploaded, setUploaded] = useState<SimulationTurbineList | undefined>(
    props.turbineLocations
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [zoneHover, setZoneHover] = useState(false);

  const showUploadUI = showUploader && (!uploaded || uploaded.length < 1);

  const clearUpload = () => {
    setUploaded(undefined);
    setTurbineLocations(undefined, false);
  };

  const onUploadAccepted = (results: any) => {
    const { turbineLocations, errors } = parseCSV(
      results.data,
      getTurbineSlugs()
    );
    if (errors.length > 0) {
      setErrors(errors);
      clearUpload();
      return;
    }

    setErrors([]);
    setUploaded(turbineLocations);
    setTurbineLocations(turbineLocations, false);
  };

  return (
    <FormControl width={"100%"}>
      <CSVReader
        onUploadAccepted={(results: any) => {
          setZoneHover(false);
          onUploadAccepted(results);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, ProgressBar, getRemoveFileProps, Remove }: any) => {
          return (
            <Box>
              <Flex>
                <Box flex={1}>
                  <Checkbox
                    mt={0}
                    onChange={() => setShowUploader(!showUploader)}
                    isChecked={showUploader}
                    colorScheme="teal"
                  >
                    Turbines
                  </Checkbox>
                  {showUploadUI && (
                    <DownloadTemplateLink href="/csv-samples/simulation-turbines-valid.csv" />
                  )}

                  {showUploadUI && <ShowTurbineOptions />}
                </Box>
                <Box>
                  <Tooltip
                    label="Optionally add turbines to the simulation"
                    fontSize="sm"
                    top={4}
                  >
                    <QuestionOutlineIcon color={"gray.200"} />
                  </Tooltip>
                </Box>
              </Flex>

              {showUploader && (
                <Box my={4}>
                  {!uploaded && (
                    <UploaderFileTarget
                      getRootProps={getRootProps}
                      hovering={zoneHover}
                      label="Add Turbines"
                    />
                  )}

                  <UploaderErrors errors={errors} />

                  {uploaded ? (
                    <Results
                      turbineLocations={uploaded}
                      clearUpload={clearUpload}
                      getRemoveFileProps={getRemoveFileProps}
                      ProgressBar={ProgressBar}
                      Remove={Remove}
                    />
                  ) : null}

                  <Box mt={6} mb={2}>
                    <FormCheckbox
                      name="useTurbineLocationsAsMetMasts"
                      label="Extract timeseries at turbine locations"
                      register={props.register}
                      value={"true"}
                    />
                  </Box>
                  <Box mt={6} mb={2}>
                    <FormCheckbox
                      name="modelWakes"
                      label="Model wakes"
                      register={props.register}
                      value={"true"}
                      helperText="De-select to remove wake effects. Power output at turbines will still be reported."
                    />
                  </Box>
                </Box>
              )}
            </Box>
          );
        }}
      </CSVReader>
    </FormControl>
  );
}
export default TurbineLocationsUploader;
