import { API } from "aws-amplify";
import gridCellHasMoreData from "../components/Map/lib/gridCellHasMoreData";

interface Params {
  simulationSlug: string;
  gridId: string;
}

export interface GridCellData {
  simulationSlug: string;
  gridId: string;
  downloads: {
    url: string;
    name: string;
    path: string;
  }[];
}

export const formatGridId = (gridId: string) => {
  // left pad with 7 zeros
  return String(gridId).padStart(7, "0");
};

const getCellData = async (params: Params): Promise<GridCellData> => {
  console.debug("getCellData api call", JSON.stringify(params));
  const apiName = "getCellData"; // as provided in 'amplify add api'

  const simulationSlug = params.simulationSlug;
  const gridId = formatGridId(String(params.gridId));
  // TODO: temporary hard coding
  // const gridId = formatGridId("338001");

  if (!gridCellHasMoreData(simulationSlug, { id: gridId })) {
    console.debug("cell has no more data", { simulationSlug, gridId });
    return {
      simulationSlug,
      gridId,
      downloads: [],
    };
  }

  const path = `/cells/${simulationSlug}/${gridId}`; // or other route you specified

  const result = await API.get(apiName, path, {
    response: true,
    responseType: "json",
  });
  return result.data.data as GridCellData;
};

export default getCellData;
