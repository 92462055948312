// @ts-expect-error
import bboxPolygon from "@turf/bbox-polygon";
// @ts-expect-error
import pointWithin from "@turf/boolean-within";
// @ts-expect-error
import { point } from "@turf/helpers";
import { SimulationTurbineList } from "../TurbineLocationUploader/helpers";
import { MetMastLocations } from "../MetMastUploader/helpers";

export default function outOfBounds(
  turbines?: SimulationTurbineList,
  metMastLocations?: MetMastLocations,
  bounds?: mapboxgl.LngLatBounds
): number {
  if (!bounds) return 0;
  if (
    (!turbines || turbines.length === 0) &&
    (!metMastLocations || metMastLocations.length === 0)
  )
    return 0;

  const boundsPolygon = bboxPolygon([
    bounds.getWest(),
    bounds.getSouth(),
    bounds.getEast(),
    bounds.getNorth(),
  ]);
  const boundsPolygonFeature = {
    type: "Feature",
    properties: {},
    geometry: boundsPolygon.geometry,
  } as GeoJSON.Feature<GeoJSON.Polygon>;

  let countWithin = 0;
  let countWithout = 0;

  (turbines || []).forEach((turbine) => {
    if (pointWithin(point([turbine[2], turbine[1]]), boundsPolygonFeature)) {
      countWithin += 1;
    } else {
      countWithout += 1;
    }
  });

  (metMastLocations || []).forEach((location) => {
    if (pointWithin(point([location[2], location[1]]), boundsPolygonFeature)) {
      countWithin += 1;
    } else {
      countWithout += 1;
    }
  });

  return countWithout;
}
