import { Privacy, Simulation, DomainStatus, SimulationStatus } from "../../API";

export function canDeleteSimulation(simulation: Partial<Simulation>): boolean {
  return canEditSimulation(simulation);
}

export function canEditSimulation(simulation: Partial<Simulation>): boolean {
  if (simulation.privacy === Privacy.PUBLIC) return false;
  if (
    simulation.status === SimulationStatus.RUNNING ||
    simulation.status === SimulationStatus.SUBMITTED ||
    simulation.status === SimulationStatus.SUCCEEDED
  ) {
    return false;
  }
  return true;
}

export function canEditCoordinates(simulation: Partial<Simulation>): boolean {
  if (simulation.privacy === Privacy.PUBLIC) return false;

  if (
    simulation.status === SimulationStatus.DRAFT ||
    simulation.status === SimulationStatus.READY_TO_SUBMIT
  ) {
    // maybe - but check simulationStatus
    const domain = simulation.domain;

    if (domain && domain.status == DomainStatus.EDITABLE) {
      return true;
    } else if (!domain) {
      return true;
    }
  }

  return false;
}

export function canSubmitSimulation(simulation: Partial<Simulation>): boolean {
  if (simulation.privacy === Privacy.PUBLIC) return false;
  if (
    simulation.status === SimulationStatus.RUNNING ||
    simulation.status === SimulationStatus.SUBMITTED ||
    simulation.status === SimulationStatus.SUCCEEDED
  )
    return false;

  return true;
}

export function canShowSimulation(simulation: Partial<Simulation>): boolean {
  if (
    simulation.status === SimulationStatus.RUNNING ||
    simulation.status === SimulationStatus.SUBMITTED ||
    simulation.status === SimulationStatus.SUCCEEDED
  )
    return true;
  return false;
}

export function shouldShowSimulationPriceEstimate(
  simulation: Partial<Simulation>
): boolean {
  if (simulation.privacy === Privacy.PUBLIC) return false;
  if (
    simulation.status === SimulationStatus.RUNNING ||
    simulation.status === SimulationStatus.SUCCEEDED
  )
    return false;
  return true;
}

export function shouldShowSimulationOutputs(
  simulation: Partial<Simulation>
): boolean {
  if (simulation.status === SimulationStatus.SUCCEEDED) return true;
  return false;
}
