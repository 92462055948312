import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Turbine } from "../../API";
import "../../App.css";
import LibraryLoading from "../LibraryLoading";
import SidebarLeft from "../SidebarLeft";
import TurbineLibrary from "./TurbineLibrary";
import useTurbineStore from "./turbineStore";

function Turbines() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { turbines, setCurrentTurbine } = useTurbineStore();
  const { fetch } = useTurbineStore();

  useEffect(() => {
    async function fetchTurbines() {
      try {
        setLoading(true);
        await fetch();
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchTurbines();
  }, []);

  const onEditClick = (turbine: Turbine) => {
    navigate(`/turbines/${turbine.slug}/edit`);
  };

  const onShowClick = (turbine: Turbine) => {
    navigate(`/turbines/${turbine.slug}`);
  };

  if (loading) {
    return (
      <SidebarLeft>
        <LibraryLoading />
      </SidebarLeft>
    );
  }

  return (
    <TurbineLibrary
      turbines={turbines}
      setCurrentTurbine={setCurrentTurbine}
      onEditClick={onEditClick}
      onShowClick={onShowClick}
    />
  );
}

export default Turbines;
