import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ThemingProps,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FiWind } from "react-icons/fi";
import DismissableAlert from "../Common/DismissableAlert";
import useSimulationStore from "./simulationStore";

function SubmitWithConfirmButton(props: {
  simulationId: string;
  disabled?: boolean;
  colorScheme?: ThemingProps<"Button">["colorScheme"];
  size?: ThemingProps<"Button">["size"];
  variant?: ThemingProps<"Button">["variant"];
  onSuccess?: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { submitSimulation } = useSimulationStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const size = props.size || "xs";
  const variant = props.variant || "ghost";

  const onSubmitSimulation = async (simulationId: string) => {
    setSubmitting(true);
    setError(null);

    const result = await submitSimulation(simulationId);
    setSubmitting(false);

    if (!result) {
      setError("Something went wrong. Please try again.");
    } else {
      setError(null);
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  };

  const onConfirm = () => {
    onSubmitSimulation(props.simulationId);
    onClose();
  };

  return (
    <Box>
      <Button
        leftIcon={<FiWind />}
        onClick={(event: any) => {
          event.stopPropagation();
          onOpen();
        }}
        aria-label="Start simulation"
        size={size}
        variant={variant}
        colorScheme={props.colorScheme}
        loadingText="Submitting..."
        isDisabled={props.disabled}
        isLoading={submitting}
      >
        Launch
      </Button>

      {error && (
        <DismissableAlert
          title="Submit Error"
          message={error}
          onClose={() => setError(null)}
        />
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Start Simulation Run
            </AlertDialogHeader>

            <AlertDialogBody>
              This will build up a cluster and queue your simulation run. Are
              you sure?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={onConfirm} ml={3}>
                Start
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
export default SubmitWithConfirmButton;
