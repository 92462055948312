import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex, FormControl, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormCheckbox } from "../FormControls";
import { POSSIBLE_WRG_HEIGHTS } from "../Simulations/simulationStore";
import { SimulationFormValues } from "../Simulations/types";

interface Props {
  register: UseFormRegister<SimulationFormValues>;
  watch: UseFormWatch<SimulationFormValues>;
}

export default function WrgHeights(props: Props) {
  const { register, watch } = props;
  const wrgHeights = watch("wrgHeights", []) as string[];

  const [expanded, setExpanded] = useState(wrgHeights.length > 0);
  useEffect(() => {
    if (wrgHeights.length > 0) {
      setExpanded(true);
    }
  }, [wrgHeights]);

  const toggleExpanded = () => {
    if (wrgHeights.length > 0) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
  };

  const wrgInputs = POSSIBLE_WRG_HEIGHTS.map((height) => {
    const key = `wrgHeights.${height}`;
    return (
      <Box flex={1} flexBasis={"50%"} key={key}>
        <FormCheckbox
          name="wrgHeights"
          label={`${height} m`}
          value={height.toString()}
          register={register}
        />
      </Box>
    );
  });

  return (
    <FormControl>
      <Flex>
        <Box flex={1}>
          <Checkbox
            onChange={toggleExpanded}
            isChecked={expanded}
            colorScheme="teal"
          >
            Extract WRGs
          </Checkbox>
        </Box>
        <Box>
          <Tooltip
            label="Optionally generate wind resource grid files at specific heights"
            fontSize="sm"
          >
            <QuestionOutlineIcon ml={2} color={"gray.200"} />
          </Tooltip>
        </Box>
      </Flex>

      {expanded && (
        <Flex direction={"row"} wrap={"wrap"} mt={4} mb={4}>
          {wrgInputs}
        </Flex>
      )}
    </FormControl>
  );
}
