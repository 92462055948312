import { Link, Icon } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

interface Props {
  href: string;
}

function DownloadTemplateLink({ href }: Props) {
  return (
    <Link
      href={href}
      color="blue.200"
      display={"inline-block"}
      mx={4}
      fontSize="xs"
      textTransform={"uppercase"}
      download
    >
      <Icon as={DownloadIcon} mr={1} />
      Template
    </Link>
  );
}

export default DownloadTemplateLink;
