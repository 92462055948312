import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { MouseEvent } from "react";
import MetMastsModal from "../MetMastsModal";
import { MetMastLocations } from "./helpers";

interface Props {
  getRemoveFileProps: () => {
    onClick: (event: MouseEvent) => void;
  };
  ProgressBar: any;
  Remove: any;
  clearUpload: () => void;
  metMastLocations: MetMastLocations;
}

export function PreviewTable(props: {
  uploaded: MetMastLocations;
  rowLimit: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { uploaded, rowLimit } = props;
  if (!uploaded) return null;

  return (
    <Box>
      <Button size="xs" colorScheme="teal" variant="outline" onClick={onOpen}>
        Spot Check
      </Button>

      <MetMastsModal
        isOpen={isOpen}
        onClose={onClose}
        rowLimit={rowLimit}
        metMastLocations={uploaded}
      />
    </Box>
  );
}

function MetMastUploaderResults(props: Props) {
  const { getRemoveFileProps, ProgressBar, metMastLocations } = props;
  const clearUpload = (event: MouseEvent) => {
    getRemoveFileProps().onClick(event);
    props.clearUpload();
  };
  return (
    <Box my={4}>
      <ProgressBar />

      {metMastLocations && metMastLocations.length > 0 ? (
        <>
          <Box>
            <Text fontSize="xs">
              {metMastLocations.length} virtual met masts have been uploaded.
            </Text>
          </Box>

          <HStack mt={4} gap={4}>
            <PreviewTable uploaded={metMastLocations} rowLimit={20} />
            <Button
              size="xs"
              colorScheme="red"
              variant="outline"
              onClick={clearUpload}
            >
              Remove Met Masts
            </Button>
          </HStack>
        </>
      ) : null}
    </Box>
  );
}

export default MetMastUploaderResults;
