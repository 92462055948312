import { Center, Icon, LinkBox, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FiFilePlus } from "react-icons/fi";

interface Props {
  getRootProps: () => object;
  label?: string;
  hovering: boolean;
}

export default function UploaderFileTarget(props: Props) {
  const { getRootProps, hovering, label } = props;
  const [internalHovering, setInternalHovering] = useState(false);

  return (
    <LinkBox
      borderRadius={"md"}
      borderWidth={1}
      padding={6}
      bg={hovering || internalHovering ? "whiteAlpha.400" : "whiteAlpha.300"}
      color={hovering || internalHovering ? "whiteAlpha.900" : "whiteAlpha.700"}
      _hover={{
        shadow: "md",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
      }}
      // borderColor={
      //   // hovering || internalHovering ? "yellow.100" : "whiteAlpha.400"
      // }
      // opacity={hovering || internalHovering ? 1 : 0.5}
      alignItems={"center"}
      justifyContent={"center"}
      justifyItems={"center"}
      // borderStyle={"dashed"}
      cursor="pointer"
      {...getRootProps()}
      onMouseEnter={() => setInternalHovering(true)}
      onMouseLeave={() => setInternalHovering(false)}
    >
      <Center>
        <Icon as={FiFilePlus} boxSize={4} />
        {label && (
          <Text
            fontWeight="light"
            textTransform="uppercase"
            letterSpacing={2}
            pl={3}
            fontSize="90%"
          >
            {label}
          </Text>
        )}
      </Center>
    </LinkBox>
  );
}
