import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import LibraryLoading from "../LibraryLoading";
import SectionHeading from "../SectionHeading";
import SidebarLeft from "../SidebarLeft";
import SimulationGridCellCount from "../Simulations/SimulationGridCellCount";
import SimulationStatusBadge from "../Simulations/SimulationStatusBadge";
import SimulationStatusMessage from "../Simulations/SimulationStatusMessage";
import useSimulationStore from "../Simulations/simulationStore";
import formatDate, { useSimplifiedDateFormat } from "../formatDate";
import MetMasts from "./MetMasts";
import SimulationOutputs from "./SimulationOutputs";
import SubmitterInfo from "./SubmitterInfo";
import TurbineLocations from "./TurbineLocations";
export default function SimulationResults() {
  const {
    turbineLocations,
    outOfBoundsCount,
    currentSimulationErrors,
    metMastLocations,
    currentSimulation,
    loadCurrentSimulation,
    setCurrentSimulation,
    windMapSourceLayers,
  } = useSimulationStore();

  const downloadableMapLayer = useMemo(() => {
    const downloadable = windMapSourceLayers.filter(
      (layer) => layer.hasDownloads
    );
    if (downloadable.length < 1) return undefined;

    return downloadable.reduce((prev, current) =>
      prev.maxzoom > current.maxzoom ? prev : current
    );
  }, [windMapSourceLayers]);

  const { simulationSlug } = useParams<{ simulationSlug: string }>();
  if (!simulationSlug) throw new Error("Missing simulationSlug"); // TODO: handle this better

  useEffect(() => {
    async function load() {
      await loadCurrentSimulation(simulationSlug, false);
    }
    load();
  }, [simulationSlug, loadCurrentSimulation]);

  useEffect(() => {
    return () => {
      setCurrentSimulation(undefined);
    };
  }, [setCurrentSimulation]);

  function renderOutOfBoundsWarning() {
    if (outOfBoundsCount <= 0) {
      return null;
    }
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription fontSize="xs">
          {outOfBoundsCount === 1
            ? "1 feature was out of bounds"
            : `${outOfBoundsCount} features were out of bounds.`}
        </AlertDescription>
      </Alert>
    );
  }

  function renderMapDownloadInstructions() {
    if (!downloadableMapLayer) return null;

    return (
      <Box
        bg="whiteAlpha.300"
        fontSize="sm"
        display={"block"}
        borderRadius={6}
        p={4}
      >
        <Text as="b">
          Zoom the map to level {downloadableMapLayer.minzoom} or higher to
          download timeseries data for each grid cell.
        </Text>
      </Box>
    );
  }

  function renderSubmitterInfo() {
    if (!currentSimulation?.submitterInfo) return null;
    const submitterInfo = JSON.parse(currentSimulation.submitterInfo) as Record<
      string,
      string
    >;
    return <SubmitterInfo submitterInfo={submitterInfo} />;
  }

  function renderErrors() {
    if (currentSimulationErrors.length < 1) return null;

    return currentSimulationErrors.map((error, index) => (
      <Alert status="error" key={index}>
        <AlertIcon />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    ));
  }

  function renderTimeRange() {
    if (!currentSimulation) return null;
    const { startDate, endDate, typicalMeteorologicalYear } = currentSimulation;

    if (typicalMeteorologicalYear) {
      return "Typical Meteorological Year";
    }

    const simplified = useSimplifiedDateFormat(currentSimulation.slug);

    return `${formatDate(
      startDate,
      simplified,
      "Unknown date"
    )} to ${formatDate(endDate, simplified, "unknown date")}`;
  }

  function renderWrgHeights() {
    if (!currentSimulation) return null;
    const wrgHeights = JSON.parse(currentSimulation.wrgHeights || "[]");

    if (!wrgHeights || wrgHeights.length < 1) return null;

    return (
      <Box>
        <Heading size="sm">WRG Heights</Heading>
        {wrgHeights.map((wrgHeight: any, index: number) => (
          <Box key={index}>
            <Text as="b" display="block" fontSize="sm" ml={2} mt={1}>
              &bull; {wrgHeight}m
            </Text>
          </Box>
        ))}
      </Box>
    );
  }

  if (!currentSimulation) {
    return (
      <SidebarLeft>
        <LibraryLoading />
      </SidebarLeft>
    );
  }

  return (
    <Box>
      <SidebarLeft>
        <Box overflowY={"auto"} height={"91vh"}>
          <Box mt={6} px={6} pb={12}>
            <SectionHeading
              title={currentSimulation.name || "Simulation"}
              closeTo="/simulations"
            />

            <Box mb={6}>
              <SimulationStatusBadge simulation={currentSimulation} />
              <SimulationStatusMessage simulation={currentSimulation} />
            </Box>

            <VStack spacing={8} alignItems={"baseline"}>
              {renderSubmitterInfo()}
              {renderErrors()}
              {renderOutOfBoundsWarning()}
              <SimulationOutputs simulation={currentSimulation} />
              {renderTimeRange()}

              {renderWrgHeights()}

              <Box flex="1">
                <Text fontSize="sm" display={"block"}>
                  Time: <Text as="b">{renderTimeRange()}</Text>
                </Text>
              </Box>
              <SimulationGridCellCount
                gridCellCount={currentSimulation.gridCellCount || undefined}
                resolution={currentSimulation.resolution || undefined}
              />
              {renderMapDownloadInstructions()}
              <TurbineLocations turbineLocations={turbineLocations} />
              <MetMasts metMastLocations={metMastLocations} />
            </VStack>
          </Box>
        </Box>
      </SidebarLeft>
    </Box>
  );
}
