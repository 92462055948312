import { Box } from "@chakra-ui/react";
import staticMap from "../../assets/turbine-bg.jpg";

export default function MapPlaceholder() {
  return (
    <Box
      w="100%"
      height="100%"
      bg="gray.100"
      position="absolute"
      bgImage={staticMap}
      className="map-placeholder"
      backgroundSize="cover"
    />
  );
}
