import { Privacy, Turbine } from "../../API";

export function canDeleteTurbine(turbine: Turbine): boolean {
  return turbine.privacy === Privacy.PRIVATE;
}

export function canEditTurbine(turbine: Turbine): boolean {
  return turbine.privacy === Privacy.PRIVATE;
}

export function canShowTurbine(_turbine: Turbine): boolean {
  return true;
}
