/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "submitSimulation",
            "endpoint": "https://2ufjjecs38.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "getCellData",
            "endpoint": "https://2nbzeyw101.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wijue3qogjdp3d4f4fgdttkxdy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:610cbadd-3ccd-4e78-b2c7-60c3ce610443",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ieNXTebjK",
    "aws_user_pools_web_client_id": "2kghslptg9l5tqpanqiabujj9h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "veerweb204754-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Project-xxcr2wwbc5e2to6sizce6f42ge-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Simulation-xxcr2wwbc5e2to6sizce6f42ge-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Turbine-xxcr2wwbc5e2to6sizce6f42ge-dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
