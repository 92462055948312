import { PlusSquareIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Input } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Turbine } from "../../API.ts";
import SidebarLeft from "../SidebarLeft";
import TurbineLibraryTable from "./TurbineLibraryTable";
import filterTurbinesByKeyword from "./filterTurbinesByKeyword.ts";
import { Link } from "react-router-dom";

type Props = {
  turbines: Turbine[];

  onEditClick: (turbine: Turbine) => void;
  onShowClick: (turbine: Turbine) => void;
  setCurrentTurbine: (turbine: Turbine | undefined) => void;
};

export default function TurbineLibrary(props: Props) {
  const [search, setSearch] = useState("");
  const { turbines, onEditClick, onShowClick } = props;

  const filteredTurbines = useMemo(() => {
    return filterTurbinesByKeyword(turbines, search);
  }, [search, turbines]);

  const isFiltered = turbines.length !== filteredTurbines.length;

  let results;
  if (search.length > 0 && filteredTurbines.length === 0) {
    results = (
      <Box textAlign="center" fontSize="sm" p={6}>
        No turbines matching "{search}"
      </Box>
    );
  } else {
    results = (
      <TurbineLibraryTable
        turbines={filteredTurbines}
        onEditClick={onEditClick}
        onShowClick={onShowClick}
        isFiltered={isFiltered}
      />
    );
  }

  return (
    <Box
      height="100%"
      display={{ base: "block", sm: "flex" }}
      flexDirection={{ base: "column", sm: "unset" }}
      overflowY={"auto"}
    >
      <SidebarLeft>
        <Flex
          direction="column"
          height="100%"
          className="record-library turbine-library"
        >
          <Box className="record-library-header" px={6} pt={6}>
            <HStack mb={6} alignItems="justify-content">
              <Box flex="1">
                <Button
                  leftIcon={<PlusSquareIcon />}
                  as={Link}
                  to="/turbines/new"
                  size="sm"
                  variant="outline"
                  // colorScheme="teal"
                >
                  New
                </Button>
              </Box>

              <Box flex="1">
                <Input
                  placeholder="Search"
                  variant="filled"
                  size="sm"
                  type="search"
                  defaultValue={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </HStack>
          </Box>
          <Box overflowY={{ base: "auto" }} flex={1}>
            {results}
          </Box>
        </Flex>
      </SidebarLeft>
    </Box>
  );
}
