import { format, parseISO } from "date-fns";

// era5 gets a simplified date format
export function useSimplifiedDateFormat(simulationSlug: string | undefined) {
  return simulationSlug === "sim_era5_global";
}

export default function formatDate(
  date: string | Date | null | undefined,
  simplified = false,
  fallback?: string
) {
  if (!date) return fallback || "";
  const formatString = simplified ? "MMMM, yyyy" : "MMMM d, yyyy";
  try {
    if (typeof date === "string") {
      return format(parseISO(date), formatString);
    }

    return format(date, formatString);
  } catch (error) {
    console.error(`Error formatting date: ${date} - ${error}`);
    return fallback || "";
  }
}
