import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Privacy, Turbine } from "../../API.ts";
import TurbineLibraryItem from "./TurbineLibraryItem";

type Props = {
  turbines: Turbine[];
  onEditClick: (turbine: Turbine) => void;
  onShowClick: (turbine: Turbine) => void;
  isFiltered: boolean;
};

function GroupedTurbines(
  props: Pick<Props, "turbines" | "onEditClick" | "onShowClick">
) {
  const { turbines, onEditClick, onShowClick } = props;
  return (
    <Box>
      {turbines.map((turbine: Turbine) => {
        return (
          <Box key={turbine.id}>
            <Divider />
            <TurbineLibraryItem
              key={turbine.id}
              turbine={turbine}
              onEditClick={onEditClick}
              onShowClick={onShowClick}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default function TurbineLibraryTable(props: Props) {
  const { turbines, onEditClick, onShowClick, isFiltered } = props;

  const groupedTurbines = useMemo(() => {
    const byProject: Record<string, Turbine[]> = {};
    turbines.forEach((turbine: Turbine) => {
      if (turbine.privacy === Privacy.PUBLIC) {
        byProject["Examples"] = byProject["Examples"] || [];
        byProject["Examples"].push(turbine);
      } else {
        byProject["My Turbines"] = byProject["My Turbines"] || [];
        byProject["My Turbines"].push(turbine);
      }
    });
    return byProject;
  }, [turbines]);

  let index: number[] | undefined = undefined;
  if (isFiltered) {
    // show all projects if filtered
    index = Array(Object.keys(groupedTurbines).length)
      .fill(0)
      .map((_, i) => i);
  }

  return (
    <Accordion index={index} allowMultiple>
      {Object.keys(groupedTurbines).map((projectName) => {
        return (
          <AccordionItem key={projectName}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" pl={2}>
                  {projectName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel px={0} pb={4}>
              <GroupedTurbines
                turbines={groupedTurbines[projectName]}
                onShowClick={onShowClick}
                onEditClick={onEditClick}
                // selectedSimulationId={selectedSimulationId}
              />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
