import ReactGA from "react-ga4";

if (import.meta.env.PROD) {
  ReactGA.initialize([
    {
      trackingId: "G-KR4MT7T50E",
      // gaOptions: {...}, // optional
      // gtagOptions: {...}, // optional
    },
  ]);
}
