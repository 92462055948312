// @ts-expect-error
import area from "@turf/area";
// @ts-expect-error
import distance from "@turf/distance";
// @ts-expect-error
import destination from "@turf/destination";
// @ts-expect-error
import { point } from "@turf/helpers";
import mapboxgl from "mapbox-gl";
import { MAX_X_EXTENT } from "../../../endpoints/calculateResolutionOptions";
import { MIN_X_EXTENT } from "../../../constants";

const COORD_PRECISION = 4; // four decimal places = 11m precision

const EXTENT_PRECISION = 0;

function roundToPrecision(value: number, precision: number) {
  return Math.round(value * 10 ** precision) / 10 ** precision;
}

export function calculateGridCellCount(
  area: number | undefined,
  resolution: number | undefined
): number {
  if (!area || !resolution) return 0;

  const gridCellCount = Math.ceil(
    resolution ? area / Math.pow(resolution / 1000, 2) : 0
  );

  return gridCellCount;
}

export type SimulationPriceData = {
  gridCellCount: number;
  priceCents: number;
  currency: string;
};

export type SimulationGeography = {
  /* The area of the bounding box in square kilometers - calculated */
  area?: number;

  /* The width of the bounding box in kilometers - user provided */
  width: number;

  /* The height of the bounding box in kilometers - user provided */
  height: number;

  centerLng: number;
  centerLat: number;

  /* The bounding box - always derived by expanding from the center point */
  bounds?: mapboxgl.LngLatBounds;
};

export function generateSimulationGeography(
  centerLng: number,
  centerLat: number,
  width: number,
  height: number
): SimulationGeography {
  centerLng = roundToPrecision(centerLng, COORD_PRECISION);
  centerLat = roundToPrecision(centerLat, COORD_PRECISION);
  width = roundToPrecision(width, EXTENT_PRECISION);
  height = roundToPrecision(height, EXTENT_PRECISION);

  width = Math.min(width, MAX_X_EXTENT);
  width = Math.max(width, MIN_X_EXTENT);

  height = Math.min(height, MAX_X_EXTENT);
  height = Math.max(height, MIN_X_EXTENT);

  const center = point([centerLng, centerLat]);

  const west = destination(center, width / 2, 270, {
    units: "kilometers",
  });
  const east = destination(center, width / 2, 90, {
    units: "kilometers",
  });
  const north = destination(center, height / 2, 0, {
    units: "kilometers",
  });
  const south = destination(center, height / 2, 180, {
    units: "kilometers",
  });

  const bounds = new mapboxgl.LngLatBounds();
  bounds.extend(west.geometry.coordinates);
  bounds.extend(east.geometry.coordinates);
  bounds.extend(north.geometry.coordinates);
  bounds.extend(south.geometry.coordinates);

  const geography = {
    bounds,
    centerLng,
    centerLat,
    width,
    height,
    area: Math.round(width * height * 100) / 100,
  };

  return geography;
}
