import mapboxgl from "mapbox-gl";
import React, { useEffect } from "react";
import { SourceLayer, WindMapSource } from "./lib/types";
import { addWindResource, removeWindResource } from "./lib/addWindResource";

import useWindResourceStore from "./windResourceStore";
import { CurrentWindResource } from "../CurrentWindResource";
import useSimulationStore from "../Simulations/simulationStore";
import { WindSpeedLegend } from "../WindSpeedLegend";

// hook to add/remove wind resource viz layer to the map
function useWindMapSource(
  map: React.MutableRefObject<mapboxgl.Map | null>,
  loaded: boolean,
  windMapSource: WindMapSource | undefined,
  windMapSourceLayers: SourceLayer[]
) {
  const speedRef = React.useRef<CurrentWindResource | undefined>(undefined);
  const legendRef = React.useRef<WindSpeedLegend | undefined>(undefined);
  const { setCurrentWindResourceData, currentWindResourceData } =
    useWindResourceStore();

  const { currentSimulation } = useSimulationStore();
  const simulationSlug = currentSimulation?.slug;

  useEffect(() => {
    speedRef.current?.setData(currentWindResourceData);
  }, [currentWindResourceData]);

  useEffect(() => {
    if (!map || !map.current) return;
    if (!loaded) return;

    if (windMapSource && windMapSourceLayers) {
      addWindResource(
        map.current,
        simulationSlug!,
        windMapSource,
        windMapSourceLayers,
        "wsaf",
        1,
        setCurrentWindResourceData
      );

      legendRef.current = new WindSpeedLegend();
      map.current.addControl(legendRef.current, "top-right");

      speedRef.current = new CurrentWindResource(currentWindResourceData);
      map.current.addControl(speedRef.current, "top-right");
    } else {
      removeWindResource(map.current);
      if (speedRef.current) map.current.removeControl(speedRef.current);
      if (legendRef.current) map.current.removeControl(legendRef.current);
    }

    return () => {
      removeWindResource(map.current);
      if (map.current && speedRef.current) {
        map.current.removeControl(speedRef.current);
      }
      if (map.current && legendRef.current) {
        map.current.removeControl(legendRef.current);
      }
    };
  }, [map, loaded, windMapSource, windMapSourceLayers, simulationSlug]);
}

export default useWindMapSource;
