/** Should we perform an HTTP request to the getCellData endpoint to retrieve additional data
 * for the given grid cell?
 */
export default function gridCellHasMoreData(
  simulationSlug: string,
  _properties: Record<string, any>
) {
  if (simulationSlug !== "sim_era5_global") {
    return false;
  }
  return true;
}
