import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { MouseEvent } from "react";
import TurbineLocationsModal from "../TurbineLocationsModal";
import { SimulationTurbineList } from "./helpers";
interface Props {
  getRemoveFileProps: () => {
    onClick: (event: MouseEvent) => void;
  };
  ProgressBar: any;
  Remove: any;
  clearUpload: () => void;
  turbineLocations: SimulationTurbineList;
}

export function PreviewTable(props: {
  uploaded: SimulationTurbineList;
  rowLimit: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { uploaded, rowLimit } = props;
  if (!uploaded) return null;

  return (
    <Box>
      <Button size="xs" colorScheme="teal" variant="outline" onClick={onOpen}>
        Spot Check
      </Button>

      <TurbineLocationsModal
        isOpen={isOpen}
        onClose={onClose}
        rowLimit={rowLimit}
        turbineLocations={uploaded}
      />
    </Box>
  );
}

function TurbineLocationUploaderResults(props: Props) {
  const { getRemoveFileProps, ProgressBar, turbineLocations } = props;
  const clearUpload = (event: MouseEvent) => {
    getRemoveFileProps().onClick(event);
    props.clearUpload();
  };
  return (
    <Box>
      <ProgressBar />

      {turbineLocations && turbineLocations.length > 0 ? (
        <>
          <Box>
            <Text fontSize="xs">
              {turbineLocations.length} turbines have been uploaded.
            </Text>
          </Box>

          <HStack mt={4} gap={4}>
            <PreviewTable uploaded={turbineLocations} rowLimit={20} />
            <Button
              size="xs"
              colorScheme="red"
              variant="outline"
              onClick={clearUpload}
            >
              Remove Turbines
            </Button>
          </HStack>
        </>
      ) : null}
    </Box>
  );
}

export default TurbineLocationUploaderResults;
