import {
  Alert,
  AlertDescription,
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "timeago.js";
import { Turbine } from "../../API";
import { FormTextInput, FormTextInputWithUnit } from "../FormControls";
import LibraryLoading from "../LibraryLoading";
import PowerThrustUploader from "../PowerThrustUploader";
import SectionHeading from "../SectionHeading";
import SidebarLeft from "../SidebarLeft";
import TurbinePowerCurvePlots from "./PowerThrustCharts";
import { PowerCurve } from "./generatePowerCurveJSON";
import useTurbineStore from "./turbineStore";
import { TurbineFormValues } from "./types";

export default function TurbineForm() {
  const { turbineSlug } = useParams<{ turbineSlug: string }>();
  const navigate = useNavigate();
  const { saveTurbine, currentTurbine, loadCurrentTurbine, newTurbine } =
    useTurbineStore();

  useEffect(() => {
    async function load() {
      if (turbineSlug) {
        await loadCurrentTurbine(turbineSlug);
        console.log("loaded turbine", currentTurbine);
      } else {
        newTurbine();
      }
    }
    load();
  }, [turbineSlug, loadCurrentTurbine, newTurbine]);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TurbineFormValues>({
    mode: "onBlur",
    defaultValues: currentTurbine,
  });

  useEffect(() => {
    reset(currentTurbine);
  }, [currentTurbine, reset]);

  const [saving, setSaving] = useState(false);

  const [powerCurve, setPowerCurve] = useState<PowerCurve | undefined>(
    currentTurbine?.powerCurve ? JSON.parse(currentTurbine.powerCurve) : null
  );

  const onClose = () => {
    navigate("/turbines");
  };

  const saveForm: SubmitHandler<TurbineFormValues> = async (values) => {
    console.debug("TurbineForm.saveForm", values);
    setSaving(true);
    try {
      const paramsToSave = {
        slug: values.slug,
        description: values.description,
        rotorDiameter: values.rotorDiameter
          ? parseInt(values.rotorDiameter.toString(), 10)
          : undefined,
        ratedPower: values.ratedPower,
      } as Partial<Turbine>;

      if (currentTurbine?.id) {
        paramsToSave.id = currentTurbine.id;
      }

      if (powerCurve && powerCurve.length > 0) {
        paramsToSave.powerCurve = JSON.stringify(powerCurve);
      } else {
        console.warn("powercurve error", powerCurve);
        setError("root.powerCurve", {
          type: "custom",
          message: "Please provide valid power/thrust data before continuing",
        });

        setSaving(false);
        return;
      }

      const saved = await saveTurbine(paramsToSave);

      if (!saved || !saved.id) return;
      navigate(`/turbines/${saved.slug}/edit`);

      // props.onClose(); // TODO: rename to close?
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  if (!currentTurbine) {
    return (
      <SidebarLeft>
        <LibraryLoading />
      </SidebarLeft>
    );
  }

  return (
    <SidebarLeft
      maxW={{ base: "100%", sm: "35rem", lg: "58rem" }}
      w="unset"
      minW={{ base: "100%", sm: "32rem" }}
    >
      <Box mt={6} px={6} pb={12}>
        <SectionHeading title="Define a Turbine" closeTo="/turbines" />

        <form onSubmit={handleSubmit(saveForm)}>
          <Box gap={16} display={{ lg: "flex", md: "block" }}>
            <Box minWidth={"16rem"}>
              <VStack spacing={8} alignItems={"flex-start"}>
                <FormTextInput
                  name="slug"
                  register={register}
                  registerOptions={{
                    required: true,
                    minLength: 2,
                    maxLength: 100,
                    pattern: /[A-Za-z0-9_\-]+/,
                  }}
                  error={errors?.slug}
                  placeholder="nrle-5k-50k"
                  label="Turbine identifier"
                  helperText="Alpha numeric, dashes and underscores allowed - no spaces"
                  isRequired={true}
                />
                <FormTextInput
                  name="description"
                  register={register}
                  registerOptions={{
                    maxLength: 100,
                  }}
                  error={errors?.description}
                  label="Description"
                  placeholder="Optional"
                />
                <Box maxWidth={"10rem"}>
                  <FormTextInputWithUnit
                    register={register}
                    registerOptions={{
                      required: true,
                      min: 1,
                    }}
                    error={errors?.rotorDiameter}
                    name="rotorDiameter"
                    label="Rotor diameter"
                    type="number"
                    unitLabel="m"
                  />
                </Box>
                <Box maxWidth={"10rem"}>
                  <FormTextInputWithUnit
                    register={register}
                    registerOptions={{
                      required: true,
                      min: 1,
                    }}
                    error={errors?.ratedPower}
                    name="ratedPower"
                    label="Rated power"
                    type="number"
                    unitLabel="kW"
                  />
                </Box>
              </VStack>
            </Box>
            <Box
              w="full"
              maxW={"30rem"}
              mt={{ lg: 0, base: 12 }}
              className="power-thrust-plots"
            >
              <PowerThrustUploader
                onUpload={setPowerCurve}
                powerCurve={powerCurve}
              />

              {errors.root?.powerCurve && (
                <Alert status="error" my={4}>
                  <AlertDescription>
                    Please upload valid power/thrust data before continuing
                  </AlertDescription>
                </Alert>
              )}

              {powerCurve && <TurbinePowerCurvePlots powerCurve={powerCurve} />}

              <Box>
                <Alert p={4} borderRadius="md" mb={4}>
                  <AlertDescription>
                    Power and thrust curve data uploaded here are kept
                    confidential in this user account and are not shared
                    publicly.
                  </AlertDescription>
                </Alert>
              </Box>
            </Box>
          </Box>
          <Box mt={8}>
            <HStack spacing={4}>
              <Button type="submit" disabled={isSubmitting} colorScheme="teal">
                Save Turbine {saving ? <Spinner ml={6} /> : null}
              </Button>

              <Button
                disabled={isSubmitting}
                variant="outline"
                onClick={onClose}
              >
                Close
              </Button>
            </HStack>
            {currentTurbine && currentTurbine.updatedAt && (
              <Text mt={4} fontSize="xs">
                Saved {format(currentTurbine.updatedAt)}
              </Text>
            )}
          </Box>
        </form>
      </Box>
    </SidebarLeft>
  );
}
