import { useEffect, useState } from "react";
import LibraryLoading from "../LibraryLoading";
import SidebarLeft from "../SidebarLeft";
import SimulationLibrary from "./SimulationLibrary";
import useProjectStore from "./projectStore";
import useSimulationStore from "./simulationStore";

export default function Simulations(props: any) {
  const { user } = props;
  const {
    currentSimulation,
    simulations,
    fetch: fetchSimulations,
    deleteSimulation,
  } = useSimulationStore();

  const { fetch: fetchProjects } = useProjectStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await fetchSimulations();
      await fetchProjects();
      setLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [user]);

  if (loading) {
    return (
      <SidebarLeft>
        <LibraryLoading />
      </SidebarLeft>
    );
  }
  return (
    <SimulationLibrary
      {...props}
      simulations={simulations}
      selectedSimulationId={currentSimulation?.id}
      onDeleteClick={(simulationId: string) => {
        if (
          window.confirm("Are you sure you want to delete this simulation?")
        ) {
          deleteSimulation(simulationId);
        }
      }}
    />
  );
}
