import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Privacy, Domain, DomainStatus } from "../../API";
import * as persist from "./persist";

interface DomainState {
  domains: Domain[];
  loaded: boolean;
  currentDomain: Partial<Domain> | undefined;
  setCurrentDomain: (domain?: Partial<Domain>) => void;
  saveDomain: (params: Partial<Domain>) => Promise<Domain>;
  newDomain: () => Partial<Domain>;
  fetch: () => Promise<void>;
  reset: () => void;
}

const useDomainStore = create<DomainState>()(
  devtools(
    (set, get) => ({
      domains: [] as Domain[],
      loaded: false as boolean,
      currentDomain: undefined as Partial<Domain> | undefined,
      reset: () => {
        set({ domains: [], loaded: false, currentDomain: undefined });
      },

      setCurrentDomain: (domain?: Partial<Domain>) => {
        set({
          currentDomain: domain,
        });
      },

      fetch: async (forceRefetch = false) => {
        try {
          if (get().loaded && !forceRefetch) return;
          const domains = await persist.fetchDomains();
          set({ domains, loaded: true });
        } catch (error) {
          console.error(error);
        }
      },

      newDomain: () => {
        const newDomain: Partial<Domain> = {
          __typename: "Domain",
          privacy: Privacy.PRIVATE,
          status: DomainStatus.EDITABLE,
        };

        get().setCurrentDomain(newDomain);
        return newDomain;
      },

      saveDomain: async (params: Partial<Domain>): Promise<Domain> => {
        // if (params.status !== DomainStatus.EDITABLE) {
        //   throw new Error("Simulation domain is not editable");
        // }

        if (!params.status) {
          params.status = DomainStatus.EDITABLE;
        }

        const domain = await persist.saveDomain(params);

        let domains = get().domains;
        // add or update in state
        if (!domains.find((ss) => ss.id === domain.id)) {
          domains = [domain, ...domains] as Domain[];
        } else {
          domains = domains.map((ss) => (ss.id === domain.id ? domain : ss));
        }

        set(() => ({
          domains: domains,
          currentDomain: domain,
        }));
        return domain;
      },
    }),
    { name: "domainStore" }
  )
);

export default useDomainStore;
