import { Box, Text, VStack } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import LibraryLoading from "../LibraryLoading";
import SectionHeading from "../SectionHeading";
import SidebarLeft from "../SidebarLeft";
import TurbinePowerCurvePlots from "../Turbines/PowerThrustCharts";
import useTurbineStore from "../Turbines/turbineStore";
import PowerThrustTable from "./PowerThrustTable";

export default function TurbineReview() {
  const { turbineSlug } = useParams<{ turbineSlug: string }>();

  const { currentTurbine, loadCurrentTurbine } = useTurbineStore();

  useEffect(() => {
    async function loadTurbine() {
      await loadCurrentTurbine(turbineSlug);
    }
    loadTurbine();
  }, [turbineSlug, loadCurrentTurbine]);

  const powerCurve = useMemo(() => {
    if (!currentTurbine) return [];
    return JSON.parse(currentTurbine.powerCurve || "[]");
  }, [currentTurbine]);

  if (!currentTurbine) {
    return (
      <SidebarLeft>
        <LibraryLoading />
      </SidebarLeft>
    );
  }

  return (
    <SidebarLeft
      w="unset"
      minW={{ base: "100%", sm: "32rem" }}
      maxW={{ base: "100%", sm: "35rem", lg: "58rem" }}
    >
      <Box mt={6} px={6} pb={12}>
        <SectionHeading
          title={currentTurbine.slug || "Turbine"}
          closeTo="/turbines"
        />
        <Box
          gap={12}
          display={{ lg: "flex", md: "block" }}
          justifyContent={"space-between"}
        >
          <Box width={"100%"} textAlign={"left"}>
            <VStack
              spacing={1}
              textAlign={"left"}
              width={"100%"}
              alignItems={"flex-start"}
              mb={12}
            >
              <Text fontSize="sm" display={"block"}>
                Turbine ID: <Text as="b">{currentTurbine.slug}</Text>
              </Text>

              {currentTurbine.description && (
                <Text fontSize="sm" display={"block"}>
                  Description: <Text as="b">{currentTurbine.description}</Text>
                </Text>
              )}

              <Text fontSize="sm" display={"block"}>
                Rotor Diameter:{" "}
                <Text as="b">{currentTurbine.rotorDiameter} m</Text>
              </Text>

              <Text fontSize="sm" display={"block"}>
                Rated Power: <Text as="b">{currentTurbine.ratedPower} kW</Text>
              </Text>
            </VStack>

            <PowerThrustTable powerCurve={powerCurve} />
          </Box>
          <Box
            w="full"
            maxW={"30rem"}
            mt={{ lg: 0, base: 12 }}
            className="power-thrust-plots"
          >
            <TurbinePowerCurvePlots powerCurve={powerCurve} />
          </Box>
        </Box>
      </Box>
    </SidebarLeft>
  );
}
