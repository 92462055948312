import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton } from "@chakra-ui/react";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Simulation } from "../../API";
import SubmitWithConfirmButton from "./SubmitWithConfirmButton";
import {
  canEditSimulation,
  canShowSimulation,
  canSubmitSimulation,
} from "./simulationStates";
import useSimulationStore from "./simulationStore";

interface Props {
  simulation: Simulation;
}

export default function SimulationActionButtons(props: Props) {
  const { deleteSimulation } = useSimulationStore();
  const { simulation } = props;
  const buttons = [];

  const onDeleteClick = () => {
    if (window.confirm("Are you sure you want to delete this simulation?")) {
      deleteSimulation(simulation.id);
    }
  };

  if (canShowSimulation(simulation)) {
    buttons.push(
      <Button
        key="show"
        as={Link}
        to={`/simulations/${simulation.slug}`}
        leftIcon={<FiEye />}
        aria-label="View simulation"
        size="xs"
        variant="ghost"
      />
    );
  }

  if (canEditSimulation(simulation)) {
    buttons.push(
      <Button
        key="edit"
        leftIcon={<EditIcon />}
        as={Link}
        to={`/simulations/${simulation.slug}/edit`}
        aria-label="Edit"
        size="xs"
        variant="ghost"
      />
    );

    buttons.push(
      <IconButton
        key="delete"
        onClick={(event) => {
          event.stopPropagation();
          onDeleteClick();
        }}
        icon={<DeleteIcon />}
        aria-label="Delete turbine"
        size="xs"
        variant="ghost"
        colorScheme="grey"
      />
    );
  }
  if (canSubmitSimulation(simulation)) {
    buttons.push(
      <SubmitWithConfirmButton
        key="submit"
        variant="ghost"
        size="xs"
        simulationId={simulation.id}
      />
    );
  }

  return <Box>{buttons}</Box>;
}
