// @ts-expect-error
import booleanContains from "@turf/boolean-contains";
import mapboxgl from "mapbox-gl";
import React, { useEffect } from "react";
import { removeMapLayer } from "./lib/removeMapElements";
import { AVAILABLE_REGION_FEATURES } from "./lib/types";

/**
 * Check if the bounds are outside any of the available regions
 */
export function isOutsideAvailableRegions(
  bounds: mapboxgl.LngLatBounds
): boolean {
  const geometry = {
    type: "Polygon",
    coordinates: [
      [
        [bounds.getWest(), bounds.getNorth()],
        [bounds.getEast(), bounds.getNorth()],
        [bounds.getEast(), bounds.getSouth()],
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getWest(), bounds.getNorth()],
      ],
    ],
  };

  return !Object.values(AVAILABLE_REGION_FEATURES).some((feature) => {
    return booleanContains(feature, geometry);
  });
}

export function addBoundaries(map: mapboxgl.Map) {
  if (!map) return;

  // draw a layer for each region and outline the bounding box with a thick yellow line
  Object.keys(AVAILABLE_REGION_FEATURES).forEach((region) => {
    const feature = AVAILABLE_REGION_FEATURES[region];

    map.addLayer({
      id: `region-${region}`,
      type: "line",
      source: {
        type: "geojson",
        data: feature,
      },
      layout: {},
      paint: {
        "line-color": "#ECC94B",
        "line-width": 2,
        "line-opacity": 0.8,
        // "line-dasharray": [1, 2],
      },
    });
  });
}

export function hideBoundaries(map: mapboxgl.Map | null) {
  if (!map) return;

  Object.keys(AVAILABLE_REGION_FEATURES).forEach((region) => {
    map.setLayoutProperty(`region-${region}`, "visibility", "none");
  });
}

export function showBoundaries(map: mapboxgl.Map | null) {
  if (!map) return;

  Object.keys(AVAILABLE_REGION_FEATURES).forEach((region) => {
    map.setLayoutProperty(`region-${region}`, "visibility", "visible");
  });
}

export function removeBoundaries(map: mapboxgl.Map | null) {
  if (!map) return;

  Object.keys(AVAILABLE_REGION_FEATURES).forEach((region) => {
    removeMapLayer(map, `region-${region}`);
  });
}

/** Draw the boundaries of the regions available for simulations,
 *  only showing them when we are in bounding-box editing mode */
function useRegionBoundaries(
  map: React.MutableRefObject<mapboxgl.Map | null>,
  loaded: boolean,
  allowEditingBounds: boolean
) {
  useEffect(() => {
    if (!map || !map.current) return;
    if (!loaded) return;
    addBoundaries(map.current);

    return () => {
      removeBoundaries(map.current);
    };
  }, [map, loaded]);

  useEffect(() => {
    if (!map || !map.current) return;
    if (!loaded) return;

    if (allowEditingBounds) {
      showBoundaries(map.current);
    } else {
      hideBoundaries(map.current);
    }
  }, [map, loaded, allowEditingBounds]);
}

export default useRegionBoundaries;
