const ALLOWED_PREFIXES = ["domain", "sim", "turbine", "project"];

const LETTERS = "abcdefghjkmnpqrstuvwxyz"; // excluding i, l, o

function randomLetter() {
  return LETTERS[Math.floor(Math.random() * LETTERS.length)];
}

function generateId(prefix: string) {
  if (!ALLOWED_PREFIXES.includes(prefix)) {
    throw new Error(`Invalid prefix: ${prefix}`);
  }

  let generatedString = [
    randomLetter(),
    Date.now().toString(36),
    Math.random().toString(36).substring(2, 5),
  ].join("");

  return prefix + "_" + generatedString;
}
export default generateId;
