import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";

export default function ErrorMessage() {
  return (
    <Center h="100vh" flexDirection="column">
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          Oh no!
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          An Error Occurred
        </Text>
        <Text color={"gray.500"} mb={6}>
          The server encountered an internal error and was unable to complete
          your request.
        </Text>

        <Button
          colorScheme="teal"
          bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
          color="white"
          variant="solid"
          onClick={() => window.location.reload()}
        >
          Try Again?
        </Button>
      </Box>
    </Center>
  );
}
